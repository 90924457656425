import styled from 'styled-components';
import { flexbox, flexboxColumn, fontStyle24 } from '../../../../../styles/mixins';

export const HeaderWrapper = styled.header`
  ${fontStyle24};
  ${flexboxColumn};
  width: 100%;
  gap: 10px;
  margin-bottom: 1rem;

  button {
    padding: 0 10px;
    border-radius: 4px;
    transition: all 200ms ease-in-out;
    font-size: 20px;

    &:hover {
      background-color: ${props => props.theme.iconButtonHover_bg};
    }

    &:active {
      box-flex-group: ${props => props.theme.iconButtonActive_bg};
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 900;
    margin: 0 10px;
  }
`;

export const FlexWrapper = styled.div`
  ${flexbox('space-between', 'center')};
  width: 100%;
`;

export const DateHeadingWrapper = styled.div`
  ${flexbox('flex-start', 'center')}
`;

export const SelectWrapper = styled.div``;
