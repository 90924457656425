import { AddImageButton, Label, LabelText } from '../../../../../../components';
import { ImageWrapper } from '../../styles';

interface TreatBasicTranslateProps {
  hospitalName?: string;
  treatName?: string;
  departments?: string[];
  categories?: string[];
  representative?: string;
  cost?: number;
  costOption?: string | undefined;
  treatIntro?: string;
  treatImage?: string;
  treatText?: string;
}

const TreatBasicTranslate = (props: TreatBasicTranslateProps) => {
  const {
    hospitalName,
    treatName,
    departments,
    categories,
    representative,
    cost,
    costOption,
    treatIntro,
    treatImage,
    treatText,
  } = props;
  return (
    <>
      <form>
        <LabelText text="병원명">{hospitalName}</LabelText>
        <LabelText text="시술명" isRequired>
          {treatName}
        </LabelText>
        <LabelText text="진료과" isRequired>
          {departments}
        </LabelText>
        <LabelText text="시술 카테고리" isRequired>
          {categories}
        </LabelText>
        <LabelText text="대표 시술 여부" isRequired>
          {representative}
        </LabelText>
        <LabelText text="가격" isRequired>
          {cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </LabelText>
        <LabelText text="가격 옵션(선택)" nodata={costOption === undefined || costOption.length <= 0}>
          {costOption === undefined || costOption.length <= 0 ? '예) 4cc/1회' : costOption}
        </LabelText>
        <LabelText text="시술 소개" nodata={treatIntro === undefined || treatIntro.length <= 0} isRequired>
          {treatIntro === undefined || treatIntro.length <= 0 ? '시술 소개를 입력해주세요' : treatIntro}
        </LabelText>
        <ImageWrapper>
          <Label text="시술 이미지형 사진" isRequired />
          <AddImageButton url={treatImage} isDisabled />
        </ImageWrapper>
        <ImageWrapper>
          <Label text="시술 텍스트형 사진" isRequired />
          <AddImageButton url={treatText} isDisabled />
        </ImageWrapper>
      </form>
    </>
  );
};

export default TreatBasicTranslate;
