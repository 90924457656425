import React from "react";
import { ApiContext, ApiContextType } from "../contexts/ApiContext";

import Authentication from "../api/Authentication";
import Hospital from "../api/Hospital";
import Doctor from "../api/Doctor";
import Treatment from "../api/Treatment";
import Category from "../api/Category";
import File from "../api/File";
import Review from "../api/Review";
import Counsel from "../api/Counsel";
import Appointment from '../api/Appointment';
import Translation from '../api/Translation';

const ApiContextProvider = ({ children }: { children: React.ReactNode }) => {
  let value: ApiContextType = {
    authentication: Authentication,
    file: File,
    hospital: Hospital,
    doctor: Doctor,
    treatment: Treatment,
    category: Category,
    review: Review,
    counsel: Counsel,
    appointment: Appointment,
    translation: Translation
  }

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

export default ApiContextProvider;
