import { CounselRoomInventoryForPartners, MessageHistory, SentMessageFile, DataResponse, Member, DefaultResponse, PushMessage } from '../model';
import api from './index';
import Qs from 'qs';

const Counsel = {
  getCounselRooms: (
    page: number = 0,
    size: number = 25,
    name?: string,
  ) => {
    return api.get<CounselRoomInventoryForPartners>(
      '/partners/counsel/rooms',
      {
        params: {
          name: name,
          pageNumber: page,
          pageSize: size,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    )
  },
  addCounselFile: (roomId: String, formData: FormData) => {
    return api.post<SentMessageFile>(
      `/partners/counsel/rooms/${roomId}/file`, 
      formData, 
      {
        headers: {"Content-Type": "multipart/form-data"}
      }
    )
  },
  getCounselMessages: (
    roomId: string,
    pageSize: number = 25,
    start?: string,
    end?: string,
  ) => {
    return api.get<MessageHistory>(
      `/partners/counsel/rooms/${roomId}/message.history`, 
      {
        params: {
          pageSize: pageSize,
          start: start,
          end: end,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getMember: (memberId: string) => {
    return api.get<DataResponse<Member>>(`/members/${memberId}`)
  },
  sendPushMessageForCounsel: (memberId: string, pushMessage: PushMessage) => {

    return api.post<DefaultResponse>(`/members/${memberId}/notifications`, pushMessage)
  }
}

export default Counsel; 