import api from './index';
import {
  AppointmentDetailResponse,
  AppointmentDoctorItem,
  AppointmentListItem,
  AppointmentSetting,
  AppointmentStatus,
  AppointmentTimeTableResponse,
  DailyAppointmentPayload,
  DailySchedule,
  DetailsResponse,
  DoctorSchedule,
  ListResponse,
  PaymentDetails,
  PaymentListItem,
} from '../model';
import Qs from 'qs';

const Appointment = {
  getList: (state: string,
            searchOption: string,
            searchKeyword: string,
            startDate?: string,
            endDate?: string,
            page?: number,
            size?: number) => {
    return api.get<ListResponse<AppointmentListItem>>(`/partners/appointments`,
      {
        params: {
          appointmentStatus: state,
          searchType: searchOption,
          searchKeyword: searchKeyword,
          searchStartDate: startDate,
          searchEndDate: endDate,
          pageNumber: page,
          pageSize: size,
        },
        paramsSerializer: (params) => Qs.stringify(params)
      }
    )
  },
  details(appointmentId: string | undefined) {
    return api.get<DetailsResponse<AppointmentDetailResponse>>(`/partners/appointments/${appointmentId}`)
  },
  changeStatus(appointmentId: string | undefined, status: string) {
    return api.patch(`/partners/appointments/${appointmentId}/status.${status}`)
  },
  paymentDetail(appointmentId: string | undefined, paymentId: string | undefined) {
    return api.get<DetailsResponse<PaymentDetails>>(`/partners/appointments/${appointmentId}/payments/${paymentId}`)
  },
  paymentHistory(memberId: string | undefined,
                 page: number, size: number) {
    return api.get<ListResponse<PaymentListItem>>(`/partners/appointments/members/${memberId}/payments`,
      {
        params: {
          pageNumber: page,
          pageSize: size,
        },
        paramsSerializer: (params) => Qs.stringify(params)
      }
    )
  },
  activateAppointment() {
    return api.patch('/partners/appointments/state.active');
  },
  inactiveAppointment() {
    return api.patch('/partners/appointments/state.inactive');
  },
  activateDoctorAppointment(doctorId: string) {
    return api.patch(`/partners/appointments/doctors/${doctorId}/state.active`);
  },
  inactiveDoctorAppointment(doctorId: string) {
    return api.patch(`/partners/appointments/doctors/${doctorId}/state.inactive`);
  },
  getSettings() {
    return api.get<DetailsResponse<AppointmentSetting>>( `/partners/appointments/settings`);
  },
  applyAppointmentTimeInterval(timeInterval: string) {
    const payload = {
      timeInterval: timeInterval
    };
    return api.patch(`/partners/appointments/time-interval`, payload);
  },
  applySameDayAvailability(availability: string) {
    const payload = {
      sameDayAvailableTime: availability
    };
    return api.patch(`/partners/appointments/same-day`, payload);
  },
  getDoctors() {
    return api.get<ListResponse<AppointmentDoctorItem>>(`/partners/appointments/doctors`);
  },
  getDoctorSchedule(doctorId: string) {
    return api.get<DetailsResponse<DoctorSchedule>>(`/partners/appointments/doctors/${doctorId}/schedules`);
  },
  saveDoctorSchedule(doctorId: string | undefined, dailySchedules: DailySchedule[]) {
    const payload = {
      monday: dailySchedules[0],
      tuesday: dailySchedules[1],
      wednesday: dailySchedules[2],
      thursday: dailySchedules[3],
      friday: dailySchedules[4],
      saturday: dailySchedules[5],
      sunday: dailySchedules[6],
    }
    return api.put(`/partners/appointments/doctors/${doctorId}/schedules`, payload);
  },
  getDailyStatus(currentMonth: string) {
    return api.get<DetailsResponse<AppointmentStatus>>(`/partners/appointments/status/monthly/${currentMonth}`);
  },
  getDailyAppointmentHistories(targetDate: string, status: string) {
    return api.get<DetailsResponse<DailyAppointmentPayload>>(`/partners/appointments/status/daily/${targetDate}/histories`,
      {
        params: {
          status: status,
        },
        paramsSerializer: (params) => Qs.stringify(params)
      })
  },
  getAppointmentTimeTable(currentDate: string) {
    return api.get<DetailsResponse<AppointmentTimeTableResponse>>(`/partners/appointments/status/time-table/${currentDate}`)
  },
  breakTime(doctorId: string, currentDate: string, timeLine: string) {
    const payload = {
      breakDate: currentDate,
      breakTime: timeLine,
    }
    return api.post(`/partners/appointments/doctors/${doctorId}/break`, payload)
  },
}

export default Appointment