import { Layout } from "antd";
import GNB from "../../layouts/GNB";
import LNB from "../../layouts/LNB";
import RocketChat from "./RocketChat";
import { StyledLayout } from "./styles";

const Consultation = () => {
	return (
		<>
			<Layout>
				<GNB />
				<Layout>
					<LNB defaultMenu={["4"]} defaultOpenKeys={["4"]} />
					<StyledLayout>
						<RocketChat />
					</StyledLayout>
				</Layout>
			</Layout>
		</>
	);
};

export default Consultation;
