import styled from "styled-components";
import { flexbox, visuallyHidden, scrollBase } from "../../../../../styles/mixins";

export const Form = styled.form`
  ${flexbox("flex-start", "center")};
  width: 100%;
  min-height: 52px;
  max-height: 150px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.inputBorder};

  .anticon {
    ${flexbox("center", "center")};
    width: 40px;
    height: 40px;
    border-radius: 4px;
    flex-shrink: 0;
    :hover {
      background-color: ${(props) => props.theme.iconButtonHover_bg};
    }

    &.anticon-send svg {
      position: relative;
      left: 2px;
    }
  }
`;

export const FileLabel = styled.label`
  cursor: pointer;
  input[type="file"] {
    ${visuallyHidden};
  }
`;

export const TextLabel = styled.label`
  width: 100%;

  textarea {
    ${scrollBase};
    width: 100%;
    resize: none;
    border: unset;
    color: ${(props) => props.theme.primary};

    :focus {
      border: unset;
      box-shadow: unset;
    }
  }
`;
