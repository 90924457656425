import heic2any from "heic2any";

export const fileToDataUri = (file: File) => new Promise<string | ArrayBuffer | null | undefined>((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event: ProgressEvent<FileReader>) => {
    resolve(event.target?.result)
  };
  reader.readAsDataURL(file);
});

export const convertToHeic = async (file: File): Promise<File> => {
  const convertImage = await heic2any({blob: file, toType: 'image/jpeg', quality: 1});
  file = new File(Array.isArray(convertImage) ? convertImage : [convertImage], file.name);

  return file;
};
