import styled from "styled-components";
import { flexbox, fontStyle16 } from "../../../styles/mixins";
import "antd/dist/antd.css";
import { Radio, TimePicker } from "antd";

export const Container = styled.div`
	${flexbox("flex-start", "center")};
	margin: 0 0 20px 0;
`;
export const FlexWrapper = styled.div`
	${flexbox("flex-start", "center")};
`;

export const RadioWrapper = styled.div`
    min-width: 422px;
	margin: 0 50px 0 0;
	color: ${(props) => props.theme.primary};
`;

export const StyledRadio = styled(Radio)`
	${fontStyle16};
	color: ${(props) => props.theme.primary};
	margin: 0 30px 0 0;

	.ant-radio {
		color: ${(props) => props.theme.primary};
	}

	.ant-radio-checked .ant-radio-inner {
		border-color: ${(props) => props.theme.accent};
	}

	.ant-radio-input:active + .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		box-shadow: unset;
	}

	&.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: ${(props) => props.theme.accent};
	}

	.ant-radio-inner::after {
		background-color: ${(props) => props.theme.accent};
	}
`;

export const StyledPicker = styled(TimePicker.RangePicker)`
	height: 42px;

	&.ant-picker {
		color: ${(props) => props.theme.primary};
	}

	&.ant-picker:hover {
		border-color: ${(props) => props.theme.inputBorderHover};
	}

	&.ant-picker-focused {
		box-shadow: unset;
		border-color: ${(props) => props.theme.inputBorderFocus};
	}

	&.ant-picker-range .ant-picker-active-bar {
		background-color: transparent;
	}

	.ant-picker-input > input {
		color: ${(props) => props.theme.primary};
		text-align: center;
	}

	&.ant-picker.ant-picker-disabled .ant-picker-input > input {
		color: ${(props) => props.theme.grey};
	}
`;
