import styled from "styled-components";
import "antd/dist/antd.css";
import { DatePicker, Radio } from "antd";
import { flexbox, flexboxColumn, fontStyle14, fontStyle15, fontStyle16, fontStyle18 } from "../../styles/mixins";

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.inputBorder};
  padding: 32px;
  margin: 30px 0 50px;
  transition: 300ms ease-in-out;
  min-width: 716px;
  /* max-width: 1750px; */

  @media screen and (min-width: 1536) {
    padding: 32px;
  }
`;

export const Form = styled.form`
  @media screen and (min-width: 1366px) {
    ${flexbox("flex-start", "center")};
  }
`;

export const InputWrapper = styled.div`
  @media screen and (min-width: 1620px) {
    margin: 0 30px 0 0;
  }
`;

export const Title = styled.div`
  ${fontStyle18};
  font-weight: 500;
  margin: 0 15px 0 0;
  min-width: 200px;
  height: 42px;
  h1 {
    padding: 0 0 0 4px;
  }
`;

export const Input = styled.input`
  ${fontStyle15};
  display: block;
  height: 42px;
  padding: 0 34px 0 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.inputBorder};
  color: ${(props) => props.theme.primary};
  transition: border-color 100ms ease-in-out;
  appearance: none;
  min-width: 455px;
  width: 100%;

  @media screen and (min-width: 1620px) {
    min-width: 600px;
    width: 829px;
  }

  @media screen and (min-width: 1800px) {
    width: 100%;
  }

  ::placeholder {
    ${fontStyle15};
    color: ${(props) => props.theme.placeholder};
  }

  :hover {
    border: 1px solid ${(props) => props.theme.inputBorderHover};
  }

  :focus,
  :active {
    border: 1px solid ${(props) => props.theme.inputBorderFocus};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  ${fontStyle14};
  color: ${(props) => props.theme.primary};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.inputBorder};
  height: 42px;
  min-width: 455px;
  width: 100%;

  @media screen and (min-width: 1620px) {
    margin: 0 15px 0 0;
    min-width: 425px;
    width: 100%;
  }

  .ant-picker-input > input {
    ${fontStyle15};
    color: ${(props) => props.theme.primary};
    text-align: center;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.inputBorderHover};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.inputBorderFocus};
  }

  &.ant-picker-focused {
    box-shadow: unset;
  }

  .ant-picker-active-bar {
    background: ${(props) => props.theme.accent};
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  box-shadow: unset;
  ${flexbox("flex-start", "center")};
`;

export const StyledRadioButton = styled(Radio.Button)`
  ${flexbox("center", "center")};
  ${fontStyle15};
  min-width: 168px;
  width: 100%;
  height: 42px;
  color: ${(props) => props.theme.primary};

  @media screen and (min-width: 1620px) {
    min-width: 97px;
    width: 100%;
  }

  &.ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }

  &.ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }

  &.ant-radio-button-wrapper-disabled {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      color: ${(props) => props.theme.primary};
    }
  }

  &.ant-radio-button-wrapper:focus-within,
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: unset;
  }

  &.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${(props) => props.theme.accent};
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):not(:disabled) {
    color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${(props) => props.theme.accent};
  }

  &.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: ${(props) => props.theme.accent};
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${(props) => props.theme.accent};
  }
`;

export const UpperWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 15px 0;
`;

export const LowerWrapper = styled.div`
  > div {
    ${flexbox("flex-start", "center")};
    margin: 0 0 15px 0;
  }

  @media screen and (min-width: 1620px) {
    ${flexbox("flex-start", "flex-start")};
  }
`;

export const Divider = styled.div`
  display: none;
  height: 160px;
  width: 1px;
  background-color: ${(props) => props.theme.inputBorder};
  opacity: 0.6;

  @media screen and (min-width: 1366px) {
    display: block;
    margin: 0 45px 0 85px;
  }

  @media screen and (min-width: 1536px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1620px) {
    display: none;
  }

  @media screen and (min-width: 1820px) {
    display: block;
    margin: 0 0 0 20px;
    height: 110px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1366px) {
    margin: 0 0 auto 0;
  }

  @media screen and (min-width: 1620px) {
    // margin: 0 0 auto -170px;
  }

  @media screen and (min-width: 1800px) {
    margin: 0 0 auto 15px;
  }
`;

export const SearchButton = styled.button`
	${fontStyle16};
	font-size:17px;
	font-weight: 500;
	height: 42px;
	padding: 0 15px;
	border-radius: 4px;
	text-align: center;
	background-color: ${(props) => props.theme.accentLight};
	color: ${(props) => props.theme.white};
	position: relative;
	min-width: 160px;
	width:100%;

	.anticon {
		position: relative;
		top: 1px;
		left: 6px;
	}

	&:hover {
		border: 1px solid ${(props) => props.theme.accent};
		background-color: ${(props) => props.theme.accent};
	}

	&:active {
		border: 1px solid ${(props) => props.theme.accentDark};
		background-color: ${(props) => props.theme.accentDark};
	}

	&:focus {
    border: 1px solid ${(props) => props.theme.accentLight}
		background-color: ${(props) => props.theme.accentLight};
	}

	&:disabled{
		cursor:not-allowed;
		opacity:0.4;
	}
`;
