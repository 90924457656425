import React, { MouseEventHandler } from 'react';
import { Button } from './styles';

interface ApproveButtonProps {
  approved?: boolean;
  onClick?: MouseEventHandler;
  isGlobal?: boolean;
  disabled?: boolean;
}

const ApproveButton = (props: ApproveButtonProps) => {
  return (
    <Button type="button" onClick={props.onClick} disabled={props.disabled}>
      {props.isGlobal ? '게시 중단' : '게시 승인'}
    </Button>
  );
};

export default React.memo(ApproveButton);
