import { Layout, Card, Content, Elapse, Info, Images, Tags, PrevBtn, NextBtn } from "./styles";
import { useRef } from "react";
import { Carousel, Image } from "antd";
import { RecoveryRecord } from "../../model";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";

interface recoveryRecordProps {
  recoveryData?: RecoveryRecord
}

const Recovery = (props: recoveryRecordProps) => {
  const carouselRef = useRef<CarouselRef>(null);

  const onClickPrev = () => {
    if(carouselRef.current) {
      carouselRef.current.prev();
    }
  }

  const onClickNext = () => {
    if(carouselRef.current) {
      carouselRef.current.next();
    }
  }

  return (
    <>
      <Layout>
        <Card>
          <Content>
            <Elapse>
              <strong>{props.recoveryData?.elapsedDays}</strong>일 경과
            </Elapse>
            <Info>
              <p>{props.recoveryData?.hospitalName} - {props.recoveryData?.doctorName} {props.recoveryData?.doctorPosition}</p>
              <strong>{props.recoveryData?.treatmentName}</strong>
              <p>방문일 : {props.recoveryData?.treatmentDate}</p>
            </Info>

            <Tags>
              <h3>증상</h3>
              <p>
                {props.recoveryData?.symptomDetails}
              </p>
              <p>{props.recoveryData?.symptoms.toString()}</p>
            </Tags>
          </Content>
          {props.recoveryData?.images.length == undefined ||
            props.recoveryData?.images.length > 0 ?
          <Images>
            <PrevBtn type="button" onClick={onClickPrev}>
               <LeftOutlined />
             </PrevBtn>
             <Carousel ref={carouselRef}>            
               {props.recoveryData?.images.map((img, i) => (
                <Image key={i} src={img.imageUrl} />
              ))}
             </Carousel>
             <NextBtn type="button" onClick={onClickNext}>
               <RightOutlined />
             </NextBtn>
          </Images>
          : ""}
        </Card>
      </Layout>
    </>
  );
};

export default Recovery;
