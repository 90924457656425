import styled from "styled-components";
import { flexbox, flexboxColumn, fontStyle15, fontStyle16, fontStyle22 } from "../../../styles/mixins";

export const Wrapper = styled.div`
  ${flexbox};
  width: 100%;
  height: calc(100vh - 64px);
  padding: 24px;
  color: ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.submenuItem_bg};
`;

export const MenuSection = styled.section`
  flex: 0 0 450px;
  margin: 0 24px 0 0;
`;

export const Header = styled.header`
  ${flexbox("space-between", "flex-end")};
  margin: 0 0 10px 0;
  h1 {
    ${fontStyle22};
    font-weight: 700;
  }
`;

export const Status = styled.div`
  ${flexbox("flex-start", "center")};

  > p {
    ${fontStyle15};
    margin: 0 10px 0 0;
  }

  .ant-switch-checked {
    background-color: ${(props) => props.theme.accentLight};
  }
`;

export const Search = styled.div`
  ${flexbox("flex-start", "center")};
  position: relative;
  height: 46px;
  margin: 0 0 16px 0;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};

  input {
    ${fontStyle16};
    display: block;
    width: 100%;
    height: 46px;
    padding: 0 15px 0 40px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: border-color 300ms ease-in-out;

    &:hover {
      border: 1px solid ${(props) => props.theme.accentLight};
    }

    &:active,
    &:focus {
      border: 1px solid ${(props) => props.theme.accent};
    }
  }

  .anticon {
    color: ${(props) => props.theme.primary};
    padding: 0 0 0 5px;
    position: absolute;
    top: 14px;
    left: 10px;
  }
`;

export const EmptyList = styled.div`
  ${flexboxColumn("center", "center")};
  ${fontStyle16};
  font-weight: 500;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  // 100% - Header높이 - Select높이 - Search높이
  height: calc(100% - 43px - 61px - 61px);
`;

export const ChatSection = styled.section`
  ${flexboxColumn("center", "center")};
  flex: 0 1 auto;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};

  .anticon-message {
    color: #aeb8c2;
    margin: 0 0 10px 0;
  }

  p {
    ${fontStyle16};
    font-weight: 500;
  }
`;
