import styled from "styled-components";
import "antd/dist/antd.css";
import { Select } from "antd";
import { flexbox, fontStyle14, positionCenter } from "../../../styles/mixins";

export const StyledSelect = styled(Select)`
	${flexbox("center", "center")};
	${positionCenter("absolute")};
	${fontStyle14};
	color: ${(props) => props.theme.primary};
	height: 36px;
	min-width: 120px;
	margin: 0 8px 25px 0;
	border-radius: 4px;

	&:not(.ant-select-customize-input) .ant-select-selector {
		height: 36px;
		border: 1px solid ${(props) => props.theme.inputBorder};
		border-radius: 4px;
		transition: border-color 200ms ease-in-out;
	}

	&:not(.ant-select-customize-input) .ant-select-selector:hover {
		border: 1px solid ${(props) => props.theme.inputBorderHover};
	}

	&:not(.ant-select-customize-input) .ant-select-selector:active,
	&:not(.ant-select-customize-input) .ant-select-selector:focus {
		border: 1px solid ${(props) => props.theme.inputBorderFocus};
	}

	&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${(props) => props.theme.inputBorderFocus};
		box-shadow: unset;
	}
	&.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
	&.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		top: 2px;
	}
`;
export const StyledOption = styled(Select.Option)``;
