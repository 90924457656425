import styled, { css } from 'styled-components';
import { flexbox, flexboxColumn, fontStyle13 } from '../../styles/mixins';

type TagStatus = string | '예약' | '취소' | '완료' | '노쇼' | '미처리';

const statusCollections: any = {
  예약: '#f5f5f5',
  취소: '#FFC5C5',
  완료: '#A7FF7D',
  노쇼: '#D2F0FF',
  미처리: 'rgba(84, 69, 255, 0.3)',
  신규: '#000',
  // 휴진: 'rgba(208, 165, 108, 0.5)',
  휴진: '#FFEF61',
};

const whatStatus = (status: TagStatus) => {
  return statusCollections[status];
};

export const StyledStatusBadge = styled.div<{ calendarMode?: boolean }>`
  ${flexbox('center', 'center')}
  ${fontStyle13}
  gap: 5px;

  ${props =>
    props.calendarMode &&
    css`
      justify-content: flex-start;
    `}
`;

export const Badge = styled.span<{ status: TagStatus }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${({ status }) => whatStatus(status)};

  ${props =>
    props.status === '신규' &&
    css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background-color: white;
        transform: rotate(135deg);
        top: 45%;
        left: -1px;
      }
    `}
`;

export const Total = styled.span`
  > strong {
    font-weight: 900;
  }
`;
