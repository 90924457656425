import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { DefaultPopup, InnerTableTag, PageHeading, SubDivider, TableDefault } from '../../../components';
import DetailButton from '../../../components/InnerTdButton/DetailButton';
import RezSearchBox from './RezSearchBox';
import UserRezInfoTable from './UserRezInfoTable';
import UserRezPaymentTable from './UserRezPaymentTable';
import UserRezHistoryTable from './UserRezHistoryTable';
import { StyledLayout, TotalNum } from './styles';
import { useApi } from '../../../contexts/ApiContext';
import { AppointmentListItem, ListResponse, SearchPeriod } from '../../../model';
import { RadioChangeEvent, TablePaginationConfig } from 'antd';

const dateFormat = 'YYYYMMDD';
const RezInquiry = () => {
  const api = useApi();
  const appointmentRef = useRef<any>();

  const [searchStatus, setSearchStatus] = useState<string>('ALL');
  const [displayOption, setDisplayOption] = useState<string>('ALL');
  const [searchOption, setSearchOption] = useState<string>('ALL');
  const onChangeSearchOption = useCallback((value: any) => {
    setDisplayOption(value);}, []);
  const [displayKeyword, setDisplayKeyword] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const onChangeSearchKeyword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDisplayKeyword(event.target.value);}, []);
  const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment().subtract(6, 'month'));
  const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());

  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(25);

  const [appointmentList, setAppointmentList] = useState<ListResponse<AppointmentListItem>>();
  const [appointmentItems, setAppointments] = useState<AppointmentListItem[]>();

  const [detailAppointmentItem, setDetailAppointmentItem] = useState<AppointmentListItem>();
  const [isDetailPopupVisible, setIsDetailPopupVisible] = useState(false);
  const onClickDetail = (appointmentItem: AppointmentListItem) => {
    setDetailAppointmentItem(appointmentItem);
    setIsDetailPopupVisible(true);
  };

  const onChangeStatus = (e: RadioChangeEvent) => {
    setSearchStatus(e.target.value);
  };
  const onChangeDate = (values: RangeValue<Moment>) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  const searchAppointment = useCallback((e: FormEvent) => {
    e.preventDefault();

    setPage(0);
    setSearchOption(displayOption);
    setSearchKeyword(displayKeyword);
  },[displayOption, displayKeyword]);

  const onSearchReset = useCallback(() => {
    setSearchStatus('ALL')
    setDisplayOption('ALL');
    setDisplayKeyword('');
    setStartDate(moment().subtract(3, 'year'))
    setEndDate(moment())
    appointmentRef.current.setPeriod(SearchPeriod.MONTHS);
  }, []);

  const loadAppointmentList = useCallback(async () => {
    const list = (await api.appointment.getList(
      searchStatus,
      searchOption,
      searchKeyword,
      startDate?.format(dateFormat) ?? moment().subtract(3, 'year').format(dateFormat),
      endDate?.format(dateFormat) ?? moment().format(dateFormat),
      page,
      size)).data;

    setAppointmentList(list)
  }, [api.appointment, endDate, page, searchKeyword, searchOption, searchStatus, size, startDate]);

  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    total: appointmentList?.totalElements,
    current: page + 1,
    onChange: (page: number) => {
      setPage(page - 1);
    },
  };

  const rezHistoryColumns: any[] = [
    {
      title: '상태',
      dataIndex: 'appointmentStatus',
      key: 'appointmentStatus',
      render: (value: string) => {
        let status = ''
        if(value === 'APPOINTED') {
          status = '예약'
        }
        if(value === 'VISITED') {
          status = '완료'
        }
        if(value === 'CANCELED') {
          status = '취소'
        }
        if(value === 'NO_SHOW') {
          status = '노쇼'
        }
        return <InnerTableTag status={status}>{status}</InnerTableTag>
      },
    },
    {
      title: '결제/취소번호',
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
    },
    {
      title: '성명(영문명)',
      dataIndex: 'userName',
      key: 'userName',
      render: (value: string, appointmentItem: AppointmentListItem) => {
        const appointeeName = appointmentItem.appointeeName;
        const appointeeNameEn = appointmentItem.appointeeNameEn;
        return (appointeeNameEn === undefined || appointeeNameEn === '' || appointeeNameEn === null) ?
          appointeeName : appointeeName + '(' + appointmentItem.appointeeNameEn + ')';
      }
    },
    {
      title: '방문일',
      dataIndex: 'upcomingDateTime',
      key: 'upcomingDateTime',
    },
    {
      title: '결제/취소일',
      dataIndex: 'executionDate',
      key: 'executionDate',
    },
    {
      title: '의사',
      dataIndex: 'doctorName',
      key: 'doctorName',
    },
    {
      title: '시술명',
      dataIndex: 'treatmentName',
      key: 'treatmentName',
    },
    {
      title: '상세보기',
      dataIndex: 'detail',
      key: 'detail',
      render: (value: string, appointmentItem: AppointmentListItem) => {

        return <DetailButton onClick={() => onClickDetail(appointmentItem)} />
      },
    },
  ];

  useEffect(() => {
    loadAppointmentList().then().catch()
  }, [loadAppointmentList]);

  useEffect(() => {
    const itemsContainKey = appointmentList?.items.map(row => {
      return { key: row.paymentId, ...row };
    });
    if(itemsContainKey) {
      setAppointments(itemsContainKey);
    }
  }, [appointmentList]);

  return (
    <>
      <div>
        <PageHeading>예약 조회</PageHeading>
        {/*  예약조회 검색창 */}
        <StyledLayout>
          <RezSearchBox ref={appointmentRef}
                        status={searchStatus}
                        onChangeStatus={onChangeStatus}
                        displayOptionValue={displayOption}
                        startDate={startDate}
                        endDate={endDate}
                        onChangeDate={onChangeDate}
                        onChangeOption={onChangeSearchOption}
                        searchValue={displayKeyword}
                        onChangeSearch={onChangeSearchKeyword}
                        onReset={onSearchReset}
                        onSubmit={searchAppointment}/>
          <TotalNum>
            총<strong>{appointmentList?.totalElements}</strong>개
          </TotalNum>
          <TableDefault columns={rezHistoryColumns} dataSource={appointmentItems} pagination={pagination}/>
          {isDetailPopupVisible && (
            <DefaultPopup
              title="상세보기"
              isPopupVisible={isDetailPopupVisible}
              setIsPopupVisible={setIsDetailPopupVisible}
              isWide
              isWithOkay
            >
              {/* 예약 정보 */}
              <UserRezInfoTable item={detailAppointmentItem} refresh={loadAppointmentList}/>
              <SubDivider />
              {/* 결제 정보 */}
              <UserRezPaymentTable item={detailAppointmentItem}/>
              <SubDivider />
              {/* 예약 내역 */}
              <UserRezHistoryTable appointeeId={detailAppointmentItem?.appointeeId}/>
            </DefaultPopup>
          )}
        </StyledLayout>
      </div>
    </>
  );
};

export default RezInquiry;
