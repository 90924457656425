import styled from 'styled-components';
import { buttonBase, flexbox, flexboxColumn, tableBase } from '../../../../styles/mixins';

export const Table = styled.table`
  ${tableBase};

  .ant-rate {
    margin: 0 15px 0 0;
  }

  .ant-rate-star:not(:last-child) {
    margin: 0 1px 0 0;
  }
`;

export const ImageWrapper = styled.div`
  ${flexbox('flex-start', 'center')};
  flex-wrap: wrap;
  max-width: 750px;

  > div {
    margin: 0 20px 20px 0;
  }

  > div:nth-of-type(n + 6) {
    margin: 0 20px 0 0;
  }

  > div:last-of-type {
    margin: 0 20px 0 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
  ${flexboxColumn('center', 'flex-start')};
`;

export const TransButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.accent};
`;
