import styled from "styled-components";

export const Button = styled.button`
	height: 32px;
	padding: 0 10px;
	border-radius: 4px;
	background-color: ${(props) => (props.disabled ? props.theme.tagDisabled_bg : props.theme.tag_bg)};
	color: ${(props) => props.theme.white};

	&:not(:disabled):hover {
		background-color: ${(props) => props.theme.tagHover_bg};
	}

	:disabled {
		cursor: not-allowed;
	}
`;
