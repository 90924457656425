import { useState, useCallback, useEffect, ReactNode } from 'react';
import PageHeading from '../../../components/PageHeading';
import TableDefault from '../../../components/Tables/TableDefault';
import InnerTdButton from '../../../components/InnerTdButton';
import UpDownButton from '../../../components/UpDownButton';
import ConfirmPopup from '../../../components/Popup/ConfirmPopup';
import { StyledLayout, FlexWrapper } from './styles';
import { useApi } from '../../../contexts/ApiContext';
import { useNavigate } from 'react-router';
import { DoctorInventoryForPartners, DoctorItemForPartners, ShiftDirection } from '../../../model';
import { ColumnsType } from 'antd/lib/table';

const popupText = {
  delete: (
    <>
      삭제 하시겠습니까?
      <br />
      삭제된 정보는 복구가 불가능하며
      <br />
      앱에 바로 반영됩니다.
    </>
  ),
};

const Doctor = () => {
  const api = useApi();
  const navigate = useNavigate();

  // 의사 목록
  const [doctorsData, setDoctorsData] = useState<DoctorInventoryForPartners>();
  const [doctors, setDoctors] = useState<DoctorItemForPartners[]>([]);

  // 의사 목록 데이터
  const getDoctors = useCallback(async () => {
    const data = (await api.doctor.getDoctors()).data;
    setDoctorsData(data);
  }, [api.doctor]);

  // 팝업 관리
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk: VoidFunction,
    handleCancel: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  const deleteDoctor = useCallback(
    async (item: DoctorItemForPartners) => {
      const data = (await api.doctor.deleteDoctor(item.doctorId)).data;

      if (data.deleted) {
        setIsConfirmVisible(false);
        getDoctors();
      }
    },
    [api.doctor, getDoctors],
  );

  // 삭제
  const onDelete = useCallback(
    (item: DoctorItemForPartners) => {
      const handleOk = () => async () => deleteDoctor(item);
      const handleCancel = () => async () => setIsConfirmVisible(false);

      setPopupData(popupText.delete, true, handleOk, handleCancel);
      setIsDeleteVisible(true);
    },
    [deleteDoctor],
  );

  // 의사 목록 호출
  useEffect(() => {
    getDoctors();
  }, [getDoctors]);

  // 의사 목록 데이터 가공
  useEffect(() => {
    const data = doctorsData?.doctors.map((row, index) => {
      return {
        key: row.doctorId,
        treatmentNames: row.treatments.join(', '),
        ...row,
        displayOrder: index + 1,
      };
    });

    if (data) {
      setDoctors(data);
    }
  }, [doctorsData]);

  // 의사 순서 변경
  const swapDoctors = useCallback(
    async (doctorId: string, direction: ShiftDirection) => {
      const data = (await api.doctor.swapDoctors(doctorId, direction)).data;

      if (data.modified) {
        getDoctors();
      }
    },
    [api.doctor, getDoctors],
  );

  const columns: ColumnsType<DoctorItemForPartners> = [
    {
      title: '순서 변경',
      dataIndex: 'change',
      key: 'change',
      render: (value: any, record: DoctorItemForPartners, index: number) => {
        return (
          <>
            <UpDownButton
              onClick={() => {
                if (index > 0) {
                  swapDoctors(record.doctorId, ShiftDirection.FORWARD);
                }
              }}
            />
            <UpDownButton
              isDown
              onClick={() => {
                if (index < doctors.length - 1) {
                  swapDoctors(record.doctorId, ShiftDirection.BACKWARD);
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: '노출 순서',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      width: 120,
    },
    { title: '성명', dataIndex: 'doctorName', key: 'doctorName' },
    { title: '직위', dataIndex: 'position', key: 'position' },
    {
      title: '시술명',
      dataIndex: 'treatmentNames',
      key: 'treatmentNames',
      width: 360,
      ellipsis: true,
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record: DoctorItemForPartners) => (
        <InnerTdButton
          isWithEdit
          isEditLink
          link={`/management/doctor/${record.doctorId}`}
          onClickDelete={() => onDelete(record)}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeading hasLink="의료진 추가" link="/management/doctor/write">
        의료진 관리
      </PageHeading>
      <StyledLayout>
        <FlexWrapper>
          <span>
            <strong>의료진</strong> | 총 <strong>{doctorsData?.totalElements}</strong>명
          </span>
        </FlexWrapper>
        <TableDefault columns={columns} dataSource={doctors} />
      </StyledLayout>

      {/* 4-13 팝업 */}
      {isDeleteVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          isPopupVisible={isDeleteVisible}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          setIsPopupVisible={setIsDeleteVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default Doctor;
