import React from 'react';
import { UpOutlined } from '@ant-design/icons';
import { StyledButton } from './styles';

interface UpDownButtonProps {
  isDown?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const UpDownButton = ({ isDown, isDisabled, onClick }: UpDownButtonProps) => {
  return (
    <StyledButton type="button" isDown={isDown} disabled={isDisabled} className="up-down-button" onClick={onClick}>
      <UpOutlined style={{ fontSize: '16px' }} />
    </StyledButton>
  );
};

export default React.memo(UpDownButton);
