import styled from 'styled-components';

export const StyledLayout = styled.div`
  @media screen and (min-width: 821px) {
    margin: 30px;
  }

  @media screen and (min-width: 1025px) {
    margin: 50px 65px;
  }
`;
