import styled from "styled-components";
import { flexbox, flexboxColumn, fontStyle14, fontStyle16, fontStyle18, fontStyle24, scrollBase } from "../../styles/mixins";

export const Layout = styled.div`
  ${flexbox("center", "center")};
  width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  overflow-y: auto;
  background: #eef0f3;
`;

export const Card = styled.div`
  ${scrollBase};
  ${flexboxColumn};
  ${fontStyle16};
  width: 510px;
  padding: 30px 25px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.white};
`;

export const Content = styled.div`
  padding: 18px 20px;
  border: 1px solid ${(props) => props.theme.accentLight};
  border-radius: 16px;
  margin: 0 0 40px 0;
`;

export const Elapse = styled.div`
  ${fontStyle18};
  font-weight: 500;
  margin: 0 0 20px 0;

  strong {
    ${fontStyle24};
    font-weight: 700;
  }
`;

export const Info = styled.div`
  margin: 0 0 18px 0;
  font-size: 15px;
  line-height: 1.3;
  strong {
    font-weight: 700;
    ${fontStyle16};
  }
`;

export const Tags = styled.div`
  h3 {
    font-size: 17px;
    font-weight: 500;
  }

  p {
    ${fontStyle14};

    &:first-of-type {
      margin: 0 0 8px;
    }

    &:last-of-type {
      font-weight: 500;
    }
  }
`;

export const Images = styled.div`
  ${flexboxColumn};
  position: relative;
  margin:0 0 23px 0;

  .ant-carousel .slick-list .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ant-carousel .slick-dots li button {
    background-color: ${(props) => props.theme.inputBorder};
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -20px;
  }

  img {
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 350px;
    max-height: 300px;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .anticon {
    font-size: 28px;
    color: rgba(189, 189, 189, 0.5);
    transition: color 300ms ease-in-out;
  }
  &:hover {
    .anticon {
      color: rgba(189, 189, 189);
    }
  }
`;

export const PrevBtn = styled(Button)`
  left: 0;
  z-index: 1;
`;

export const NextBtn = styled(Button)`
  right: 0;
`;