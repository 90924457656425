import api from './index';
import Qs from 'qs';
import { TranslatedResponse } from '../model';

const Translation = {
  transalate: (messageId: string, source: string) => {
    return api.get<TranslatedResponse>(
      `/translation/messages/${messageId}`,
      {
        params: {
          "to": "ko",
          "contents": source
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    )
  }
}

export default Translation;