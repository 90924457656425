import React, { ChangeEventHandler, FormEventHandler, KeyboardEventHandler } from 'react';
import { Input } from "antd";
import { PlusOutlined, SendOutlined } from "@ant-design/icons";
import { Form, FileLabel, TextLabel } from "./styles";

const { TextArea } = Input;

interface MessageInputProps {
  onSubmit?: FormEventHandler
  onChange?: ChangeEventHandler
  fileSend?: ChangeEventHandler
  handleOnKeyUp?: KeyboardEventHandler
  hospitalName: string
  textMessage: string
}

const MessageInput = (props: MessageInputProps) => {
  
  return (
    <Form onSubmit={props.onSubmit}>
      <FileLabel htmlFor="file">
        <input type="file" id="file" accept="image/*" onChange={props.fileSend} />
        <PlusOutlined style={{ fontSize: "18px" }} />
      </FileLabel>
      <TextLabel className="rc-message-box__container" htmlFor="msg">
        <TextArea
            value={props.textMessage}
            onChange={props.onChange}
            autoSize={{ minRows: 1, maxRows: 3 }}
            name="msg"
            id="msg"
            placeholder="입력해주세요" onKeyUp={props.handleOnKeyUp}
            className="rc-message-box__textarea js-input-message"
        />
      </TextLabel>
      <button type="submit">
        <SendOutlined style={{ fontSize: "18px" }} />
      </button>
    </Form>
  );
};

export default React.memo(MessageInput);
