import { Table } from 'antd';
import styled from 'styled-components';
import { buttonBase, flexbox } from '../../../../../styles/mixins';
import { SCROLL_BUTTON_TRIGGER_LENGTH } from '.';

export const Wrapper = styled.div`
  width: 58.5vw;
  height: 72vh;
  overflow-x: hidden;
`;

export const StyledDailyTable = styled(Table)`
  tr {
    &:hover {
      td.disabled {
        background-color: ${props => props.theme.disabled};
        opacity: 0.5;
      }
    }
  }

  td {
    width: 140px;
    margin: 0;

    &.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      z-index: 990;
    }
  }

  .disabled {
    background-color: ${props => props.theme.disabled};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ControlButtonBox = styled.div<{ isHide?: boolean }>`
  width: 106%;
  transform: translateX(-1.75vw);
  position: absolute;
  top: 12.5px;
  z-index: 99;
  ${flexbox('space-between', 'center')}
  display: ${props => (props.isHide ? 'none' : 'flex')};

  button {
    ${buttonBase}
    width: 10px;
    height: auto;
    padding: 5px 12px;

    &.ant-btn:focus {
      border-color: ${props => props.theme.accentLight};
    }
  }
`;

export const ScrollSection = styled.div<{ thLength?: any }>`
  width: ${props =>
    props.thLength > SCROLL_BUTTON_TRIGGER_LENGTH ? `calc((76px + (152px * ${props.thLength})))` : 'auto'};
  overflow-x: scroll;
`;

export const StyledDailyView = styled.div`
  position: relative;
`;
