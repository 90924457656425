import React, { useState } from "react";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import { useAuth } from "../../contexts/AuthContext";
import { Header, StyledLogo, FlexWrapper, Button, AdminName } from "./styles";

// 페이지 전체 레이아웃 상단 Global Navigation Bar
const GNB = () => {
	const auth = useAuth();
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	const showPopup = () => setIsPopupVisible(true);

	return (
		<>
			<Header>
				<div>
					<StyledLogo to="/" />
				</div>
				<FlexWrapper>
					<AdminName>{auth.user?.username}</AdminName>
					<Button isFilled isMiddle onClick={showPopup}>
						로그아웃
					</Button>
				</FlexWrapper>
			</Header>

			{isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isLogout isWithCancel>
					로그아웃 하시겠습니까?
				</ConfirmPopup>
			)}
		</>
	);
};

export default React.memo(GNB);
