import { Badge, StyledStatusBadge, Total } from './styles';

interface StatusTagProps {
  totalNum?: number;
  status: string;
  calendarMode?: boolean;
}

const StatusTag = ({ status, totalNum, calendarMode }: StatusTagProps) => {
  return (
    <StyledStatusBadge calendarMode={calendarMode}>
      <Badge status={status} />
      {totalNum ? (
        calendarMode ? (
          <Total>
            <strong>{totalNum > 99 ? '99' : totalNum}</strong> 건 {totalNum > 99 ? '+' : ''}
          </Total>
        ) : (
          <Total>
            {status}: <strong>{totalNum}</strong> 건
          </Total>
        )
      ) : (
        <Total>{status}</Total>
      )}
    </StyledStatusBadge>
  );
};

export default StatusTag;
