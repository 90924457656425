import styled from "styled-components";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import { flexbox, positionCenterX, fontStyle16, fontStyle12 } from "../../styles/mixins";

export const StyledSider = styled(Layout.Sider)`
	flex: 0 0 260px !important;
	max-width: 260px !important;
	min-width: 260px !important;
	width: 260px !important;
	height: 100vh;
	overflow: auto;
	position: fixed;
	/* GNB  높이 64px */
	top: 64px;
	left: 0;
	bottom: 0;

	@media screen and (max-width: 1535px) {
		flex: 0 0 150px !important;
		max-width: 150px !important;
		min-width: 150px !important;
		width: 150px !important;
	}

	a.inquiry {
		${positionCenterX("absolute")}
		bottom:125px;

		:hover {
			color: ${(props) => props.theme.white};
		}
	}
`;

export const StyledMenu = styled(Menu)`
	height: 100vh;

	.ant-menu-item a:hover,
	.ant-menu-item-selected a,
	.ant-menu-item-selected a:hover {
		color: ${(props) => props.theme.accent};
	}

	.ant-menu-item-selected a {
		font-weight: 500;
	}

	/* 현재 선택된 메뉴 아이템*/
	&:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: ${(props) => props.theme.menuItem_bg};
		color: ${(props) => props.theme.accent};
	}

    .ant-menu-item::after,
    .ant-menu-item-active::after,
    .ant-menu-item-selected::after {
        border-right: 3px solid ${(props) => props.theme.accent};
    }

	.ant-menu-sub .ant-menu-item-selected {
		background-color: ${(props) => props.theme.submenuItem_bg};
	}

	/* 메뉴 아이템 active */
	.ant-menu-submenu-title:active,
	.ant-menu-item:active {
		background-color: ${(props) => props.theme.submenuItem_bg};
	}

	/* 메뉴 아이템 hover */

	.ant-menu-item-active:hover,
	.ant-menu-submenu-title:hover {
		color: ${(props) => props.theme.accent};
	}

	.ant-menu-submenu:hover .ant-menu-submenu-arrow,
	.ant-menu-submenu:hover .ant-menu-submenu-title {
		color: ${(props) => props.theme.accent};
	}

	.ant-menu-submenu-active,
	.ant-menu-submenu-selected {
		color: ${(props) => props.theme.accent};

		.ant-menu-submenu-arrow {
			color: ${(props) => props.theme.accent};
		}
	}

	/* .ant-menu-title-content a {
		${flexbox("space-between", "center")};
	} */
`;

export const StyledLink = styled.a`
	${flexbox("center", "center")};
	${fontStyle16};
	${positionCenterX("absolute")};
	bottom: 130px;
	min-width: 130px;
	height: 46px;
	padding: 0 15px;
	border-radius: 4px;
	text-align: center;
	font-weight: 500;
	background-color: ${(props) => props.theme.accentLight};
	color: ${(props) => props.theme.white};

	transition: background-color 300ms ease-in-out;

	&:hover {
		background-color: ${(props) => props.theme.accent};
		color: ${(props) => props.theme.white};
	}

	&:active {
		background-color: ${(props) => props.theme.accentDark};
	}

	&:focus {
		background-color: ${(props) => props.theme.accentLight};
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
`;

export const Unread = styled.span`
	${flexbox("center", "center")};
	${fontStyle12};
	display: inline-block;
	padding: 1px 4px;
	border-radius: 4px;
	min-width: 20px;
	height: 20px;
	margin: 0 0 0 10px;
	text-align: center;
	color: ${(props) => props.theme.white};
	background-color: ${(props) => props.theme.tag_bg};
`;
