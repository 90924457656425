import axios from 'axios';
import Authentication from './Authentication';

let isTokenRefreshing = false;
let refreshSubscribers: any[] = [];

const onTokenRefreshed = (accessToken: string) => {
  refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback: Function) => {
  refreshSubscribers.push(callback);
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if ((config.url !== '/partners/login' && config.url !== '/token.refresh') && accessToken) {
      config.headers = {
        'Authorization': `Bearer ${accessToken}`,
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401 && !(originalRequest.url === '/partners/login')) {
      // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken: string) => {
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;
          originalRequest.headers["Content-Type"] = "application/json";
          resolve(api(originalRequest));
        });
      });

      if (!isTokenRefreshing) {
        isTokenRefreshing = true;
        localStorage.removeItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken') ?? '';

        if (refreshToken) {
          const { data } = await Authentication.refreshToken(refreshToken);

          if (data.success) {
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);

            isTokenRefreshing = false;
            // 새로운 토큰으로 지연되었던 요청 진행
            onTokenRefreshed(data.accessToken);
          } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }

      return retryOriginalRequest;
    } else if (status === 403) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
    return Promise.reject(error);
  }
);

export default api;
