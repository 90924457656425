import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import { useEffect, useState } from 'react';
import { StyledContent, StyledLayout } from './styles';

const Reservation = () => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState<string[]>(['4']);
  useEffect(() => {
    let menu: string[] = [];
    if (pathname.startsWith('/reservation/status')) {
      menu = ['4'];
    } else if (pathname.startsWith('/reservation/inquiry')) {
      menu = ['5'];
    } else if (pathname.startsWith('/reservation/settings')) {
      menu = ['6'];
    }

    setMenu(menu);
  }, [pathname]);
  return (
    <>
      <Layout>
        <GNB />
        <Layout>
          <LNB defaultMenu={menu} defaultOpenKeys={['sub2']} />
          <StyledLayout>
            <StyledContent>
              <Outlet />
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Layout>
    </>
  );
};

export default Reservation;
