import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { StyledButton } from '../styles';
import { MouseEventHandler } from 'react';

interface DeleteButtonProps {
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
}

const DeleteButton = (props: DeleteButtonProps) => {
  return (
    <StyledButton type="button" onClick={props.onClick} disabled={props.isDisabled}>
      <DeleteOutlined style={{ fontSize: '18px' }} />
    </StyledButton>
  );
};

export default React.memo(DeleteButton);
