import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  buttonBase,
  flexbox,
  flexboxColumn,
  fontStyle12,
  fontStyle14,
  fontStyle15,
  fontStyle16,
  fontStyle18,
  fontStyle24,
} from '../../../../../styles/mixins';
import { Divider } from 'antd';

export const List = styled.ul`
  ${flexboxColumn};
  padding: 24px 24px 50px;
`;

export const Item = styled.li`
  position: relative;
  width: 100%;
  border-radius: 8px;
  margin: 25px 0;

  /* 메시지 공통 */
  > div {
    ${flexbox};
    width: 60%;

    .message-body {
      ${flexbox};
    }
  }

  /* 병원 관리자가 보낸 메시지 */
  &.own > div {
    flex-direction: row-reverse;
    margin: 0 0 0 auto;

    .message-body {
      flex-direction: row-reverse;
    }
  }

  /* 로켓챗 시스템 메시지 e.g. '~~님이 대화에 참여했습니다.', '~~님이 나가셨습니다.' */
  &.system {
    > div {
      ${flexbox('center', 'flex-end')};
      ${fontStyle14};
      width: 100%;
      min-height: 25px;
      padding: 0 0 0 20px;
      margin: 10px 0 0 0;

      .anticon-user {
        position: relative;
        bottom: 3px;
        margin: 0 1px 0 0;
      }

      span {
        font-weight: 500;
      }

      p {
        color: ${props => props.theme.chatGrey};
      }
    }
  }

  /* 새로운 날짜 시작 */
  &.new-day {
    margin: 40px 0 0 0;

    ::after,
    ::before {
      position: absolute;
      display: block;
      pointer-events: none;
    }

    ::before {
      content: attr(data-date);
      ${fontStyle12};
      top: -30px;
      left: calc(50% - 70px);
      min-width: 140px;
      padding: 0 10px;
      z-index: 1;
      text-align: center;
      border-radius: 2px;
      font-weight: 700;
      background-color: ${props => props.theme.white};
    }

    ::after {
      content: ' ';
      top: -20px;
      right: 0;
      left: 0;
      border-width: 1px 0 0;
      border-color: #f2f3f5;
      border-style: solid;
    }
  }
  /* 이미지 파일 첨부 */

  &.has-attachment {
    .message-body {
      ${flexboxColumn};
    }
  }
  /* 메시지 전송 실패 */
  .message-actions {
    display: none;
  }

  &.failed .message-actions {
    display: flex;
  }

  &.failed .time {
    display: none;
  }

  /* 회복 기록 */

  &.recovery .body,
  &.appointment .body {
    background-color: ${props => props.theme.white};
  }
`;

export const User = styled.div`
  ${fontStyle16};
  font-weight: 500;
  margin: 0 0 2px 0;
`;

export const Body = styled.div`
  ${fontStyle15};
  padding: 10px 15px;
  background-color: #eee;
  border-radius: 8px;
  word-break: break-word;
`;

export const Time = styled.time<{ datetime?: string }>`
  ${fontStyle12};
  color: ${props => props.theme.chatGrey};
  flex-shrink: 0;
  margin: auto 5px 0 5px;
`;

export const Attach = styled.div`
  max-width: 480px;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  p {
    ${fontStyle15};
    padding: 10px 15px;
    margin: 0 0 10px 0;
    background-color: #eee;
    border-radius: 8px;
    word-break: break-word;
  }

  .rcx-attachment__content {
    width: 100%;

    picture {
      /* background-position: center;
			background-size: repeat;
			background-repeat: no-repeat;
			box-sizing: content-box; */

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
`;

export const ActionsWrapper = styled.div`
  margin: auto 0 0 0;

  button {
    ${flexbox('center', 'center')};
    width: 26px;
    height: 22px;
    background-color: #fadb13;
    transition: background-color 300ms ease-in-out;

    :first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      margin: 0 1px 0 0;
    }

    :last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin: 0 3px 0 0;
    }

    :hover {
      background-color: #edd013;
    }

    :active {
      background-color: #e3c712;
    }
  }
`;

// 회복 기록 추가
export const RecoveryCard = styled.div`
  width: 410px;
  border-radius: 10px;
  background-color: ${props => props.theme.white};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`;

export const Elapse = styled.p`
  margin: 0 0 15px 0;
  ${fontStyle18};
  font-weight: 500;

  strong {
    ${fontStyle24};
    font-weight: 700;
  }
`;

export const Details = styled.p`
  margin: 0 0 15px 0;
`;

export const Tags = styled.div`
  ${flexbox('flex-start', 'center')};
  flex-wrap: wrap;
  gap: 6px 5px;
  margin: 0 0 20px 0;

  span {
    padding: 2px 10px;
    min-width: 50px;
    height: 30px;
    border: 1px solid ${props => props.theme.accentLight};
    border-radius: 16px;
    text-align: center;
  }
`;

export const Info = styled.div`
  padding: 20px 0 0 0;
  margin: 0 0 30px 0;
  border-top: 1px solid ${props => props.theme.submenuItemDark_bg};

  strong {
    font-weight: 700;
  }
`;

export const StyledLink = styled(Link)`
  ${buttonBase};
  ${flexbox('center', 'center')};
  width: 100%;
  height: 42px;
  text-align: center;
  border-radius: 8px;
`;

export const StyledDetail = styled.div`
  ${buttonBase};
  ${flexbox('center', 'center')};
  width: 100%;
  height: 42px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
`;

export const CarouselWrapper = styled.div`
  max-width: 350px;
  height: 200px;
  margin: 0 0 60px 0;

  ${flexboxColumn};
  position: relative;
  margin: 0 0 23px 0;

  .ant-carousel .slick-list .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ant-carousel .slick-dots li button {
    background-color: ${props => props.theme.inputBorder};
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -20px;
  }

  img {
    width: auto;
    height: auto;
    max-width: 350px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .anticon {
    font-size: 28px;
    color: rgba(189, 189, 189, 0.5);
    transition: color 300ms ease-in-out;
  }
  &:hover {
    .anticon {
      color: rgba(189, 189, 189);
    }
  }
`;

export const PrevBtn = styled(Button)`
  left: 0;
  z-index: 1;
`;

export const NextBtn = styled(Button)`
  right: 0;
`;

export const ChatDivider = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background-color: ${props => props.theme.divider_bg};
`;

export const TransButton = styled.button`
  ${fontStyle12};
  color: ${props => props.theme.accent};
  flex-shrink: 0;
  margin: auto 5px 0 5px;
`;
