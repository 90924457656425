import React from 'react';
import { StyledSelect, StyledOption } from "./styles";

interface ItemDefault {
	value: string;
	text: string;
}

interface SelectProps<T> {
	optionList: T[];
	style?: any;
}

const Select = <ItemType extends ItemDefault | string>(props: SelectProps<ItemType>) => {
	const handleChange = (value: any) => console.log(value);

	const options = props.optionList?.map((option, i) => (
		<StyledOption key={i} value={option}>
			{option}
		</StyledOption>
	));

	return (
		<StyledSelect
			defaultValue={props.optionList[0]}
			style={props.style}
			onChange={handleChange}
			dropdownStyle={{ borderRadius: "4px" }}
		>
			{options}
		</StyledSelect>
	);
};

export default React.memo(Select);
