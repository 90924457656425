import React, {
  ChangeEventHandler,
  FormEventHandler,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import {
  ButtonWrapper,
  Form,
  Input,
  OptionWrapper,
  SearchButton,
  StyledRadioButton,
  StyledRadioGroup,
  StyledRangePicker,
  Title,
  StatusWrapper,
  Wrapper,
  Divider,
  SearchWrapper,
  FlexWrapper,
} from './styles';
import { Select } from '../../../../components';
import { AppointmentSearchOption, SearchPeriod } from '../../../../model';
import { RadioChangeEvent } from 'antd';
import moment, { Moment } from 'moment';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';

interface RezSearchBoxProps {
  title?: string;
  status?: string;
  onChangeStatus?: any;
  optionList?: any[];
  displayOptionValue: string;
  onChangeOption?: (event: RadioChangeEvent) => void;
  titleUnder?: ReactNode;
  optionListUnder?: any[];
  onChangeUnderOption?: ChangeEventHandler<HTMLSelectElement>;
  startDate?: EventValue<Moment>;
  endDate?: EventValue<Moment>;
  onChangeDate?: (values: RangeValue<Moment>) => void;
  searchValue: string;
  onChangeSearch?: ChangeEventHandler<HTMLInputElement>;
  allowClear?: boolean;
  onReset?: MouseEventHandler<HTMLButtonElement>;
  onSubmit: FormEventHandler;
}

const RezSearchBox = forwardRef((props: RezSearchBoxProps, ref) => {
  const { onChangeDate, onChangeStatus, onReset, startDate, endDate } = props;

  const [period, setPeriod] = useState<SearchPeriod>(SearchPeriod.MONTHS);

  useImperativeHandle(ref, () => ({
    setPeriod,
  }));

  const onChangePeriod = (e: RadioChangeEvent) => {
    const period: SearchPeriod = e.target.value;
    if (onChangeDate) {
      let startDate = moment();
      let endDate = moment();
      switch (period) {
        case SearchPeriod.TODAY: {
          startDate = moment();
          endDate = moment();
          break;
        }
        case SearchPeriod.WEEK: {
          startDate = moment().subtract(1, 'week');
          endDate = moment();
          break;
        }
        case SearchPeriod.MONTH: {
          startDate = moment().subtract(1, 'month');
          endDate = moment();
          break;
        }
        case SearchPeriod.MONTHS: {
          startDate = moment().subtract(6, 'month');
          endDate = moment();
        }
      }

      onChangeDate([startDate, endDate]);
    }
    setPeriod(period);
  };

  const optionList = [
    { value: AppointmentSearchOption.ALL, text: '전체' },
    { value: AppointmentSearchOption.PAYMENT_NO, text: '결제취소번호' },
    { value: AppointmentSearchOption.APPOINTEE_NAME, text: '성명' },
    { value: AppointmentSearchOption.DOCTOR_NAME, text: '의사' },
    { value: AppointmentSearchOption.TREATMENT_NAME, text: '시술명' },
  ];

  return (
    <>
      <Wrapper>
        <Form onSubmit={props.onSubmit}>
          <FlexWrapper>
            <StatusWrapper>
              <Title>
                <h1>예약 상태</h1>
              </Title>
              <StyledRadioGroup value={props.status} onChange={onChangeStatus}>
                <StyledRadioButton value={'ALL'}>전체</StyledRadioButton>
                <StyledRadioButton value={'APPOINTED'}>예약</StyledRadioButton>
                <StyledRadioButton value={'CANCELED'}>취소</StyledRadioButton>
                <StyledRadioButton value={'VISITED'}>완료</StyledRadioButton>
                <StyledRadioButton value={'NO_SHOW'}>노쇼</StyledRadioButton>
              </StyledRadioGroup>
            </StatusWrapper>
            <OptionWrapper>
              <div>
                <Title>
                  <h1>기간</h1>
                </Title>

                <StyledRangePicker value={startDate && endDate ? [startDate, endDate] : null} onChange={onChangeDate} />
              </div>
              <StyledRadioGroup value={period} onChange={onChangePeriod}>
                <StyledRadioButton value={SearchPeriod.TODAY}>오늘</StyledRadioButton>
                <StyledRadioButton value={SearchPeriod.WEEK}>1주일</StyledRadioButton>
                <StyledRadioButton value={SearchPeriod.MONTH}>1개월</StyledRadioButton>
                <StyledRadioButton value={SearchPeriod.MONTHS}>6개월</StyledRadioButton>
              </StyledRadioGroup>
            </OptionWrapper>

            <SearchWrapper>
              <Title>
                <h1>검색</h1>
              </Title>
              <Select
                style={{ margin: '0 15px 0 0', minWidth: '120px' }}
                optionList={optionList}
                value={props.displayOptionValue}
                onChange={props.onChangeOption}
              />
              <Input
                type="text"
                placeholder="검색어를 입력하세요."
                value={props.searchValue}
                onChange={props.onChangeSearch}
              />
            </SearchWrapper>
          </FlexWrapper>
          <Divider />
          <ButtonWrapper isReset>
            <SearchButton type="submit">
              <span>검색</span>
              <SearchOutlined style={{ fontSize: '19px' }} />
            </SearchButton>
            <SearchButton type="button" isReset onClick={onReset}>
              <span>초기화</span>
              <RedoOutlined style={{ fontSize: '19px' }} />
            </SearchButton>
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </>
  );
});

export default React.memo(RezSearchBox);
