import { Layout } from 'antd';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import ContentHeading from '../../components/ContentHeading';
import Patient from './Patient';
import { StyledLayout, StyledContent } from './styles';

const Review = () => {
  return (
    <>
      <Layout>
        <GNB />
        <Layout>
          <LNB defaultMenu={['8']} defaultOpenKeys={['8']} />
          <StyledLayout>
            <StyledContent>
              <ContentHeading>리뷰 관리</ContentHeading>
              <Patient />
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Layout>
    </>
  );
};

export default Review;
