import styled, { css } from 'styled-components';
import { fontStyle12 } from '../../../../../styles/mixins';

export const Day = styled.div<{
  empty?: any;
  today?: any;
  selected?: any;
  isOffed?: boolean;
  isToday?: boolean;
  isSelected?: any;
  isNone?: any;
}>`
  box-sizing: border-box;
  flex: 1;
  text-align: center;
  padding: 12px;
  margin: 2.5px;
  padding: 10px 12px;
  color: ${props => props.theme.primary};

  background-color: ${props => (props.isToday || props.today ? '#f5f5f5' : 'transparent')};

  border: ${props => (props.selected ? `1px solid ${props.theme.accent}` : `1px solid transparent`)};
  border-bottom: ${props => (props.isToday || props.today ? `2px solid ${props.theme.accentDark}` : null)};
  border-radius: 4px;
  width: calc(100% / 7);
  height: 10.5vh;
  transition: all 250ms ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:hover {
    background-color: #f5f5f5;
  }

  ${props =>
    props.empty &&
    css`
      visibility: hidden;
    `}

  ${props =>
    props.isOffed &&
    css`
      background-color: rgba(255, 239, 97, 0.5);

      &:hover {
        background-color: rgba(255, 239, 97, 0.5);
      }

      &:active,
      &:focus {
        background-color: rgba(255, 239, 97, 0.5);
      }
    `}

   ${props =>
    props.isSelected &&
    css`
      border: 1px solid ${props => props.theme.accentDark};
    `}

   ${props =>
    props.isNone &&
    css`
      background-color: grey;
    `}
`;

export const Total = styled.div`
  ${fontStyle12};
  text-align: left;
  color: #555;
  strong {
    font-weight: 900;
  }
`;

export const CalendarBadgeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;

  span:last-child {
    display: none;
  }

  @media screen and (min-width: 1536px) {
    span:last-child {
      display: block;
    }
  }
`;
