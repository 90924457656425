import React, { ReactNode } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { StyledModal, CloseButton, FlexWrapper, Button } from './styles';

interface DefaultPopupProps {
  title: string;
  isPopupVisible?: boolean;
  isWithCancel?: boolean;
  isWithOkay?: boolean;
  isWithEdit?: boolean;
  isWithSave?: boolean;
  isWide?: boolean;
  children?: ReactNode | ReactNode[];
  okWithoutClose?: boolean;
  setIsPopupVisible?: (visible: boolean) => void;
  setIsConfirmVisible?: (visible: boolean) => void;
  setIsEditVisible?: (visible: boolean) => void;
  onClickCancel?: () => void;
  onClickOk?: () => void;
}

const DefaultPopup = (props: DefaultPopupProps) => {
  const onClickCancel = () => props.setIsPopupVisible && props.setIsPopupVisible(false);

  const onClickOk = () => {
    //입력 완료
    props.setIsPopupVisible && props.setIsPopupVisible(false);
  };

  const onOk = () => {
    if (props.onClickOk) {
      props.onClickOk();
    }

    if (!props.okWithoutClose) {
      props.setIsPopupVisible && props.setIsPopupVisible(false);
    }
  };

  return (
    <>
      <StyledModal
        centered
        footer={null}
        visible={props.isPopupVisible}
        onOk={onClickOk}
        onCancel={onClickCancel}
        isWide={props.isWide}
      >
        <div>
          <h1>{props.title}</h1>
          <CloseButton type="button" onClick={onClickCancel}>
            <CloseOutlined style={{ fontSize: '12px' }} />
          </CloseButton>
          {props.children}
        </div>

        <FlexWrapper>
          {props.isWithCancel && (
            <Button isBig onClick={onClickCancel}>
              취소
            </Button>
          )}
          {props.isWithOkay && (
            <Button isBig onClick={onOk}>
              확인
            </Button>
          )}
          {props.isWithSave && (
            <Button isBig isFilled onClick={onOk}>
              저장
            </Button>
          )}
          {props.isWithEdit && (
            <Button isBig onClick={onOk}>
              편집
            </Button>
          )}
        </FlexWrapper>
      </StyledModal>
    </>
  );
};

export default React.memo(DefaultPopup);
