import styled from 'styled-components';
import { fontStyle16, tableBase } from '../../../styles/mixins';
import { Layout } from 'antd';

// /*
//   export const StyledLayout = styled.div`
//   @media (min-width: 821px) {
//     /* margin: 0 30px; */
//     margin: 30px 24px;
//   }

//   @media screen and (min-width: 1025px) {
//     /* margin: 0 65px; */
//   }
// `;
//  */

export const StyledLayout = styled.div`
  @media screen and (min-width: 821px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1025px) {
    margin: 0 65px;
  }
`;

export const StyledContent = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;

export const SubHeading = styled.h2`
  ${fontStyle16}
  font-weight: 500;
  color: ${props => props.theme.primary};
  margin-bottom: 20px;

  > span {
    margin: 0 0 0 15px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 20%;
      left: -7.5px;
      width: 2px;
      height: 70%;
      background-color: black;
    }
  }
`;

export const Table = styled.table`
  ${tableBase};
`;

export const TotalNum = styled.span`
  ${fontStyle16};
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
  > strong {
    font-weight: 700;
    margin: 0 5px;
  }
`;
