import submit_default from '../../../../../../assets/submit-default.svg';
import reserve_default from '../../../../../../assets/reserve-default.svg';
import reserve_active from '../../../../../../assets/reserve-active.svg';
import reserve_cancel from '../../../../../../assets/reserve-cancel.svg';
import review_default from '../../../../../../assets/review-default.svg';
import review_active from '../../../../../../assets/review-active.svg';
import visit_default from '../../../../../../assets/visit-default.svg';
import visit_active from '../../../../../../assets/visit-active.svg';


  interface DisplayedStatusList {
    [key: string]: { name: string; icon: string }[];
  }

  interface StatusListItem {
    status: string;
    displayedStatus: { name: string; icon: string }[];
    activeIdx: number;
    title: string;
    subTitle: string;
    desc: string;
  }

  // sample: 예시
  // reserve: 예약
  // cancel: 예약 취소
  // noshow: 노쇼 취소
  // visit: 방문
  // review: 리뷰 작성 시
  // unreview: 리뷰 미작성 시
  // remind: 리뷰 미작성 15일 후 재알림
  const displayedStatusList: DisplayedStatusList = {
    sample: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_default },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_default },
    ],
    APPOINTED: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_active },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_default },
    ],
    CANCELED: [
      { name: 'Submit', icon: submit_default },
      { name: 'Cancel', icon: reserve_cancel },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_default },
    ],
    NO_SHOW: [
      { name: 'Submit', icon: submit_default },
      { name: 'Cancel', icon: reserve_cancel },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_default },
    ],
    APPOINTMENT_DATE: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_default },
      { name: 'Visit', icon: visit_active },
      { name: 'Review', icon: review_default },
    ],
    VISITED: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_default },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_active },
    ],
    unreview: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_default },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_active },
    ],
    REQUEST_REVIEW: [
      { name: 'Submit', icon: submit_default },
      { name: 'Reserve', icon: reserve_default },
      { name: 'Visit', icon: visit_default },
      { name: 'Review', icon: review_active },
    ],
  };

  const statusList: StatusListItem[] = [
    {
      status: 'sample',
      displayedStatus: displayedStatusList['sample'],
      activeIdx: -1,
      title: 'Sample Title Text',
      subTitle: 'Sample Subtitle Text',
      desc: 'Sample description text.  Sample description text. Sample description text. Sample description text text. ',
    },
    {
      status: 'APPOINTED',
      displayedStatus: displayedStatusList['APPOINTED'],
      activeIdx: 1,
      title: 'Appointment completed',
      subTitle: 'Reminder will be sent on visit date',
      desc: 'If you cancel the appointment 2 days before the visit, you will get a full refund. If you cancel the appointment 1 day before the visit, you will get a 50% refund. You cannot cancel the appointment on the day of the visit.',
    },
    {
      status: 'CANCELED',
      displayedStatus: displayedStatusList['CANCELED'],
      activeIdx: 1,
      title: 'Appointment canceled',
      subTitle: '',
      desc: 'Based on the actual situation of each credit card company, the refund time will take 1 to 3 weeks.',
    },
    {
      status: 'NO_SHOW',
      displayedStatus: displayedStatusList['NO_SHOW'],
      activeIdx: 1,
      title: 'Appointment canceled',
      subTitle: '',
      desc: 'Your appointment has been canceled due to a no-show.',
    },
    {
      status: 'APPOINTMENT_DATE',
      displayedStatus: displayedStatusList['APPOINTMENT_DATE'],
      activeIdx: 2,
      title: 'Today is my appointment day for the hospital visit',
      subTitle: '',
      desc: 'If you are more than 30 minutes late, the appointment will be automatically canceled and no refund will be given.',
    },
    {
      status: 'VISITED',
      displayedStatus: displayedStatusList['VISITED'],
      activeIdx: 3,
      title: 'How does it feel to come to the hospital?',
      subTitle: '',
      desc: 'For the convenience of our customers, please leave your real reviews on this item. After 30 days from the date of visit, the review function will be closed.',
    },
    {
      status: 'unreview',
      displayedStatus: displayedStatusList['unreview'],
      activeIdx: 3,
      title: 'How does it feel to come to the hospital?',
      subTitle: '',
      desc: 'For the convenience of our customers, please leave your real reviews on this item. After 30 days from the date of visit, the review function will be closed.',
    },
    {
      status: 'REQUEST_REVIEW',
      displayedStatus: displayedStatusList['REQUEST_REVIEW'],
      activeIdx: 3,
      title: 'Please leave a review',
      subTitle: '',
      desc: 'For the convenience of customers, please leave your real reviews of the hospital. After the visit date has passed, the review function will be closed.',
    },
  ];


export {statusList, displayedStatusList} ;