import React, { ChangeEvent, useState } from 'react';
import { Container, FlexWrapper, RadioWrapper, StyledPicker, StyledPickerWrapper, StyledRadio } from './styles';
import { Label } from '../../../../components';
import { Radio, RadioChangeEvent, RadioProps } from 'antd';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

interface RezRadioTimeProps extends RadioProps {
  dayOfWeek?: string;
  scheduleType?: string;
  morningStartAt?: string;
  morningEndAt?: string;
  afternoonStartAt?: string;
  afternoonEndAt?: string;
  onChangeScheduleType?: (event: RadioChangeEvent) => void;
  onChangeMorningTime?: (values: RangeValue<Moment>) => void;
  onChangeAfternoonTime?: (values: RangeValue<Moment>) => void;
}

const RezRadioTime = (props: RezRadioTimeProps) => {
  const { dayOfWeek, scheduleType, morningStartAt, morningEndAt, afternoonStartAt, afternoonEndAt } = props;
  const localTimeToMoment = (time?: string): Moment => {
    return time ? moment(time, 'HH:mm:ss') : moment();
  };

  return (
    <Container>
      <Label text={dayOfWeek ?? ''} isRequired />
      <FlexWrapper>
        <RadioWrapper>
          <Radio.Group
            value={scheduleType}
            onChange={props.onChangeScheduleType}
          >
            <StyledRadio value={'FULL_DAY'}>전일진료</StyledRadio>
            <StyledRadio value={'MORNING'}>오전진료</StyledRadio>
            <StyledRadio value={'AFTERNOON'}>오후진료</StyledRadio>
            <StyledRadio value={'CLOSED'}>진료안함(휴진)</StyledRadio>
          </Radio.Group>
        </RadioWrapper>
        <StyledPickerWrapper>
          <div>
            <span>오전</span>
            <StyledPicker format="HH:mm" minuteStep={10}
                          value={[localTimeToMoment(morningStartAt), localTimeToMoment(morningEndAt)]}
                          onChange={props.onChangeMorningTime}
                          disabled={(scheduleType === "AFTERNOON" || scheduleType === "CLOSED")}
            />
          </div>
          <div>
            <span>오후</span>
            <StyledPicker format="HH:mm" minuteStep={10}
                          value={[localTimeToMoment(afternoonStartAt), localTimeToMoment(afternoonEndAt)]}
                          onChange={props.onChangeAfternoonTime}
                          disabled={(scheduleType === "MORNING" || scheduleType === "CLOSED")}
            />
          </div>
        </StyledPickerWrapper>
      </FlexWrapper>
    </Container>
  );
};

export default RezRadioTime;
