import React, { useCallback, useEffect, useState } from 'react';
import { Popover } from 'antd';
import {
  ChangeStatusButton,
  ChangeStatusPopover,
  DataWrapper,
  DisplayButton,
  InnerCellCardWrapper,
  Status,
  StyledCard,
  StyledTreat,
  StyledUser,
} from './styles';
import ConfirmPopup from '../Popup/ConfirmPopup';
import { stat } from 'fs';

interface InnerCellCardProps {
  id: string;
  doctorId: string;
  currentDate: any;
  timeLine: string;
  content?: any;
  isNew?: boolean;
  status?: string;
  statusText: string;
  userName?: string;
  doctorName?: string;
  treatName?: string;
  visitDate?: string;
  isDaily?: boolean;
  columnStyle?: any;
  onSwitchStatus?: any;
  onBreak?: any;
  isLast?: boolean;
  isDimmed?: boolean;
  isAppointed?: boolean;
}

const popupText = {
  done: (
    <>
      예약을 완료하시겠습니까? <br />
      예약 완료시 환자에게 알림이 전송됩니다.
      <br />
      <small>* 상태 변경 시 상태를 되돌릴 수 없습니다.</small>
    </>
  ),
  noShow: (
    <>
      노쇼로 변경하시겠습니까? <br />
      예약 상태 변경 시 환자에게 알림이 전송됩니다. <br />
      <small>* 상태 변경 시 상태를 되돌릴 수 없습니다.</small>
    </>
  ),
  offed: (
    <>
      휴진 설정하시겠습니까? <br />
      설정 시 해당 시간에는 예약을 받을 수 없습니다. <br />
      <small>* 상태 변경 시 상태를 되돌릴 수 없습니다.</small>
    </>
  ),
  reserved: (
    <>
      이미 예약이 있습니다. <br />
      예약 취소 후 휴진 설정할 수 있습니다.
    </>
  ),
};

const InnerCellCard = ({
  id,
  doctorId,
  currentDate,
  timeLine,
  content,
  isNew,
  status,
                         statusText,
  userName,
  treatName,
  columnStyle,
  onSwitchStatus,
  onBreak,
  isAppointed,
  isLast,
  isDimmed,
}: InnerCellCardProps) => {
  const [isRightClicked, setIsRightClicked] = useState(false);
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const handleRightClickCard = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsRightClicked(p => !p);
  };

  const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction) => {
    setPopupHandleOk(handleOk);
  };

  const [popupRez, setPopupRez] = useState(false);
  const [popupOffed, setPopupOffed] = useState(false);
  const [popupNoShow, setPopupNoShow] = useState(false);
  const [popupVisited, setPopupVisited] = useState(false);

  const onSwitchStatusPopup = useCallback((switchStatus: string) => {
    if(switchStatus === 'VISITED') {
      setPopupRez(true);
      setPopupData(popupText.done, true, () => () => onSwitchStatus(id, switchStatus));
    } else if(switchStatus === 'NO_SHOW') {
      setPopupNoShow(true);
      setPopupData(popupText.noShow, true, () => () => onSwitchStatus(id, switchStatus));
    } else if(switchStatus === 'OFFED') {
      if(status === 'CANCELED' || status === 'ALL' || status === 'NO_SHOW') {
        setPopupOffed(true);
        setPopupData(popupText.offed, true,
          () => () => onBreak(doctorId, currentDate, timeLine));
      } else {
        setPopupVisited(true);
        setPopupData(popupText.reserved, false,() => () => {});
      }
    }

    setIsRightClicked(p => !p);
  }, [currentDate, doctorId, id, onBreak, onSwitchStatus, timeLine]);

  const closeRightClickedPopup = () => {
    setIsRightClicked(false);
  };

  useEffect(() => {
    window.addEventListener('click', closeRightClickedPopup)
  }, []);

  return (
    <>
      <InnerCellCardWrapper>
        <Popover placement="right" trigger="click" content={content}>
          <button style={{ width: '100%' }}
                  onContextMenu={e => handleRightClickCard(e)}>
            <StyledCard isNew={isNew} style={columnStyle}>
              <Status status={statusText} />
              <DataWrapper>
                <StyledUser>{userName}</StyledUser>
                <StyledTreat>{treatName}</StyledTreat>
              </DataWrapper>
            </StyledCard>
          </button>
        </Popover>
        {!isDimmed && isRightClicked && (
          <ChangeStatusPopover>
            <DisplayButton isLast={isLast}>
              {isAppointed && (status === 'APPOINTED') && (
                <ChangeStatusButton
                type="button"
                onClick={() => {
                  onSwitchStatusPopup('VISITED')
                }}
              >
                <span>완료</span>
              </ChangeStatusButton>
              )}
              {isAppointed && (status === 'APPOINTED') && (
                <ChangeStatusButton
                  type="button"
                  onClick={() => {
                    onSwitchStatusPopup('NO_SHOW')
                  }}
                >
                  <span>노쇼</span>
                </ChangeStatusButton>
              )}
              <ChangeStatusButton
                type="button"
                onClick={() => {
                  onSwitchStatusPopup('OFFED')
                }}
              >
                <span>휴진</span>
              </ChangeStatusButton>
            </DisplayButton>
          </ChangeStatusPopover>
        )}
      </InnerCellCardWrapper>
      {/* 팝업 2-1-1 */}
      {popupRez && (
        <ConfirmPopup setIsPopupVisible={setPopupRez} isPopupVisible={popupRez}
                      isWithCancel={true}
                      handleOk={popupHandleOk}>
          {popupText.done}
        </ConfirmPopup>
      )}
      {/* 팝업 2-2-1 */}
      {popupNoShow && (
        <ConfirmPopup setIsPopupVisible={setPopupNoShow} isPopupVisible={popupNoShow}
                      isWithCancel={true}
                      handleOk={popupHandleOk}>
          {popupText.noShow}
        </ConfirmPopup>
      )}
      {/* 팝업 2-3-1 */}
      {popupOffed && (
        <ConfirmPopup setIsPopupVisible={setPopupOffed} isPopupVisible={popupOffed}
                      isWithCancel={true}
                      handleOk={popupHandleOk}>
          {popupText.offed}
        </ConfirmPopup>
      )}
      {/* 팝업 2-3-2 */}
      {popupVisited && (
        <ConfirmPopup setIsPopupVisible={setPopupVisited} isPopupVisible={popupVisited}>
          {popupText.reserved}
        </ConfirmPopup>
      )}
    </>
  );
};

export default InnerCellCard;
