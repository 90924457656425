import { ApprovedReviewResponse, ReviewDetailItem, ReviewItem, ReviewSearchKeywordOption, ReviewSearchSortBy, ReviewsResponse } from '../model';
import api from './index';
import Qs from 'qs';

const Review = {
  getReviews: (
    searchType: ReviewSearchKeywordOption,
    start: string,
    end: string,
    searchKeyword: string = "",
    rating: number = 0,
    page: number,
    size: number,
    sortBy: ReviewSearchSortBy = ReviewSearchSortBy.DEFALUT
  ) => {
    return api.get<ReviewsResponse<ReviewItem>>(
      '/partners/reviews',
      {
        params: {
          searchTermsType: searchType,
          start: start,
          end: end,
          searchTerms: searchKeyword,
          rating: rating,
          page: page,
          size: size,
          sortBy: sortBy
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    )
  },
  approveDisplay: (reviewId: string) => {
    return api.patch<ApprovedReviewResponse>(`/partners/reviews/${reviewId}/state.post`);
  },
  getReviewDetail: (reviewId: string) => {
    return api.get<ReviewDetailItem>(`/partners/reviews/${reviewId}`);
  },
  unPosting(reviewId: string) {
    return api.patch<ApprovedReviewResponse>(`/partners/reviews/${reviewId}/state.un-post`);
  },
}

export default Review; 