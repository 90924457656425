import styled from "styled-components";
import { Table } from "antd";
import "antd/dist/antd.css";
import { scrollBase } from "../../../styles/mixins";
import { TableDefaultProps } from "../TableDefault";

export const StyledTable = styled(Table)<TableDefaultProps>`
  .ant-table-body {
    ${scrollBase};
  }
  td {
    font-size:14px;
  }

  /* 2-1. 오늘의 추천 관리 리스트 첫 번째, 마지막 버튼 상태 (disabled) */
  tbody tr:first-of-type .up-down-button:first-of-type {
    cursor: not-allowed;
    opacity: 0.4;
  }

  tbody tr:last-of-type .up-down-button:last-of-type {
    cursor: not-allowed;
    opacity: 0.4;
  }

  /* table pagination */
  .ant-table-pagination.ant-pagination {
    margin: 20px 0 30px;
  }
  .ant-pagination-item:hover {
    border-color: ${(props) => props.theme.accent};
    > a {
      color: ${(props) => props.theme.accent};
    }
  }

  .ant-pagination-item a {
    color: ${(props) => props.theme.primary};
  }

  .ant-pagination-item-active {
    border-color: ${(props) => props.theme.accentLight};
  }
  .ant-pagination-item-active a {
    color: ${(props) => props.theme.accentLight};
  }

  .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination-next .ant-pagination-item-link:hover {
    color: ${(props) => props.theme.accentLight};
    border-color: ${(props) => props.theme.accentLight};
  }

  /* sort 버튼색 */

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${(props) => props.theme.primary};
  }

  /* 체크박스 */

  .ant-checkbox-input:hover,
  .ant-checkbox-input:active ~ .ant-checkbox-inner,
  .ant-checkbox-input:focus ~ .ant-checkbox-inner {
    border-color: ${(props) => props.theme.accent};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox:active .ant-checkbox-inner,
  .ant-checkbox:focus .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${(props) => props.theme.accent};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${(props) => props.theme.accent};
  }

  /* 체크박스로 선택한 row 배경색 */
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: ${(props) => props.theme.submenuItem_bg};
  }
`;
