import styled from 'styled-components';
import { flexbox, fontStyle12, fontStyle22, buttonBase } from '../../styles/mixins';

export const Wrapper = styled.div`
  ${flexbox('space-between', 'center')};
  position: relative;
  margin-bottom: 50px;

  a:hover {
    color: ${props => props.theme.white};
  }
`;

export const HeadingWrapper = styled.div`
  ${flexbox('flex-start', 'flex-end')};
  /* margin-bottom: 30px; */
  /* margin: 20px 0 30px; */
`;

export const Heading = styled.h2`
  ${fontStyle22};
  font-weight: 500;
`;

export const HelperText = styled.span`
  ${fontStyle12};
  font-weight: 500;
  color: ${props => props.theme.red};
  margin: 0 0 0 5px;
`;

export const GlobalHelperText = styled.p`
  ${fontStyle12};
  font-weight: 500;
  color: ${props => props.theme.grey};
  position: absolute;
  bottom: -20px;
  left: 0;
`;

export const Button = styled.button<{ isFilled?: boolean; isMiddle?: boolean }>`
  ${buttonBase};
  width: 110px;
  height: 36px;
  margin: auto 80px 0 0;
`;
