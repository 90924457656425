import styled from "styled-components";
import { flexboxColumn, fontStyle14, fontStyle15 } from "../../../styles/mixins";

export const FlexWrapper = styled.div`
	${flexboxColumn("flex-start", "flex-start")};
	width: 100%;
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 100%;

	> span {
		${fontStyle15};
		position: absolute;
		top: 10px;
		right: 26%;
	}
`;

interface StyledInputProps {
	isRequiredEmpty?: boolean;
	isCost?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
	${fontStyle15};
	display: block;
	width: 75%;
	height: 42px;
	padding: 0 34px 0 16px;
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorder)};
	color: ${(props) => props.theme.primary};
	transition: border-color 100ms ease-in-out;
	appearance: none;
	text-align: ${(props) => (props.isCost ? "right" : "left")};

	::placeholder {
		${fontStyle15};
		color: ${(props) => props.theme.placeholder};
	}

	:hover {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderHover)};
	}

	:focus,
	:active {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderFocus)};
	}

	:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
`;

export const MaxLength = styled.div<{isPopup?: boolean}>`
    align-self: end;
    margin: ${(props) => (props.isPopup ? "2px 0 0 0" : "2px 25% 0 0")};
	color: ${(props) => props.theme.primary};
	> span {
		color: ${(props) => props.theme.grey};
	}
`;

export const HelperWrapper = styled.div`
	height: 25px;
`;

export const HelperText = styled.p`
	${fontStyle14};
	font-weight: 500;
	color: ${(props) => props.theme.red};
`;
