import React from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import { StyledSelect } from "./styles";

const { Option, OptGroup } = Select;
const SelectOptGroup = () => {
	function handleChange(value: any) {
		console.log(`selected ${value}`);
	}

	return (
		<StyledSelect defaultValue="전체 시술 카테고리" style={{ width: 200 }} onChange={handleChange}>
			<OptGroup label="성형">
				<Option value="0">눈 수술</Option>
				<Option value="1">코 수술</Option>
				<Option value="2">윤곽 수술</Option>
				<Option value="3">양악 수술</Option>
				<Option value="4">가슴 수술</Option>
				<Option value="5">지방 흡입</Option>
				<Option value="6">지방 이식</Option>
				<Option value="7">리프팅/거상</Option>
				<Option value="8">성형 기타</Option>
			</OptGroup>
			<OptGroup label="피부">
				<Option value="9">특허 페이스 리액터</Option>
				<Option value="10">스킨 케어</Option>
				<Option value="11">쁘띠</Option>
				<Option value="12">안티에이징</Option>
				<Option value="13">바디</Option>
				<Option value="14">피부 기타</Option>
			</OptGroup>
			<OptGroup label="치과">
				<Option value="15">특허 페이스 리액터</Option>
				<Option value="16">스킨 케어</Option>
				<Option value="17">쁘띠</Option>
				<Option value="18">안티에이징</Option>
				<Option value="19">바디</Option>
				<Option value="20">피부 기타</Option>
			</OptGroup>
			<OptGroup label="여성병원">
				<Option value="21">여성성형</Option>
				<Option value="22">여성질환</Option>
				<Option value="23">여성종합검진</Option>
				<Option value="24">여성병원 기타</Option>
			</OptGroup>
			<OptGroup label="한의원">
				<Option value="25">다이어트</Option>
			</OptGroup>
		</StyledSelect>
	);
};

export default React.memo(SelectOptGroup);
