import { HospitalPartnersDetail, ModifyHospitalPartnersRequest, HospitalModifiedResponse, HospitalAgentAccountResponse, ActivateResponse, DeactivateResponse } from '../model';
import api from './index';

const Hospital = {
  getHospital: (region: string) => {
    return api.get<HospitalPartnersDetail>(`/partners/hospital/${region}`);
  },
  modifyHospital: (data: ModifyHospitalPartnersRequest) => {
    return api.put<HospitalModifiedResponse>('/partners/hospital', data);
  },
  getHospitalAgent: () => {
    return api.get<HospitalAgentAccountResponse>('/partners/hospital/agent');
  },
  activateCounsel: () => {
    return api.put<ActivateResponse>('/partners/hospital/counsel/activation');
  },
  deactivateCounsel: () => {
    return api.put<DeactivateResponse>('/partners/hospital/counsel/deactivation');
  },
}

export default Hospital;
