import React from 'react';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { User } from '../helpers/SocketProvider';
import { CounselRoomItem } from '../model';

export interface SocketContextType {
  socket: WebSocketHook;
  isConnected: boolean;
  isLogin: boolean;
  user?: User;
  rooms: CounselRoomItem[];
  status: boolean;
  lastJsonMessage: string;
  modifyStatus: (status: boolean) => void;
  getCounselRooms: (page: number, size: number, name?: string) => Promise<boolean>;
  getRefreshCounselRooms: (page: number, size: number, name?: string) => Promise<boolean>;
  sendCounselMessage: (roomId: String, message: String) => void;
  streamRoomMessage: (roomId: String) => void;
}

export let SocketContext = React.createContext<SocketContextType>(null!);

export const useSocket = () => {
  return React.useContext(SocketContext);
}