import styled from "styled-components";
import { flexbox, fontStyle14, fontStyle15 } from "../../../styles/mixins";

export const FlexWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	margin: 0 0 10px 0;
`;

export const InputWrapper = styled.div`
	flex-grow: 1;
`;

export const Input = styled.input<{isRequiredEmpty?: boolean}>`
	${fontStyle15};
	display: block;
	width: 100%;
	height: 42px;
	padding: 0 34px 0 16px;
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorder)};
	color: ${(props) => props.theme.primary};
	transition: border-color 100ms ease-in-out;
	appearance: none;
	text-align: left;

	::placeholder {
		${fontStyle15};
		color: ${(props) => props.theme.placeholder};
	}

	:hover {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderHover)};
	}

	:focus,
	:active {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderFocus)};
	}

	:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
`;

export const HelperText = styled.p`
	${fontStyle14};
	font-weight: 500;
	color: ${(props) => props.theme.red};
`;
