import React from 'react';
import Image from '../Image';
import { RelativeContainer, CloseButton } from './styles';
import { CloseOutlined } from '@ant-design/icons';
import { ImageProps } from 'antd';

interface Props extends ImageProps {
  onClose?: () => void;
  isDisabled?: boolean;
}

const ImageWithButton = (props: Props) => {
  return (
    <RelativeContainer>
      <Image {...props} />
      {!props.isDisabled && (
        <CloseButton onClick={props.onClose} type="button" style={{ fontSize: '10px' }}>
          <CloseOutlined />
        </CloseButton>
      )}
    </RelativeContainer>
  );
};
export default React.memo(ImageWithButton);
