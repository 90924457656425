import React from 'react';
import { Section } from '../../../../Hospital/styles';
import { Divider, LabelText, TableDefault, TableHeading } from '../../../../../../components';
import { ColumnsType } from 'antd/lib/table';
import {
  BeforeAndAfterTreatmentItem,
  TreatmentAdvantageAndEffectItem,
  TreatmentFaqItem,
  TreatmentProcedureItem,
} from '../../../../../../model';

interface TreatDetailTranslateProps {
  firstExplain?: string;
  secondExplain?: string;
  thirdExplain?: string;
  fourthExplain?: string | undefined;
  fifthExplain?: string | undefined;
  advantageColumns?: ColumnsType<TreatmentAdvantageAndEffectItem>;
  advantages?: TreatmentAdvantageAndEffectItem[];
  procedureColumns?: ColumnsType<TreatmentProcedureItem>;
  procedures?: TreatmentProcedureItem[];
  beforeAndAfterColumns?: ColumnsType<BeforeAndAfterTreatmentItem>;
  beforeAndAfters?: BeforeAndAfterTreatmentItem[];
  faqColumns?: ColumnsType<TreatmentFaqItem>;
  faqs?: TreatmentFaqItem[];
}

const TreatDetailTranslate = (props: TreatDetailTranslateProps) => {
  const {
    firstExplain,
    secondExplain,
    thirdExplain,
    fourthExplain,
    fifthExplain,
    advantageColumns,
    advantages,
    procedureColumns,
    procedures,
    beforeAndAfterColumns,
    beforeAndAfters,
    faqColumns,
    faqs,
  } = props;
  return (
    <form>
      <Section>
        <TableHeading>시술 추천 대상 설명</TableHeading>
        <LabelText text="시술 설명1" isRequired>
          {firstExplain}
        </LabelText>
        <LabelText text="시술 설명2" isRequired>
          {secondExplain}
        </LabelText>
        <LabelText text="시술 설명3" isRequired>
          {thirdExplain}
        </LabelText>
        <LabelText text="시술 설명4 (선택)" nodata={fourthExplain === undefined || fourthExplain.length <= 0}>
          {fourthExplain}
        </LabelText>
        <LabelText text="시술 설명5 (선택)" nodata={fifthExplain === undefined || fifthExplain.length <= 0}>
          {fifthExplain}
        </LabelText>
      </Section>
      <Divider />
      <Section>
        <TableHeading hasButton="추가" isButtonDisabled>
          시술 장점 및 효과 (선택)
          <small>선택 시 최소 2개, 최대 3개까지 추가 가능합니다.</small>
        </TableHeading>
        <TableDefault columns={advantageColumns} dataSource={advantages} />
      </Section>
      <Divider />
      <Section>
        <TableHeading hasButton="추가" isButtonDisabled>
          시술 과정(선택)
          <small>선택 시 최소 3개, 최대 8개까지 추가 가능합니다.</small>
        </TableHeading>
        <TableDefault columns={procedureColumns} dataSource={procedures} />
      </Section>
      <Section>
        <TableHeading hasButton="추가" isButtonDisabled>
          시술 전후(선택)
          <small>최대 10개까지 추가 가능합니다.</small>
        </TableHeading>
        <TableDefault columns={beforeAndAfterColumns} dataSource={beforeAndAfters} />
      </Section>
      <Divider />
      <Section>
        <TableHeading hasButton="추가" isButtonDisabled isRequired>
          자주 묻는 질문
          <small>최소 2개, 최대 6개까지 추가 가능합니다.</small>
        </TableHeading>
        <TableDefault columns={faqColumns} dataSource={faqs} />
      </Section>
    </form>
  );
};

export default TreatDetailTranslate;
