import styled from "styled-components";
import { flexbox, fontStyle12 } from "../../../styles/mixins";

export const FlexWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	width: 79.5%;
	position: relative;
	margin: 0 0 25px 0;

	.ant-select {
		flex-grow: 1;
	}
`;

export const HelperText = styled.p`
	${fontStyle12};
	color: ${(props) => props.theme.grey};
	position: absolute;
	top: 25px;
	left: 0;
`;
