import styled from "styled-components";
import { flexbox, fontStyle20, buttonBase, scrollBase } from "../../../../styles/mixins";

const headerHeight = 55;
const footerHeight = 60;

export const Header = styled.header`
  ${flexbox("flex-start", "center")};
  width: 100%;
  height: ${headerHeight}px;
  padding: 0 20px;
  box-shadow: 0 2px 8px #f0f1f2;

  h1 {
    ${fontStyle20};
    font-weight: 700;
    margin: 0 15px 0 0;
  }

  button {
    ${buttonBase};
    height: 31px;
  }
`;

export const Section = styled.section`
  ${scrollBase};
  width: 100%;
  height: calc(100% - ${headerHeight}px - ${footerHeight}px);
  overflow-y: auto;
`;

export const Footer = styled.footer`
  ${flexbox("flex-start", "center")};
  min-height: ${footerHeight}px;
  width: 100%;
  padding: 18px;
`;
