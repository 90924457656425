import React, { ChangeEventHandler } from 'react';
import { StyledSelect, StyledOption } from "./styles";

interface ItemDefault {
	value: string;
	text: string;
}

interface SelectProps {
	optionList: string[];
	optionChange?: (value: any, option: any) => void;
	style?: any;
}

const Select = (props: SelectProps) => {

	const options = props.optionList?.map((option, i) => (
		<StyledOption key={i} value={option}>
			{option}
		</StyledOption>
	));

	return (
		<StyledSelect
			defaultValue={props.optionList[0]}
			style={props.style}
			onChange={props.optionChange}
			dropdownStyle={{ borderRadius: "4px" }}
		>
			{options}
		</StyledSelect>
	);
};

export default React.memo(Select);
