import { ChangeEvent, FormEvent, useEffect, useState, useCallback, ReactNode } from 'react';
import { Rate, TablePaginationConfig } from 'antd';
import SearchBox from '../../../components/SearchBox';
import TableDefault from '../../../components/Tables/TableDefault';
import Select from '../../../components/Inputs/Select';
import ApproveButton from '../../../components/ApproveButton';
import DetailButton from '../../../components/InnerTdButton/DetailButton';
import ConfirmPopup from '../../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../../components/Popup/DefaultPopup';
import ReviewTable from './ReviewTable';
import { StyledLayout, FlexWrapper, PopupSubHeading, Stars, StatusText, StatusButton } from './styles';
import { useApi } from '../../../contexts/ApiContext';
import { ReviewItem, ReviewRating, ReviewSearchKeywordOption, ReviewsResponse } from '../../../model';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { ColumnsType } from 'antd/lib/table';

const Patient = () => {
  const api = useApi();

  const searchOptionList = ['전체', '시술명', '성명'];
  const dateFormat = 'YYYYMMDD';
  const popupText = {
    approve: (
      <>
        게시 승인 하시겠습니까? <br />
        앱에 바로 반영됩니다.
      </>
    ),
    reject: (
      <>
        게시를 중단하시겠습니까? <br />
        중단 후 다시 게시할 수 없습니다.
      </>
    ),
  };

  // 검색어
  const [keyword, setKeyword] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();

  // 리뷰 상세 팝업
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [detailReviewId, setDetailReviewId] = useState<string>('');
  const onClickDetail = (review: ReviewItem) => {
    setDetailReviewId(review.reviewId);
    setIsDetailVisible(true);
  };

  // 검색 키워드
  const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  // 키워드 검색 옵션
  const [type, setType] = useState<ReviewSearchKeywordOption>(ReviewSearchKeywordOption.ALL);
  const [searchType, setSearchType] = useState<ReviewSearchKeywordOption>(ReviewSearchKeywordOption.ALL);

  // 검색 날짜 설정
  const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment().subtract(6, 'month'));
  const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
  const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>(moment().subtract(6, 'month'));
  const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>(moment());

  // 날짜 변경
  const onChangeDate = (values: RangeValue<Moment>) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  // 검색어 검색 기준
  const onChangeOption = (e: any) => {
    switch (e) {
      case '전체':
        setType(ReviewSearchKeywordOption.ALL);
        break;
      case '시술명':
        setType(ReviewSearchKeywordOption.TREATMENT);
        break;
      case '성명':
        setType(ReviewSearchKeywordOption.USER);
        break;
    }
  };

  // 리뷰 목록
  const [reviewsData, setReviewsData] = useState<ReviewsResponse<ReviewItem>>();
  const [reviews, setReviews] = useState<ReviewItem[]>([]);

  // 리뷰 별점
  const [selectedRating, setSelectedRating] = useState<ReviewRating>();
  const [reviewRatingList] = useState<ReviewRating[]>([
    {
      name: '전체 별점',
      rating: 0,
    },
    {
      name: '5점',
      rating: 5,
    },
    {
      name: '4점',
      rating: 4,
    },
    {
      name: '3점',
      rating: 3,
    },
    {
      name: '2점',
      rating: 2,
    },
    {
      name: '1점',
      rating: 1,
    },
  ]);

  // 목록 페이징
  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(25);

  // 팝업 관리
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [reasonsForReport, setReasonsForReport] = useState<string>('');

  // 게시 중단 팝업
  const [isStopVisible, setIsStopVisible] = useState(false);
  const onClickStop = (reasonText: string) => {
    setReasonsForReport(reasonText);
    setIsStopVisible(true);
  };

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk: VoidFunction,
    handleCancel: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 검색 submit
  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      setPage(0);

      setSearchType(type);
      setSearchKeyword(keyword);

      setSearchStartDate(startDate ?? moment().subtract(6, 'month'));
      setSearchEndDate(endDate ?? moment());
    },
    [endDate, keyword, startDate, type],
  );

  // 리뷰 조회
  const getReviews = useCallback(async () => {
    const data = (
      await api.review.getReviews(
        searchType,
        searchStartDate?.format(dateFormat) ?? moment().subtract(6, 'month').format(dateFormat),
        searchEndDate?.format(dateFormat) ?? moment().format(dateFormat),
        searchKeyword,
        selectedRating?.rating,
        page,
        size,
      )
    ).data;

    setReviewsData(data);
  }, [api.review, searchType, searchStartDate, searchEndDate, searchKeyword, selectedRating?.rating, page, size]);

  // 리뷰 별점 변경
  const onChangeRating = useCallback(
    (value: string) => {
      const reviewRating = reviewRatingList.find(row => row.name === value);

      setSelectedRating(reviewRating);
    },
    [reviewRatingList],
  );

  // 게시 승인 호출
  const approveDisplay = useCallback(
    async (reviewId: string) => {
      const data = (await api.review.approveDisplay(reviewId)).data;

      if (data.approved) {
        getReviews().then().catch();
      }
    },
    [api.review, getReviews],
  );

  // 게시 승인
  const onClickApprove = useCallback((item: ReviewItem) => {
    const handleOk = () => async () => approveDisplay(item.reviewId);
    const handleCancel = () => async () => setIsConfirmVisible(false);

    setPopupData(popupText.approve, true, handleOk, handleCancel);
    setIsConfirmVisible(true);
  }, [approveDisplay, popupText.approve]);

  const unPosting = useCallback(async (reviewId: string) => {
    (await api.review.unPosting(reviewId));

    getReviews().then().catch();
  }, [api.review, getReviews]);

  const onClickStopPositing = useCallback(async (item: ReviewItem) => {
    const handleOk = () => async () => unPosting(item.reviewId);
    const handleCancel = () => async () => setIsConfirmVisible(false);

    setPopupData(popupText.reject, true, handleOk, handleCancel);
    setIsConfirmVisible(true);
  }, [popupText.reject, unPosting]);

  // 리뷰 목록 컬럼
  const columns: ColumnsType<ReviewItem> = [
    {
      title: '번호',
      dataIndex: 'number',
      key: 'number',
      render: (value: any, record: ReviewItem, index: number) => {
        return size * page + (index + 1);
      },
    },
    {
      title: '작성일',
      dataIndex: 'wroteAt',
      key: 'wroteAt',
      render: (value: any, review: ReviewItem) => {
        return moment(review?.wroteAt).format('YYYY.MM.DD');
      },
      sorter: (a: ReviewItem, b: ReviewItem) => {
        const aDate = moment(a.wroteAt, 'YYYY.MM.DD');
        const bDate = moment(b.wroteAt, 'YYYY.MM.DD');
        return bDate.diff(aDate);
      },
    },
    {
      title: '시술명',
      dataIndex: 'treatmentName',
      key: 'treatmentName',
    },
    {
      title: '별점',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a: ReviewItem, b: ReviewItem) => {
        return b.rating - a.rating;
      },
      render: (value: any, review: ReviewItem) => {
        return (
          <Stars>
            <Rate disabled defaultValue={review.rating} />
            <span>{review.rating}</span>
          </Stars>
        );
      },
    },
    {
      title: '내용',
      dataIndex: 'contents',
      key: 'contents',
      ellipsis: true,
    },
    {
      title: '성명',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '',
      dataIndex: 'reviewId',
      key: 'reviewId',
      render: (value: any, review) => <DetailButton onClick={() => onClickDetail(review)} />,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (value: any, review: ReviewItem) => {
        if (!review.approved) {
          if(review.domestic) {
            return '-';
          } else {
            return (
              <StatusButton type="button" style={{width: '100%'}} onClick={() => onClickStop('계시 중단')}>
                게시 중단
              </StatusButton>
            );
          }
        } else if (review.reported) {
          return (
            <StatusButton type="button" style={{width: '100%'}} onClick={() => onClickStop(review.reasonText)}>
              게시 중단
            </StatusButton>
          );
        } else {
          return <StatusText>게시</StatusText>;
        }
      },
    },
    {
      title: '관리',
      dataIndex: 'approved',
      key: 'approved',
      render: (value: any, review) => {
        if (review.reported ) {
          return <ApproveButton approved={false} isGlobal={true} disabled={true}/>
        } else {
          if(review.domestic) {
            if (!review.approved) {
              return <ApproveButton approved={false} isGlobal={false} onClick={() => onClickApprove(review)} />
            } else if (review.approved) {
              return <ApproveButton approved={true} isGlobal={false} disabled={true} />
            }
          } else {
            if (review.approved) {
              return <ApproveButton approved={true} isGlobal={true} onClick={() => onClickStopPositing(review)} />
            } else if (!review.approved) {
              return <ApproveButton approved={true} isGlobal={true} disabled={true} />
            }
          }
        }
      },
    },
  ];

  // 시술 목록 페이지네이션
  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    total: reviewsData?.totalElements,
    current: page + 1,
    onChange: (page: number) => {
      setPage(page - 1);
    },
  };

  // 리뷰 목록 호출
  useEffect(() => {
    getReviews().then().catch();
  }, [getReviews]);

  // 리뷰 목록 데이터 가공
  useEffect(() => {
    const data = reviewsData?.reviews?.map(row => {
      return { key: row.reviewId, ...row };
    });

    if (data) {
      setReviews(data);
    }
  }, [reviewsData]);

  return (
    <>
      <StyledLayout>
        <SearchBox
          optionList={searchOptionList}
          titleUnder="기간"
          searchValue={keyword}
          onChangeSearch={onChangeKeyword}
          onChangeOption={onChangeOption}
          startDate={startDate}
          endDate={endDate}
          onChangeDate={onChangeDate}
          onSubmit={onSubmit}
          allowClear={false}
        />

        <FlexWrapper>
          <span>
            <strong>환자 리뷰</strong> | 총 <strong>{reviewsData?.totalElements}</strong>명
          </span>
          <Select onChange={onChangeRating} optionList={reviewRatingList.map(row => row.name)} />
        </FlexWrapper>
        <TableDefault columns={columns} dataSource={reviews} pagination={pagination} />
      </StyledLayout>
      {isDetailVisible && (
        <DefaultPopup
          title="상세보기"
          isPopupVisible={isDetailVisible}
          setIsPopupVisible={setIsDetailVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          isWide
          isWithOkay
        >
          <PopupSubHeading>리뷰 상세</PopupSubHeading>
          <ReviewTable reviewId={detailReviewId} />
        </DefaultPopup>
      )}

      {isConfirmVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmVisible}
          setIsPopupVisible={setIsConfirmVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}

      {/* 3-17 팝업 (22.08.10) */}
      {isStopVisible && (
        <ConfirmPopup isPopupVisible={isStopVisible} setIsPopupVisible={setIsStopVisible}>
          사용자의 신고로 게시 중단된 리뷰입니다. <br />
          <small>신고 사유 : {reasonsForReport}</small>
        </ConfirmPopup>
      )}
    </>
  );
};

export default Patient;
