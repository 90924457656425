import api from './index';
import { DoctorInventoryForPartners, DoctorDetailForPartners, RequestDoctorCreateByPartners, DoctorCreatedResponseForPartners, RequestDoctorModifyByPartners, DoctorModifiedResponseForPartners, DoctorDeletedResponse, ShiftDirection, DisplayOrderChangedResponseForPartners } from '../model';

const Doctor = {
  getDoctors: () => {
    return api.get<DoctorInventoryForPartners>(`/partners/doctors`);
  },
  getDoctor: (doctorId: string, region: string) => {
    return api.get<DoctorDetailForPartners>(`/partners/doctors/${doctorId}/${region}`);
  },
  addDoctor: (payload: RequestDoctorCreateByPartners) => {
    return api.post<DoctorCreatedResponseForPartners>(`/partners/doctor`, payload);
  },
  modifyDoctor: (doctorId: string, payload: RequestDoctorModifyByPartners) => {
    return api.put<DoctorModifiedResponseForPartners>(`/partners/doctors/${doctorId}`, payload);
  },
  deleteDoctor: (doctorId: string) => {
    return api.delete<DoctorDeletedResponse>(`/partners/doctors/${doctorId}`);
  },
  swapDoctors: (doctorId: string, direction: ShiftDirection) => {
    return api.put<DisplayOrderChangedResponseForPartners>(`/partners/doctors/${doctorId}/display-order`, {direction:direction});
  }
}

export default Doctor;
