import { Member } from '../../../../model';
import { Table } from './styles';

interface DataProps {
  data?: Member;
}

const UserDetailTable = (memberData: DataProps) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>
            <strong>성명(영문명)</strong>
          </th>
          <td>
            {memberData.data?.username}
            {memberData.data?.usernameEn ? '(' + memberData.data.usernameEn + ')' : ''}
          </td>
          <th>국적</th>
          <td>{memberData.data?.locale}</td>
        </tr>
        <tr>
          <th>가입일</th>
          <td>{memberData.data?.createdAt}</td>
          <th>탈퇴일</th>
          <td>{memberData.data?.resignedAt}</td>
        </tr>
        <tr>
          <th>생년월일</th>
          <td>{memberData.data?.birthday}</td>
          <th>성별</th>
          <td>{memberData.data?.gender}</td>
        </tr>
        <tr>
          <th>이메일</th>
          <td>{memberData.data?.email}</td>
          <th>전화번호</th>
          <td>
            ({memberData.data?.countryNumber}) {memberData.data?.contact}
          </td>
        </tr>
        <tr>
          <th>회원구분</th>
          <td>{memberData.data?.memberType}</td>
          <th>소셜루트</th>
          <td>{memberData.data?.signUpFunnel}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default UserDetailTable;
