import React from 'react';
import { useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import SelectChat from "./SelectChat";
import { Empty } from "antd";
import { SearchOutlined, MessageFilled } from "@ant-design/icons";
import { Wrapper, MenuSection, Header, Status, Search, EmptyList, ChatSection } from "./styles";

const optionList = ["전체 메시지", "안 읽은 메시지"];

const RocketChat = () => {
	const [hasChatList, setHasChatList] = useState(false);

	const [status, setStatus] = useState(true);
	const onToggleStatus = (checked: boolean) => {
		if (checked) {
			setStatus(true);
		} else {
			setStatus(false);
		}
	};

	return (
		<Wrapper>
			<MenuSection>
				<Header>
					<h1>메디컬 오 한의원 압구정 본점</h1>
					<Status>
						{status ? <p>상담 가능</p> : <p>상담 준비중</p>}
						<ToggleButton defaultChecked onChange={onToggleStatus} />
					</Status>
				</Header>

				<SelectChat optionList={optionList} />

				<Search>
					<SearchOutlined style={{ fontSize: "18px" }} />
					<input type="text" placeholder="성명을 입력해주세요." />
				</Search>

				{hasChatList ? (
					<ul> chat list </ul>
				) : (
					<EmptyList>
						<Empty description={false} />
						<p>결과 0 건</p>
					</EmptyList>
				)}
			</MenuSection>

			<ChatSection>
				<MessageFilled style={{ fontSize: "60px" }} />

				<p>대화 상대를 선택하세요.</p>
			</ChatSection>
		</Wrapper>
	);
};
export default React.memo(RocketChat);
