import { Calendar, Layout } from 'antd';
import styled from 'styled-components';
import { fontStyle14, fontStyle15, fontStyle16 } from '../../../styles/mixins';

export const CalendarWrapper = styled.div`
  #container {
    margin: 24px;
  }

  .ant-picker-calendar-header {
  }

  .ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
    display: none;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${props => props.theme.accent};
  }
  .ant-select:not(.ant-select-disabled):active .ant-select-selector {
    border-color: ${props => props.theme.accent};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${props => props.theme.accent};
    box-shadow: 0 0 0 2px rgba(208, 165, 108, 0.4);
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: ${props => props.theme.accentDark};
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: ${props => props.theme.accentDark};
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
    background-color: rgba(208, 165, 108, 0.4);
  }
`;

export const StyledLayout = styled.div`
  display: flex;
  height: 80vh;
  gap: 12px;
  margin: 12px 20px;
`;

export const CalendarSection = styled.section`
  width: 80vw;
  height: 100%;
`;

export const ReservationHistoryWrapper = styled.section`
  width: 500px;
  height: 100%;
  overflow: hidden;
  padding: 12px;
`;

export const ReservationDataWrapper = styled.div`
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
`;

export const ReservationDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Total = styled.div`
  > strong {
    font-weight: 900;
  }
`;

export const CardSection = styled.section<{ view?: string }>`
  height: ${props => (props.view === '일별보기' ? '24vh' : '74%')};
  overflow: scroll;
`;

export const SubCalendar = styled(Calendar)`
  .ant-picker-calendar-header {
    display: none;
  }
`;

export const RefreshButton = styled.button`
  ${fontStyle15}
  position: absolute;
  top: 140px;
  right: 100px;
  color: ${props => props.theme.inputBorder};
`;
