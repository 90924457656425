import api from './index';

import { LoginRequest, LoginResponse, LogoutReqest } from '../model';

const Authentication = {
  partnersLogin: (data: LoginRequest) => {
    return api.post<LoginResponse>('/partners/login', data, {headers: undefined});
  },
  logout: (data: LogoutReqest) => {
    return api.post<null>('/logout', data);
  },
  refreshToken: (refreshToken: string) => {
    return api.post<LoginResponse>(
      '/token.refresh',
      null,
      {
        headers: {
        'Authorization': `Bearer ${refreshToken}`
        },
      },
    );
  },
};

export default Authentication;