import React from 'react';
import { FlexWrapper, InputWrapper, StyledInput, HelperWrapper, MaxLength, HelperText } from './styles';
import { InputProps } from 'antd';
import { ChangeEventHandler } from 'react';

interface StyledInputProps extends InputProps {
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isRequiredEmpty?: boolean;
  maxLength?: number;
  isCost?: boolean;
  isPopup?: boolean;
}

const Input = ({ placeholder, value, onChange, isRequiredEmpty, maxLength, isCost, isPopup }: StyledInputProps) => {
  return (
    <FlexWrapper>
      <InputWrapper>
        <StyledInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          isRequiredEmpty={isRequiredEmpty}
          isCost={isCost}
        />
        {isCost && <span>원 ~ </span>}
      </InputWrapper>
      {maxLength && (
        <MaxLength isPopup={isPopup}>
          {value?.toString().length} / <span>{maxLength}자 </span>
        </MaxLength>
      )}
      <HelperWrapper>{isRequiredEmpty && <HelperText>필수 입력 값입니다.</HelperText>}</HelperWrapper>
    </FlexWrapper>
  );
};

export default React.memo(Input);
