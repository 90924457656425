import { DataResponse, ItemsResponse, TreatmentItemForPartners, DeleteResponse, CreateTreatmentRequest, CreateTreatmentReponse, ModifyTreatmentRequest, ModifyResponse, TreatmentDetails, ShiftDirection } from '../model';
import api from './index';
import Qs from 'qs';

const Treatment = {
    getTreatments: () => {
      return api.get<ItemsResponse<TreatmentItemForPartners>>(
        '/partners/me/treatments',
        {
          params: {},
          paramsSerializer: (params) => Qs.stringify(params),
        }
      )
    },
    deleteTreatment: (treatmentId: string) => {
        return api.delete<DeleteResponse>(`/partners/me/treatments/${treatmentId}`);
    },
    addTreatment: (data: CreateTreatmentRequest) => {
      return api.post<CreateTreatmentReponse>('/partners/me/treatments', data);
    },
    getTreatment: (treatmentId: string, region: string) => {
      return api.get<DataResponse<TreatmentDetails>>(`/partners/me/treatments/${treatmentId}/${region}`);
    },
    modifyTreatment: (treatmentId: string, data: ModifyTreatmentRequest) => {
      return api.put<ModifyResponse>(`/partners/me/treatments/${treatmentId}`, data);
    },
    swapTreatment: (treatmentId: string, direction: ShiftDirection) => {
      return api.put<ModifyResponse>(`/partners/me/treatments/${treatmentId}/display-order`, {direction: direction});
    },
    changeNormallyTreatment: (treatmentId: string) => {
      return api.put<ModifyResponse>(`/partners/me/treatments/${treatmentId}/normal`);
    },
    changeRepresentativeTreatment: (treatmentId: string) => {
      return api.put<ModifyResponse>(`/partners/me/treatments/${treatmentId}/representative`);
    },
}

export default Treatment;