import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

export const StyledLayout = styled(Layout)`
  margin: 64px 0 0 260px; // for fixed gnb and lnb
  padding: 0 30px 24px;

  @media screen and (max-width: 1535px) {
    margin: 64px 0 0 150px;
  }
`;

export const StyledContent = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;

export const StyledTabs = styled(Tabs)`
  color: ${props => props.theme.primary};
  margin: 20px 0 0 0;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover {
    color: ${props => props.theme.accent};
    font-weight: 500;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 35px 0;
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.theme.accent};
  }

  .ant-tabs-tab:hover {
    color: ${props => props.theme.accent};
  }
`;
