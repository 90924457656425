export interface LoginUser {
  userId: string;
  username: string;
}

export interface LoginRequest {
  loginName: string;
  loginPassword: string;
}

export interface LoginResponse {
  success: boolean;
  accessToken: string;
  refreshToken: string;
}

export interface ActivateResponse {
  activated: boolean;
}

export interface DeactivateResponse {
  deactivated: boolean;
}

export interface LogoutReqest {
  refreshToken: string;
}

export interface IssuedSignedUrl {
  signedUrl: string;
  uploadTargetUrl: string;
}

export interface HospitalPartnersDetail {
  hospitalId: string;
  hospitalName: string;
  departments: string[];
  treatments: string[];
  treatmentCategories: string[];
  tel: string;
  address: string;
  addressMapUrl: string;
  introductionSummary: string;
  introductionDescription: string;
  representativeImageUrl: string;
  monTreatmentPeriod: TreatmentPeriodItem;
  tueTreatmentPeriod: TreatmentPeriodItem;
  wedTreatmentPeriod: TreatmentPeriodItem;
  thuTreatmentPeriod: TreatmentPeriodItem;
  friTreatmentPeriod: TreatmentPeriodItem;
  satTreatmentPeriod: TreatmentPeriodItem;
  sunDayOffTreatmentPeriod: TreatmentPeriodItem;
  lunchTreatmentPeriod: TreatmentPeriodItem;
  significant: string;
}

export interface TreatmentPeriodItem {
  treatment: boolean;
  dayOfWeek?: string;
  opensAt: string;
  closesAt: string;
}

export interface ModifyHospitalPartnersRequest {
  tel: string;
  address: string;
  addressMapUrl: string;
  introductionSummary: string;
  introductionDescription: string;
  representativeImageUrl: string;
  treatmentMon: boolean;
  monOpensAt: string;
  monClosesAt: string;
  treatmentTue: boolean;
  tueOpensAt: string;
  tueClosesAt: string;
  treatmentWed: boolean;
  wedOpensAt: string;
  wedClosesAt: string;
  treatmentThu: boolean;
  thuOpensAt: string;
  thuClosesAt: string;
  treatmentFri: boolean;
  friOpensAt: string;
  friClosesAt: string;
  treatmentSat: boolean;
  satOpensAt: string;
  satClosesAt: string;
  treatmentSunAndOff: boolean;
  sunAndOffOpensAt: string;
  sunAndOffClosesAt: string;
  treatmentLunch: boolean;
  lunchStartsAt: string;
  lunchEndsAt: string;
  significant?: string;
}

export interface HospitalModifiedResponse {
  modified: boolean;
}

export interface DoctorInventoryForPartners {
  totalElements: number;
  doctors: DoctorItemForPartners[];
}

export interface DoctorItemForPartners {
  doctorId: string;
  displayOrder: number;
  doctorName: string;
  position: string;
  treatments: string[];
}

export interface DoctorDetailForPartners {
  doctorId: string;
  hospitalId: string;
  hospitalName: string;
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introductionImageUrls: IntroImageForPartners[];
  treatments: TreatmentForPartners[];
}

export interface DetailItemImage {
  key?: any;
  imageUrl: string;
  displayOrder: number;
  file?: File;
}

export interface IntroImageForPartners {
  imageUrl: string;
  displayOrder: number;
}

export interface TreatmentForPartners {
  key?: any;
  treatmentId: string;
  treatmentName: string;
  displayOrder: number;
}

export interface HospitalTreatmentsResponseItem {
  key?: any;
  treatmentId: string;
  treatmentName: string;
  departmentName: string;
  departmentEnabled: boolean;
  treatmentCategoryName: string;
  treatmentCategoryEnabled: boolean;
}

export interface RequestDoctorCreateByPartners {
  hospitalName: string;
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introductionImages: IntroductionImageByPartners[];
  treatments: DoctorTreatmentByPartners[];
}

export interface DoctorCreatedResponseForPartners {
  created: boolean;
}

export interface IntroductionImageByPartners {
  imageUrl: string;
  displayOrder: number;
}

export interface DoctorTreatmentByPartners {
  treatmentId: string;
  treatmentName: string;
  displayOrder: number;
}

export interface RequestDoctorModifyByPartners {
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introImages: IntroductionImageByPartners[];
  treatments: DoctorTreatmentByPartners[];
}

export interface DoctorModifiedResponseForPartners {
  modified: boolean;
}

export interface ItemsResponse<T> {
  data: T[];
}

export interface TreatmentItemForPartners {
  key?: any;
  treatmentId: string;
  number: number;
  displayOrder: number;
  representative: string;
  name: string;
  treatmentName: string;
  departmentId: string;
  departmentName: string;
  treatmentCategoryId: string;
  treatmentCategoryName: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface DoctorDeletedResponse {
  deleted: boolean;
  message: string;
}

export interface DisplayOrderChangedResponseForPartners {
  modified: boolean;
  message: string;
}

export interface DeleteResponse {
  deleted: boolean;
  message?: string;
}

export interface TreatmentPartnersDetails {
  id: string;
  hospitalId: string;
  enabled: boolean;
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: TreatmentRepresentative,
  price: number;
  priceOption: string;
  introduction: string;
  imageUrl: string;
  textImageUrl: string;
  description: string[];
  treatmentAdvantagesAndEffects: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export enum TreatmentRepresentative {
  NORMAL = "NORMAL",
  REPRESENTATIVE = "REPRESENTATIVE",
}

export interface TreatmentAdvantageAndEffectItem {
  imageUrl: string;
  content: string;
  file?: File;
  key?: any;
}

export interface TreatmentFaqItem {
  title: string;
  content: string;
  key?: any;
}

export interface TreatmentProcedureItem {
  imageUrl: string;
  title: string;
  file?: File;
  key?: any;
}

export interface BeforeAndAfterTreatmentItem {
  beforeImageUrl: string;
  afterImageUrl: string;
  title: string;
  beforeFile?: File;
  afterFile?: File;
  key?: any;
}

export interface DepartmentItem {
  id: string;
  name: string;
  nameEn: string;
  nameZh: string;
  enabled: boolean;
  displayOrder: number;
}

export interface TreatmentCategoryItem {
  categoryId: string;
  departmentId: string;
  categoryName: string;
  categoryNameEn: string;
  categoryNameZh: string;
  enabled: boolean;
  displayOrder: number;
}

export interface DataResponse<T> {
  status?: boolean;
  success?: boolean
  message?: string,
  data: T;
}

export interface DepartmentWithCategoryList {
  departmentId: string;
  departmentName: string;
  categories: CategoryItem[];
}

export interface CategoryItem {
  categoryId: string;
  categoryName: string;
}

export interface GetAllDepartmentsResponse {
  success: boolean;
  message: string;
  result: DepartmentItem[];
}

export enum ShiftDirection {
  FORWARD = 'Forward',
  BACKWARD = 'Backward',
}

export interface CreateTreatmentRequest {
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: string;
  price: number;
  priceOption?: string;
  introduction?: string;
  imageUrl?: string;
  textImageUrl?: string;
  description: string[];
  treatmentAdvantagesAndEffects?: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures?: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export interface CreateResponse {
  created: boolean;
  message?: string;
}

export interface CreateTreatmentReponse extends CreateResponse {
  treatmentId: string;
}

export interface ModifyTreatmentRequest {
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: string;
  price: number;
  priceOption?: string;
  introduction?: string;
  imageUrl?: string;
  textImageUrl?: string;
  description: string[];
  treatmentAdvantagesAndEffects?: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures?: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export interface ModifyResponse {
  modified: boolean;
  message?: string;
}

export interface TreatmentDetails {
  id: string;
  hospitalId: string;
  enabled: boolean;
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: TreatmentRepresentative,
  price: number;
  priceOption: string;
  introduction: string;
  imageUrl: string;
  textImageUrl: string;
  description: string[];
  treatmentAdvantagesAndEffects: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export enum SearchPeriod {
  TODAY,
  WEEK,
  MONTH,
  MONTHS,
}

export enum ReviewSearchKeywordOption {
  ALL = "",
  TREATMENT = "TREATMENT",
  USER = "USER",
}

export enum ReviewSearchSortBy {
  DEFALUT = "",
  WROTE_DATE = "WROTE_DATE",
  RATING = "RATING",
}

export interface ReviewsResponse<T> {
  totalElements: number;
  reviews: T[];
  message: string;
}

export interface ReviewItem {
  reviewId: string;
  wroteAt: string;
  treatmentName: string;
  rating: number;
  contents: string;
  userName: string;
  userNameEn: string;
  domestic: boolean;
  approved: boolean;
  reported: boolean;
  reasonText: string;
}

export interface ReviewRating {
  name: string;
  rating: number;
}

export interface ApprovedReviewResponse {
  approved: boolean;
  message: string;
}

export interface ReviewDetailItem {
  reviewId: string;
  wroteAt: string;
  treatmentName: string;
  rating: number;
  userName: string;
  userNameEn: string;
  domestic: boolean;
  userEmail: string;
  contents: string;
  imageUrls: string[];
  receiptImageUrl: string;
}

export interface HospitalAgentAccountResponse {
  agentId: string;
  agentPassword: string;
}

export interface CounselRoomItem {
  roomId: string;
  memberName: string;
  lastMessage: string;
  messageType: string;
  lastMessageDateTime: string;
  isUnread: boolean;
}

export interface CounselRoomInventoryForPartners {
  rooms: CounselRoomItem[];
  message: string;
}

export interface SentMessageFile {
  sent: boolean;
  message: string;
}

export interface DefaultResponse {
  success?: boolean
  message?: string,
}

export enum MessageType {
  STARTED = "STARTED",
  TEXT = "TEXT",
  RECOVERY_RECORD = "RECOVERY_RECORD",
  FILE = "FILE",
  APPOINTMENT="APPOINTMENT"
}

export enum SenderType {
  USER = "USER",
  HOSPITAL = "HOSPITAL",
}

export interface MessageItem {
  sender: string;
  senderType: SenderType;
  type: MessageType | string;
  messageId: string,
  message: string;
  sendDateTime: string;
}

export interface MessageHistory {
  memberId: string;
  memberName: string;
  messages: MessageItem [];
  errorMessage: string;
}

export interface RecoveryRecord {
  recoveryId: string;
  elapsedDays: number;
  symptoms: string[];
  symptomDetails: string;
  images: {imageUrl: string}[];
  hospitalName: string;
  doctorName: string;
  doctorPosition: string;
  treatmentName: string;
  treatmentDate: string;
}

export interface Appointment {
  id: string
  appointmentStatus: string,
  hospitalName: string,
  doctorName: string,
  doctorPosition: string,
  treatmentName: string,
  appointmentDate: string,
  appointmentTime: string
  canceledDate: string,
  canceledTime: string
}

export interface Member {
  memberId: string;
  username: string;
  usernameEn: string;
  locale: string;
  createdAt: string;
  resignedAt: string;
  birthday: string;
  gender: string;
  email: string;
  countryNumber: string;
  contact: string;
  memberType: string;
  signUpFunnel: string;
}

export interface PushMessage {
  title: string;
  body: string;
  additionalData: PushAddData;
}

export interface PushAddData{
  roomId: string;
  hospitalName: string;
}

export interface ListResponse<T> {
  totalElements: number;
  message: string;
  items: T[]
}

export interface DetailsResponse<T> {
  read: boolean;
  message: string;
  payload: T
}
export interface AppointmentListItem {
  appointmentId: string,
  paymentId: string,
  appointmentStatus: string,
  paymentNumber: string,
  appointeeId: string,
  appointeeName: string,
  appointeeNameEn: string,
  upcomingDateTime: string,
  executionDate: string,
  doctorName: string,
  treatmentName: string
}

export interface AppointmentDetailResponse {
  appointmentId: string;
  details: AppointmentDetail
}

export interface AppointmentDetail {
  status: string;
  upcomingDateTime: string;
  appointeeName: string;
  appointeeNameEn: string;
  nationality: string;
  contact: string;
  birth: string;
  doctorName: string;
  treatmentName: string;
  note: string;
}

export enum AppointmentSearchOption {
  ALL = "ALL",
  PAYMENT_NO = "PAYMENT_NO",
  APPOINTEE_NAME = "APPOINTEE_NAME",
  DOCTOR_NAME = "DOCTOR_NAME",
  TREATMENT_NAME = "TREATMENT_NAME",
}

export enum AppointmentHistoryFilter {
  ALL = "ALL",
  APPOINTED = "APPOINTED",
  VISITED = "VISITED",
  CANCELED = "CANCELED",
  NO_SHOW = "NO_SHOW"
}

export interface PaymentDetails {
  paymentId: string,
  status: string,
  paymentNumber: string,
  executionDateTime: string,
  paymentMethod: string,
  amount: number
}

export interface PaymentListItem {
  paymentId: string,
  paymentNumber: string,
  executionDate: string,
  upcomingDate: string,
  doctor: string,
  treatment: string
}

export interface AppointmentSetting {
  hospitalId: string,
  usedService: boolean,
  appointmentIntervalTime: string,
  sameDayAvailabilityTime: string
}

export interface AppointmentDoctorItem {
  doctorId: string,
  name: string,
  position: string,
  appointmentAvailability: string
}

export interface DailySchedule {
  dayOfWeek: string,
  type: string,
  morningStartAt: string,
  morningEndAt: string,
  afternoonStartAt: string,
  afternoonEndAt: string
}

export interface WeeklySchedule {
  monday: DailySchedule,
  tuesday: DailySchedule,
  wednesday: DailySchedule,
  thursday: DailySchedule,
  friday: DailySchedule,
  saturday: DailySchedule,
  sunday: DailySchedule,
}

export interface DoctorSchedule {
  doctorId: string,
  weeklySchedule: WeeklySchedule
}

export interface AppointmentStatus {
  dailyStatus: AppointmentDailyStatus;
}

export interface StatusItem {
  appointed: number;
  canceled: number;
  closed: number;
  noShow: number;
  total: number;
  unprocessed: number;
  visited: number;
}

export interface AppointmentDailyStatus {
  [key: string]: StatusItem
}

export interface DailyAppointmentPayloadStatus {
  totalElements: number;
  appointed: number;
  visited: number;
  canceled: number;
  noShow: number;
}

export interface DailyAppointmentPayloadHistories {
  appointeeId: string;
  appointmentId: string;
  paymentId: string;
  status: string;
  appointeeName: string;
  appointeeNameEn: string;
  doctorName: string;
  treatmentName: string;
  upcomingDate: string;
  upcomingTime: string;
  newer: boolean
}

export interface DailyAppointmentPayload {
  status: DailyAppointmentPayloadStatus;
  histories: DailyAppointmentPayloadHistories[];
}

export interface AppointmentTimeTableDoctor {
  id: string;
  name: string;
  usedAppointment: boolean;
}

export interface TimeTableAppointmentItem {
  appointmentId: string;
  appointee: string;
  appointeeEn: string;
  contact: string;
  treatment: string;
  newer: boolean,
  status: string;
  appointedAt: string;
  upcomingAt: string;
  canceledAt: string;
  doTreatment: boolean;
}

export interface TimeTableAppointmentItems {
  [key: string]: TimeTableAppointmentItem
}

export interface TimeTableAppointment {
  [key: string]: TimeTableAppointmentItems;
}

export interface AppointmentTimeTableResponse {
  doctors: AppointmentTimeTableDoctor[],
  timeSchedules: string[],
  appointments: TimeTableAppointment;
}

export interface TranslatedResponse {
  "translated": boolean
  "message": string,
  "payload": TranslatedPayload
}

export interface TranslatedPayload {
  "translatedText": string
}