import styled from "styled-components";
import { Layout } from "antd";

export const StyledLayout = styled(Layout)`
	margin: 64px 0 0 260px;

	@media screen and (max-width: 1535px) {
		margin: 64px 0 0 150px;
	}
`;
