import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Carousel } from 'antd';
import { UserOutlined, ReloadOutlined, CloseOutlined } from '@ant-design/icons';
import {
  List,
  Item,
  User,
  Body,
  Time,
  ActionsWrapper,
  Attach,
  RecoveryCard,
  Elapse,
  Details,
  Tags,
  Info,
  CarouselWrapper,
  StyledDetail,
  NextBtn,
  PrevBtn,
  ChatDivider,
  TransButton,
} from './styles';
import moment from 'moment';
import { Appointment, MessageItem, MessageType, RecoveryRecord, SenderType } from '../../../../../model';
import DefaultPopup from '../../../../../components/Popup/DefaultPopup';
import Recovery from '../../../../Recovery';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { useApi } from '../../../../../contexts/ApiContext';
import {
  StatusCard,
  Divider,
  StatusSection,
  Texts,
  InfoSection,
  Infos,
  AppointmentDetails,
  StatusWrapper,
  StatusItem,
  StatusDivider,
} from './AppointmentCard/styles';
import { statusList, displayedStatusList } from './AppointmentCard/appointmentCard';

// system : "~님이 대화에 참여했습니다", "~님이 나갔습니다."와 같은 안내 메시지를 띄우는 경우, Item에 'system'이라는 className을 넣어주세요.
// own : 병원 관리자가 입력하는 메시지인 경우, Item에 'own'이라는 className을 추가해주세요.
// newDay : 메시지 전송 날짜가 변한 경우, Item에 'new-day'라는 className을 추가해주세요.
// username : 환자 이름 (병원 관리자에는 비어있는 문자열을 넣었습니다.)
// body : 메시지 내용 (병원 관리자, 환자 공통)
// attachBody : 이미지 파일을 첨부하면서 입력한 메시지 내용 (병원 관리자, 환자 공통)
// imgSrc: 이미지 주소
// time : 메시지 전송 시간
// failed : 메시지 전송에 실패한 경우, Item에 'failed'라는 className을 추가해주세요.

interface ListProps {
  list?: MessageItem[];
  toBottom: boolean;
}

interface ItemProps extends MessageItem {
  system: boolean;
  newDay: boolean;
  failed: boolean;
  getRecoveryRecord: any;
}

const Message = (props: ItemProps) => {
  const api = useApi();
  const carouselRef = useRef<CarouselRef>(null);

  const time = moment(props.sendDateTime, 'YYYY-MM-DD HH:mm:ss');

  const dataDate = time?.format('YYYY MMMM Do dddd');
  const timestamp = time?.format('HH:mm');
  const datetime = time?.format('LLLL');

  const [translatedText, setTranslatedText] = useState<string>();
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isAttach, setIsAttach] = useState<boolean>(false);
  const [isTranslated, setIsTranslated] = useState<boolean>(false);

  useEffect(() => {
    switch (props.type) {
      case MessageType.STARTED:
      case MessageType.TEXT:
      case MessageType.RECOVERY_RECORD:
        setIsAttach(false);
        break;
      case MessageType.APPOINTMENT:
        setIsAttach(false);
        break;
      default: {
        setIsAttach(true);
        if (props.type.startsWith('image')) {
          setIsImage(true);
        } else {
          setIsImage(false);
        }
      }
    }
  }, [props.type]);

  const onTranslate = useCallback(
    async (item: MessageItem) => {
      setIsTranslated(p => !p);
      const data = (await api.translation.transalate(item.messageId, item.message)).data;
      setTranslatedText(data.payload.translatedText);
    },
    [api.translation],
  );

  // 상위 컴포넌트로 회복 기록 전달
  const onClickDetail = useCallback(
    (recoveryRecord: RecoveryRecord) => {
      props.getRecoveryRecord(recoveryRecord);
    },
    [props],
  );

  const onClickPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const onClickNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <Item
      data-date={dataDate}
      className={
        (props.system ? 'system' : '') +
        ' ' +
        (props.senderType === SenderType.HOSPITAL ? 'own' : '') +
        ' ' +
        (props.newDay ? 'new-day' : '') +
        ' ' +
        (isAttach ? 'has-attachment' : '') +
        ' ' +
        (props.failed ? 'failed' : '')
      }
    >
      {props.system ? (
        <div>
          <UserOutlined style={{ fontSize: '16px' }} />
          <span>{props.sender}</span>
          <p>{props.message}</p>
          <Time className="time" datetime={datetime}>
            {timestamp}
          </Time>
        </div>
      ) : (
        // 일반 메세지인 경우
        (() => {
          switch (props.type) {
            // 회복 기록
            case MessageType.RECOVERY_RECORD: {
              const recoveryRecord: RecoveryRecord = JSON.parse(props.message);
              return (
                <ul>
                  <Item className="recovery">
                    <div>
                      <div className="message-body">
                        <div>
                          <Body className="body recovery">
                            <RecoveryCard>
                              <Elapse>
                                <strong>{recoveryRecord.elapsedDays}</strong>일 경과
                              </Elapse>
                              {recoveryRecord.images?.length > 0 ? (
                                <CarouselWrapper>
                                  <PrevBtn type="button" onClick={onClickPrev}>
                                    <LeftOutlined />
                                  </PrevBtn>
                                  <Carousel ref={carouselRef}>
                                    {recoveryRecord.images.map((img, i) => (
                                      <div style={{ height: '150px', textAlign: 'center', background: '#fff' }} key={i}>
                                        <img src={img.imageUrl} alt="recovery" />
                                      </div>
                                    ))}
                                  </Carousel>
                                  <NextBtn type="button" onClick={onClickNext}>
                                    <RightOutlined />
                                  </NextBtn>
                                </CarouselWrapper>
                              ) : (
                                ''
                              )}
                              <Details>{recoveryRecord.symptomDetails}</Details>
                              <Tags>
                                {recoveryRecord.symptoms.map((symptom, i) => (
                                  <span key={i}>{symptom}</span>
                                ))}
                              </Tags>
                              <Info>
                                <p>
                                  {recoveryRecord.hospitalName} - {recoveryRecord.doctorName}{' '}
                                  {recoveryRecord.doctorPosition}
                                </p>
                                <p>
                                  <strong>{recoveryRecord.treatmentName}</strong>
                                </p>
                                <p>방문일 : {recoveryRecord.treatmentDate}</p>
                              </Info>
                              <StyledDetail onClick={() => onClickDetail(recoveryRecord)}>상세보기</StyledDetail>
                            </RecoveryCard>
                          </Body>
                        </div>
                      </div>
                    </div>
                  </Item>
                </ul>
              );
            }
            case MessageType.APPOINTMENT: {
              const appointment: Appointment = JSON.parse(props.message);
              const cancellationCheck =
                appointment.appointmentStatus === 'CANCELED' || appointment.appointmentStatus === 'NO_SHOW';
              const cancelledTime = cancellationCheck ? moment(appointment.canceledTime, "HH:mm:ss").format('HH:mm') : '';

              return (
                <ul>
                  <Item className="appointment">
                    <div>
                      <div className="message-body">
                        <div>
                          <Body className="body">
                            <StatusCard>
                              <StatusSection>
                                <StatusWrapper>
                                  {statusList.map(
                                    statusItem =>
                                      appointment.appointmentStatus === statusItem.status && (
                                        <>
                                          {displayedStatusList[statusItem.status].map((displayItem, idx) => (
                                            <>
                                              <StatusItem isActive={idx === statusItem.activeIdx}>
                                                <div>
                                                  <img src={displayItem.icon} alt="icon" />
                                                </div>
                                                <span>{displayItem.name}</span>
                                              </StatusItem>
                                              {idx <= 2 && <StatusDivider />}
                                            </>
                                          ))}
                                        </>
                                      ),
                                  )}
                                </StatusWrapper>
                                <Texts>
                                  {statusList.map(
                                    statusItem =>
                                      appointment.appointmentStatus === statusItem.status && (
                                        <>
                                          <h2>{statusItem.title}</h2>
                                          <span>{statusItem.subTitle}</span>
                                          <p>{statusItem.desc}</p>
                                        </>
                                      ),
                                  )}
                                </Texts>
                              </StatusSection>
                              <Divider />
                              <InfoSection>
                                <Infos>
                                  <h3>{appointment.hospitalName}</h3>
                                  <span>
                                    <strong>{appointment.doctorName}</strong> {appointment.doctorPosition}
                                  </span>
                                </Infos>
                                <AppointmentDetails>
                                  <h4>{appointment.treatmentName}</h4>
                                  {cancellationCheck && appointment.canceledDate ? (
                                    <>
                                      <div>
                                        Appointment date : <span>{appointment.appointmentDate} {appointment.appointmentTime}</span>
                                      </div>
                                      <div>
                                        Cancellation date : <span>{appointment.canceledDate} {cancelledTime.toString()}</span>
                                      </div>
                                    </>
                                  ) : (
                                    <div>
                                      Date of visit: <span>{appointment.appointmentDate} {appointment.appointmentTime}</span>
                                    </div>
                                  )}
                                </AppointmentDetails>
                              </InfoSection>
                            </StatusCard>
                          </Body>
                        </div>
                      </div>
                    </div>
                  </Item>
                </ul>
              );
            }
            // 단순 텍스트 메세지 혹은 첨부파일
            default: {
              return (
                <div>
                  <div className="message-body">
                    <div>
                      {props.senderType === 'USER' ? <User>{props.sender}</User> : ''}
                      {!isAttach ? (
                        <Body className="body">
                          <p>{props.message}</p>
                          {isTranslated && (
                            <>
                              <ChatDivider />
                              <p>{translatedText}</p>
                            </>
                          )}
                        </Body>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* 사진을 첨부하는 경우 */}
                    <Attach className="rcx-message-attachment">
                      {isImage ? (
                        <div className="rcx-attachment__content">
                          <picture>
                            <img alt="attach" className="gallery-item" src={props.message} />
                          </picture>
                        </div>
                      ) : (
                        ''
                      )}
                    </Attach>
                    <Time className="time" datetime={datetime}>
                      {timestamp}
                    </Time>
                    {!isImage && props.senderType === 'USER' && (
                      <TransButton onClick={() => onTranslate(props)}>
                        {isTranslated ? <p>원문보기</p> : <p>번역보기</p>}
                      </TransButton>
                    )}
                  </div>

                  {/* 메시지 전송에 실패한 경우 */}
                  <ActionsWrapper className="message-actions">
                    <button type="button" className="message-actions__button" title="재전송">
                      <ReloadOutlined style={{ fontSize: '12px' }} />
                    </button>
                    <button type="button" className="message-actions__button" title="삭제">
                      <CloseOutlined style={{ fontSize: '12px' }} />
                    </button>
                  </ActionsWrapper>
                </div>
              );
            }
          }
        })()
      )}
    </Item>
  );
};

const MessageList = (props: ListProps) => {
  const loader = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<null | HTMLUListElement>(null);

  // 회복 기록 상세보기 Popup
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [recoveryData, setRecoveryData] = useState<RecoveryRecord>();
  const onClickDetail = (recoveryData: RecoveryRecord) => {
    setRecoveryData(recoveryData);
    setIsDetailVisible(true);
  };

  const handleObserver = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      if (entries[0]?.isIntersecting && props.list?.[0]) {
        if (props.toBottom) {
          scrollToBottom();
        } else {
          const elementCount = entries[0].target.childElementCount;
          if (elementCount > 25) {
            entries[0].target.children[22].scrollIntoView();
          }
        }
      }
    },
    [props.list, props.toBottom],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ block: 'end' });
    }
  };

  return (
    <List ref={scrollRef} onChange={scrollToBottom}>
      <div ref={loader} key="observer">
        {props.list?.map((obj, i) => (
          <Message
            getRecoveryRecord={(e: any) => onClickDetail(e)}
            key={i}
            {...obj}
            system={false}
            failed={false}
            newDay={false}
          />
        ))}
      </div>
      {isDetailVisible && (
        <DefaultPopup
          title="상세보기"
          isPopupVisible={isDetailVisible}
          setIsPopupVisible={setIsDetailVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          isWide
          isWithOkay
        >
          <Recovery recoveryData={recoveryData!} />
        </DefaultPopup>
      )}
    </List>
  );
};

export default React.memo(MessageList);
