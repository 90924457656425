import { useCallback, useEffect, useState } from 'react';
import { TableDefault } from '../../../../components';
import { SubHeading, TotalNum } from '../styles';
import { TablePaginationConfig } from 'antd';
import { ListResponse, PaymentListItem } from '../../../../model';
import { useApi } from '../../../../contexts/ApiContext';

interface UserRezHistoryTableProps {
  appointeeId?: string
}

const UserRezHistoryTable = ({appointeeId}: UserRezHistoryTableProps) => {
  const api = useApi();

  const [histories, setHistories] = useState<ListResponse<PaymentListItem>>();
  const [historyItems, setHistoryItems] = useState<PaymentListItem[]>();
  // 페이징
  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(10);

  const loadMemberPaymentHistories = useCallback(async (appointeeId: string | undefined) => {
    const histories = (await api.appointment.paymentHistory(appointeeId, page, size)).data;

    setHistories(histories)
  }, [api.appointment, page, size]);

  const userHistoryColumns: any[] = [
    {
      title: '결제/취소번호',
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
    },
    {
      title: '방문일',
      dataIndex: 'upcomingDate',
      key: 'upcomingDate',
    },
    {
      title: '결제/취소일',
      dataIndex: 'executionDate',
      key: 'executionDate',
    },
    {
      title: '의사/시술명',
      dataIndex: 'docTreatName',
      key: 'docTreatName',
      render: (value: string, paymentItem: PaymentListItem) => {
        return paymentItem.doctor + '/' + paymentItem.treatment
      }
    },
  ];

  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    total: histories?.totalElements,
    current: page + 1,
    onChange: (page: number) => {
      setPage(page - 1);
    },
  };

  useEffect( () => {
    loadMemberPaymentHistories(appointeeId)
      .then().catch()
  }, [appointeeId, loadMemberPaymentHistories]);

  useEffect(() => {
    const itemsContainKey = histories?.items.map(row => {
      return { key: row.paymentId, ...row };
    });
    if(itemsContainKey) {
      setHistoryItems(itemsContainKey);
    }
  }, [histories?.items]);

  return (
    <>
      <SubHeading>
        예약 내역
        <TotalNum>
          총<strong>{histories?.totalElements}</strong>건
        </TotalNum>
      </SubHeading>
      <TableDefault columns={userHistoryColumns} dataSource={historyItems} pagination={pagination} />
    </>
  );
};

export default UserRezHistoryTable;
