import React from 'react';
import { StyledLabel } from "./styles";

interface LabelProps {
	text: string;
	isRequired?: boolean;
	isPopup?: boolean;
}

const Label = ({ text, isRequired, isPopup }: LabelProps) => {
	return (
		<StyledLabel isPopup={isPopup}>
			<label>{text}</label>
			{isRequired && <span>*</span>}
		</StyledLabel>
	);
};

export default React.memo(Label);
