import React, { ReactNode } from 'react';
import Label from '../Label';
import { FlexWrapper } from './styles';

interface LabelTextProps {
  text: string;
  isRequired?: boolean;
  nodata?: any;
  isPopup?: boolean;
  children: ReactNode;
}

const LabelText = ({ text, isRequired, nodata, isPopup, children }: LabelTextProps) => {
  return (
    <FlexWrapper nodata={nodata}>
      <Label text={text} isRequired={isRequired} isPopup={isPopup} />
      <p style={{ width: '750px', wordBreak: 'keep-all' }}>{children}</p>
    </FlexWrapper>
  );
};

export default React.memo(LabelText);
