import React, { FormEvent, Dispatch, MouseEventHandler, SetStateAction, useState, useCallback } from 'react';
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { Header, Section, Footer } from "./styles";
import { MessageHistory, MessageItem, PushMessage } from '../../../../model';
import moment, { Moment } from "moment";
import { useApi } from '../../../../contexts/ApiContext';
import { useSocket } from '../../../../contexts/SocketContext';

interface ChatAreaProps {
	history?: MessageHistory
	messages?: MessageItem[]
	roomId: string
	hospitalName: string
	toBottom: boolean
	setToBottom: Dispatch<SetStateAction<boolean>>
	onClickDetail?: MouseEventHandler
	setStartDate: Dispatch<SetStateAction<Moment | undefined>>
}

const ChatArea = (props: ChatAreaProps) => {
	const api = useApi();
	const socket = useSocket();

	const [textMessage, setTextMessage] = useState<string>("");		

	const onChange = (message: any) => {
		setTextMessage(message.target.value)
	}

	// 'Ctrl + Enter'로 메시지 전송
	const handleOnKeyUp = (event: any) => {
		if(event.ctrlKey && event.key === "Enter") {
			sendTextMessage()
		}
	}

	// message 전송 button
	const onSubmit = (event: FormEvent) => {
		event.preventDefault()

		sendTextMessage()
	};

	// message 전송
	const sendTextMessage = () => {
		if(textMessage !== "") {
			socket.sendCounselMessage(props.roomId, textMessage)
			const memberId = props.history?.memberId

			sendPushMessage(memberId!, textMessage).then().catch()
			setTextMessage("")
			props.setToBottom(true)
		}
	}

	// image file 전송
	const handleFileSend = (event: any) => {
		event.preventDefault()

		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("agentId", localStorage.getItem("rocket.chat-user-id")!)
		formData.append("agentToken", localStorage.getItem("rocket.chat-auth-token")!)
		formData.append("file", file)

		fileSend(props.roomId, formData).then().catch()
		const memberId = props.history?.memberId
		if(memberId) {
			const message = "파일명 : " + file.name
			sendPushMessage(memberId, message).then().catch()
		}
	}

	// file api 전송
	const fileSend = useCallback(async (roomId: String, formData: FormData) => {
		await api.counsel.addCounselFile(roomId, formData)
	}, [api.counsel])

	// send push message
	const sendPushMessage = useCallback(async (memberId: string, sendMessage: string) => {
		const additionalData = {
			"hospitalName": props.hospitalName,
			"roomId": props.roomId
		}

		const pushMessage: PushMessage = {
			title: props.hospitalName,
			body: sendMessage,
			additionalData: additionalData
		}

		await api.counsel.sendPushMessageForCounsel(memberId, pushMessage)
	}, [api.counsel, props.hospitalName, props.roomId])

	const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
		const scrollTop = event.currentTarget.scrollTop;
		
		if (scrollTop === 0 && props.messages?.length! >= 25) {
			props.setToBottom(false)
			const date = moment(props.messages?.[0]?.sendDateTime, "YYYY-MM-DD HH:mm:ss.SSS");
			props.setStartDate(date);
		}
	};

	return (
		<>
			<Header>
				<h1>{props.history?.memberName}</h1>
				<button type="button" onClick={props.onClickDetail}>
					상세정보
				</button>
			</Header>
			<Section onScroll={scrollHandler}>
				<MessageList list={props.messages} toBottom={props.toBottom}/>
			</Section>
			<Footer>
				<MessageInput hospitalName={props.hospitalName} 
							  textMessage={textMessage} handleOnKeyUp={handleOnKeyUp}
							  onChange={onChange} onSubmit={onSubmit} fileSend={handleFileSend}/>
			</Footer>
		</>
	);
};

export default React.memo(ChatArea);
