import { ColumnsType } from 'antd/lib/table';
import { DetailItemImage, TreatmentForPartners } from '../../../../../model';
import {
  AddImageButton,
  ContentHeading,
  Divider,
  Label,
  LabelText,
  LabelToggle,
  TableHeading,
  TableDefault,
} from '../../../../../components';
import { ImageWrapper, Section } from '../styles';

interface DoctorEditTranslateProps {
  display?: boolean;
  hospitalName?: string;
  name?: string;
  position?: string;
  intro?: string;
  representative?: string;
  doctorColumns?: ColumnsType<DetailItemImage>;
  treatColumns?: ColumnsType<TreatmentForPartners>;
  doctor?: DetailItemImage[];
  treat?: TreatmentForPartners[];
}

const DoctorEditTranslate = (props: DoctorEditTranslateProps) => {
  const { display, hospitalName, name, position, intro, representative, doctor, doctorColumns, treat, treatColumns } =
    props;

  return (
    <>
      <Section>
        <ContentHeading isRequired isHelperText>
          의료진 기본 정보
        </ContentHeading>
        <LabelToggle text="노출" checked={display} />
        <LabelText text="병원명">{hospitalName}</LabelText>
        <LabelText text="의사 성명" isRequired>
          {name}
        </LabelText>
        <LabelText text="직위" isRequired>
          {position}
        </LabelText>
        <LabelText text="한줄 소개" isRequired>
          {intro}
        </LabelText>
        <ImageWrapper>
          <Label text="대표 사진" isRequired />
          <AddImageButton url={representative} isDisabled />
        </ImageWrapper>
      </Section>
      <Divider />
      <Section>
        <ContentHeading>의료진 소개</ContentHeading>
        <TableHeading hasButton="추가" isButtonDisabled>
          소개 사진(선택)
        </TableHeading>
        <TableDefault dataSource={doctor} columns={doctorColumns} />
      </Section>
      <Section>
        <TableHeading hasButton="추가" isButtonDisabled>
          진료 시술(선택)
        </TableHeading>
        <TableDefault dataSource={treat} columns={treatColumns} />
      </Section>
    </>
  );
};

export default DoctorEditTranslate;
