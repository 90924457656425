import { css } from "styled-components";

// ------------------------------------------------------------------------------ typography
const fontBase = css`
  color: ${(props) => props.theme.primary};
  letter-spacing: -0.01em;
  line-height: 1.5em;
`;

export const fontStyle12 = css`
  ${fontBase};
  font-size: 12px;
`;

export const fontStyle13 = css`
  ${fontBase};
  font-size: 13px;
`;

export const fontStyle14 = css`
  ${fontBase};
  font-size: 14px;
`;

export const fontStyle15 = css`
  ${fontBase};
  font-size: 15px;
`;

export const fontStyle16 = css`
  ${fontBase};
  font-size: 16px;
`;

export const fontStyle18 = css`
  ${fontBase};
  font-size: 18px;
`;

export const fontStyle20 = css`
  ${fontBase};
  font-size: 20px;
`;

export const fontStyle22 = css`
  ${fontBase};
  font-size: 22px;
`;

export const fontStyle24 = css`
  ${fontBase};
  font-size: 24px;
`;

export const fontStyle30 = css`
  ${fontBase};
  font-size: 30px;
`;

// ------------------------------------------------------------------------------ flexbox

export const flexbox = (jc, ai) => css`
  display: flex;
  justify-content: ${jc};
  align-items: ${ai};
`;

export const flexboxColumn = (jc, ai) => css`
  ${flexbox(jc, ai)};
  flex-direction: column;
`;

// ------------------------------------------------------------------------------ position

export const positionCenterX = (type = "absolute") => css`
  position: ${type};
  left: 50%;
  transform: translateX(-50%);
`;

export const positionCenterY = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  transform: translateY(-50%);
`;

export const positionCenter = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// ------------------------------------------------------------------------------ table

export const tableBase = css`
  width: 100%;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid #f0f0f0;
  margin: 0 0 30px 0;

  th {
    position: relative;
    font-weight: 500;
    background: #f9f9f9;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease;
    padding: 8px 10px;
    overflow-wrap: break-word;
  }
  /* 
	th:not(:last-child):not([colspan])::before {
		position: absolute;
		top: 50%;
		right: 0;
		width: 1px;
		height: 1.6em;
		background-color: rgba(0, 0, 0, 0.06);
		transform: translateY(-50%);
		transition: background-color 0.3s;
		content: "";
	} */

  td {
    font-size: 14px;
    line-height: 1.5em;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s;
    position: relative;
    padding: 10px 20px;
    overflow-wrap: break-word;
    text-align: left !important;
    vertical-align: center !important;
  }
`;

// ------------------------------------------------------------------------------

export const buttonBase = css`
	${flexbox("center", "center")};
	padding: 0 15px;
	border-radius: 4px;
	text-align: center;
	
	font-size : ${(props) => (props.isBig ? "18px" : props.isMiddle ? "16px" : "14px")};
	line-height:1.5em;

	background-color: ${(props) => (props.isFilled ? props.theme.accentLight : props.theme.white)};
	border: 1px solid ${(props) => props.theme.accentLight};
	color: ${(props) => (props.isFilled ? props.theme.white : props.theme.accent)};

	transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

	:nth-of-type(2){
		margin:0 0 0 10px;
	}

	@media screen and (max-width:820px){
		:nth-of-type(2){
			margin:10px 0 0 0px;
		}
	}

	&:hover {
		border: 1px solid ${(props) => (props.isFilled ? props.theme.accent : props.theme.accentLight)};
		background-color: ${(props) => (props.isFilled ? props.theme.accent : props.theme.menuItem_bg)};
		color: ${(props) => (props.isFilled ? props.theme.white : props.theme.accent)};
	}

	&:active {
		border: 1px solid ${(props) => (props.isFilled ? props.theme.accentDark : props.theme.accentLight)};
		background-color: ${(props) => (props.isFilled ? props.theme.accentDark : props.theme.submenuItem_bg)};
		color: ${(props) => (props.isFilled ? props.theme.white : props.theme.accent)};
	}

	&:focus {
    border: 1px solid ${(props) => (props.isFilled ? props.theme.accentLight : props.theme.accent)}
		background-color: ${(props) => props.theme.accentLight};
		color: ${(props) => (props.isFilled ? props.theme.white : props.theme.accent)};
	}

	&:disabled{
		cursor:not-allowed;
		opacity:0.4;
	}

`;
// ------------------------------------------------------------------------------ etc.

export const scrollBase = css`
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    height: 10%;
    border-radius: 8px;
    background-color: #dfdfdf;
    transition: background-color 300ms ease-in-out;
    :hover {
      background-color: #dadada;
    }
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
  }
`;

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const visuallyHidden = css`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;
