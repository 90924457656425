import React from 'react';
import Label from "../Label";
import Input from "../Input";
import { FlexWrapper, HelperText } from "./styles";
import { ChangeEventHandler, ReactNode } from "react";
import { InputProps } from "antd";

interface LabelInputProps extends InputProps {
	text: string;
	placeholder?: string;
	isRequired?: boolean;
	value?: string | ReadonlyArray<string> | number | undefined;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	isRequiredEmpty?: boolean;
	isCost?: boolean;
	isPopup?: boolean;
	maxLength?: number;
	hasHelperText?: boolean;
	children?: ReactNode;
}

const LabelInput = (props: LabelInputProps) => {
	return (
		<>
			<FlexWrapper isPopup={props.isPopup}>
				<Label text={props.text} isRequired={props.isRequired} isPopup={props.isPopup} />
				{props.hasHelperText && <HelperText>{props.children}</HelperText>}
				<Input
					type="text"
					placeholder={props.placeholder}
					value={props.value}
					onChange={props.onChange}
					maxLength={props.maxLength}
					isCost={props.isCost}
					isRequiredEmpty={props.isRequiredEmpty}
					isPopup={props.isPopup} />
			</FlexWrapper>
		</>
	);
};

export default React.memo(LabelInput);
