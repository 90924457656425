import styled from 'styled-components';
import dayjs from 'dayjs';
import { flexbox, flexboxColumn, fontStyle24 } from '../../../../styles/mixins';

export const CalendarContainer = styled.div`
  ${flexboxColumn('flex-start', 'flex-start')}
  width: 100%;
  height: auto;

  td.ant-table-cell {
    padding: 0;

    &:first-child {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  ${flexboxColumn}
  width: 100%;
`;

// 요일
export const Weekdays = styled.div`
  ${flexbox('space-between', 'flex-start')}
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid gainsboro;

  > div {
    width: calc(100% / 7);
    text-align: center;
    padding: 20px 10px 15px;
  }
`;

export const Week = styled.div`
  display: flex;
  width: 100%;
`;

export const Days = styled.div`
  ${flexbox('space-between', 'flex-start')}
  flex-wrap: wrap;
  width: 100%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 5px 0;
`;

export const Div = styled.div`
  width: 150px;
  height: 40px;
  ${flexbox('center', 'center')}
  background-color: ${props => props.theme.menuItem_bg};
  color: ${props => props.theme.chatGrey};
`;

export const TimeDiv = styled(Div)`
  width: 100px;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.inputBorder};

  &:first-child {
    border-bottom: none;
  }
`;
