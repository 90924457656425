import { Radio, TimePicker } from 'antd';
import styled from 'styled-components';
import { flexbox, flexboxColumn, fontStyle16 } from '../../../../styles/mixins';

export const Container = styled.div`
  width: 100%;
  ${flexboxColumn('flex-start', 'flex-start')};
  gap: 8px;
  margin-bottom: 30px;
  padding: 0 10px;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  ${flexbox('space-between', 'center')}
`;

export const RadioWrapper = styled.div`
  ${flexbox}
  color: ${props => props.theme.primary};
`;

export const StyledRadio = styled(Radio)`
  ${fontStyle16};
  color: ${props => props.theme.primary};

  .ant-radio {
    color: ${props => props.theme.primary};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.theme.accent};
  }

  .ant-radio-input:active + .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: unset;
  }

  &.ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${props => props.theme.accent};
  }

  .ant-radio-inner::after {
    background-color: ${props => props.theme.accent};
  }
`;

export const StyledPicker = styled(TimePicker.RangePicker)`
  height: 42px;

  &.ant-picker {
    color: ${props => props.theme.primary};
  }

  &.ant-picker:hover {
    border-color: ${props => props.theme.inputBorderHover};
  }

  &.ant-picker-focused {
    box-shadow: unset;
    border-color: ${props => props.theme.inputBorderFocus};
  }

  &.ant-picker-range .ant-picker-active-bar {
    background-color: transparent;
  }

  .ant-picker-input > input {
    color: ${props => props.theme.primary};
    text-align: center;
  }

  &.ant-picker.ant-picker-disabled .ant-picker-input > input {
    color: ${props => props.theme.grey};
  }
`;

export const StyledPickerWrapper = styled.div`
  ${flexbox('center', 'center')}
  gap: 20px;

  > div {
    ${flexboxColumn}
    gap: 5px;
  }

  span {
    margin-right: 5px;
  }
`;
