import React from 'react';

import { MouseEventHandler } from 'react';
import EditLink from './EditLink';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

import { ButtonWrapper } from './styles';

/* 
0. 테이블 cell 안에 들어가는 '편집' 및 '삭제' 버튼입니다.

1. '편집' 버튼과 '삭제' 버튼 둘 다 있는 경우도 있고, '삭제' 버튼만 있는 경우가 있습니다.
		- 'isWithEdit' props를 사용하여 조건부 렌러링이 되도록 했습니다.

2. '편집'의 경우 팝업창을 보여주는 버튼(EditButton)과 편집 페이지로 이동하는 링크(EditLink) 두 가지가 있습니다.
		- 'isEditLink' props를 사용하여 조건부 렌더링이 되도록 했습니다. 
		- 'EditLink'의 경우에는 'link' props에 해당 link를 넣어 주시면 됩니다.
*/

interface InnerTdButtonProps {
  isWithEdit?: boolean;
  isEditLink?: boolean;
  isDisabled?: boolean;
  link?: string;
  onClickEdit?: MouseEventHandler;
  onClickDelete?: MouseEventHandler;
}

const InnerTdButton = ({
  isWithEdit,
  isEditLink,
  isDisabled,
  link,
  onClickEdit,
  onClickDelete,
}: InnerTdButtonProps) => {
  return (
    <ButtonWrapper>
      {isWithEdit &&
        (isEditLink ? <EditLink link={link} /> : <EditButton onClick={onClickEdit} isDisabled={isDisabled} />)}
      <DeleteButton onClick={onClickDelete} isDisabled={isDisabled} />
    </ButtonWrapper>
  );
};

export default React.memo(InnerTdButton);
