import React, { Key } from "react";
import { StyledTable } from "../TableDefault/styles";
import { TableProps } from "antd";

interface TableSortProps extends TableProps<any> {
	data: any;
}

const TableSort = (props: TableSortProps) => {
	return <StyledTable
		rowSelection={props.rowSelection}
		columns={props.columns}
		dataSource={props.data}
		scroll={props.scroll}
		pagination={props.pagination ? props.pagination : false}
		locale={{
			triggerDesc: "내림차순",
			triggerAsc: "오름차순",
			cancelSort: "정렬 취소",
		}}
	/>;
};

export default React.memo(TableSort);
