import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { CounselRoomItem } from '../../../../model';
import { List, Item, FlexWrapper, Name, Timestamp, Content, Unread } from "./styles";

interface Props {
	rooms: CounselRoomItem[];
	selected?: CounselRoomItem;
	setPage: Dispatch<SetStateAction<number>>;
	onClick?: (item: CounselRoomItem) => void;
}

interface ItemProps {
	item: CounselRoomItem;
	selected?: CounselRoomItem;
	onClick?: (item: CounselRoomItem) => void;
}

const ListItem = (props: ItemProps) => {
	return (
		<Item key={props.item.roomId} onClick={() => props.onClick?.(props.item)} className={props.selected === props.item ? "selected" : ""}>
			<div>
				<FlexWrapper>
					<Name>
						<strong>{props.item.memberName}</strong>
					</Name>
					<Timestamp>{props.item.lastMessageDateTime}</Timestamp>
				</FlexWrapper>
				<FlexWrapper>
					<Content>{props.item.lastMessage}</Content>
					{props.item.isUnread ? <Unread>N</Unread> : ""}
				</FlexWrapper>
			</div>
		</Item>
	);
};

const ChatList = (props: Props) => {
	const loader = useRef(null);

	const handleObserver = useCallback(async (entries: IntersectionObserverEntry[]) => {
		if (entries[0]?.isIntersecting) {			
			props.setPage((prev) => ++prev);
		}
	}, [props]);

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: "20px",
			threshold: 0,
		}

		const observer = new IntersectionObserver(handleObserver, option);

		if (loader.current) {
			observer.observe(loader.current);
		}
	}, [handleObserver])

	return (
		<List>
			{props.rooms.map((value, index) => <ListItem item={value} key={index} onClick={props.onClick} selected={props.selected} />)}
			<div ref={loader} key="observer"></div>
		</List>
	);
};

export default React.memo(ChatList);
