import styled from "styled-components";
import { Image } from "antd";
import { flexbox } from "../../styles/mixins";

export const Wrapper = styled.div`
	position: relative;

	button {
		${flexbox("center", "center")};
		position: absolute;
		right: 8px;
		bottom: 8px;
		width: 24px;
		height: 24px;
		border-radius: 2px;
		background-color: #dedede;
		transition: background-color 200ms ease-in-out;

		.anticon {
			color: ${(props) => props.theme.white};
			transition: color 200ms ease-in-out;
		}

		&:hover {
			background-color: #d7d7d7;
		}

		&:active {
			background-color: #d3d3d3;
		}
	}
`;

export const StyledImage = styled(Image)`
	border-radius: 13px;
	width: 130px;
	height: 145px;
`;
