import { DataWrapper, Status, StyledCard } from './styles';

interface CardProps {
  isNew?: boolean;
  status: string;
  children?: any;
  userName?: string;
  doctorName?: string;
  treatName?: string;
  visitDate?: string;
  onClickDetail?: any;
}
const Card = ({ isNew, status, children, userName, doctorName, treatName, visitDate, onClickDetail }: CardProps) => {
  return (
    <StyledCard isNew={isNew} onClick={onClickDetail}>
      <Status status={status} />
      <DataWrapper>{children}</DataWrapper>
    </StyledCard>
  );
};

export default Card;
