import { DataResponse, DepartmentWithCategoryList, GetAllDepartmentsResponse, TreatmentCategoryItem } from '../model';
import api from './index';

const Category = {
  getDepartments: () => {
    return api.get<GetAllDepartmentsResponse>('/departments');
  },
  getAllCategories: () => {
    return api.get<DataResponse<DepartmentWithCategoryList[]>>('/departments/all/categories');
  },
  getCategories: (departmentId: string) => {
    return api.get<DataResponse<TreatmentCategoryItem[]>>(`/departments/${departmentId}/categories`);
  },
};
/// 시술 카테고리, 진료과 다국어 지원 api 수정.

export default Category;