import { Card } from 'antd';
import styled, { css } from 'styled-components';
import { flexbox, flexboxColumn } from '../../styles/mixins';

type TagStatus = string | '예약' | '취소' | '완료' | '노쇼' | '미처리';

const statusCollections: any = {
  예약: '#f5f5f5',
  취소: '#FFC5C5',
  완료: '#A7FF7D',
  노쇼: '#D2F0FF',
  미처리: 'rgba(84, 69, 255, 0.3)',
};

const whatStatus = (status: TagStatus) => {
  return statusCollections[status];
};

export const StyledCard = styled(Card)<{ isNew?: boolean }>`
  ${flexbox};
  position: relative;
  margin-bottom: 5px;
  padding: 0 12px;
  border-radius: 4px;
  cursor: pointer;

  ${props =>
    props.isNew &&
    css`
      &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid black;
        border-top-left-radius: 2px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    `}
`;

// 상태값 받아서 색상 변경
export const Status = styled.div<{ status: TagStatus }>`
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ status }) => whatStatus(status)};
  opacity: 0.5;
`;

export const DataWrapper = styled.div`
  ${flexboxColumn};
  width: 100%;

  p {
    ${flexbox}
    width: 100%;

    span:first-child {
      width: 40px;
      margin-right: 10px;
      font-weight: 900;
    }
  }
`;
