import styled from 'styled-components';
import { flexbox, flexboxColumn } from '../../styles/mixins';

export const StyledButton = styled.button<{ isDown?: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.accentLight};
  color: ${props => props.theme.white};
  border-radius: 8px;

  transform: ${props => (props.isDown ? 'rotate(180deg)' : 'rotate(0)')};

  :nth-of-type(2) {
    margin: 0 0 0 7px;
  }

  .anticon.anticon-up {
    ${flexbox('center', 'center')};
  }

  &:hover {
    background-color: ${props => props.theme.accent};
  }

  &:active {
    background-color: ${props => props.theme.accentDark};
  }

  &:disabled {
    background-color: ${props => props.theme.disabled_bg};
    cursor: not-allowed;
  }

  @media screen and (max-width: 820px) {
    ${flexboxColumn('center', 'center')};

    :nth-of-type(2) {
      margin: 7px 0 0 0;
    }
  }
`;
