import { Select } from '../../../../components';
import { SubHeading, Table } from '../styles';
import {
  AppointmentDetail,
  AppointmentListItem,
} from '../../../../model';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useApi } from '../../../../contexts/ApiContext';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import { ContentWrapper, TransButton } from '../../../Review/Patient/ReviewTable/styles';

interface UserRezInfoTableProps {
  item?: AppointmentListItem;
  refresh: any;
}
const UserRezInfoTable = ({item, refresh}: UserRezInfoTableProps) => {
  const api = useApi();
  const optionList = [
    { value: "APPOINTED", text: '예약' },
    { value: "VISITED", text: '완료' },
    { value: "NO_SHOW", text: '노쇼' }];

  const popupText = {
    visited: (
      <>
        예약을 완료하시겠습니까? <br />
        예약 완료시 환자에게 알림이 전송됩니다.
        * 상태 변경 시 상태를 되돌릴 수 없습니다.
      </>
    ),
    noShow: (
      <>
        노쇼로 변경하시겠습니까? <br />
        예약 상태 변경 시 환자에게 알림이 전송됩니다. <br />
        * 상태 변경 시 상태를 되돌릴 수 없습니다.
      </>
    )
  };

  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isTranslated, setIsTranslated] = useState<boolean>(false);
  const [translatedText, setTranslatedText] = useState<string>();

  const setPopupData = (contents: JSX.Element, isWithCancel: boolean,
                        handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  const [appointmentId, setAppointmentId] = useState<string>();
  const [details, setDetails] = useState<AppointmentDetail>();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [disabledChange, setDisabledChange] = useState<boolean>(false);

  const loadAppointmentDetail = useCallback(async () => {
    const appointment = (await api.appointment.details(item?.appointmentId)).data;

    setAppointmentId(appointment.payload.appointmentId);
    setDetails(appointment.payload.details)
  }, [api.appointment, item?.appointmentId]);

  const changeStatus = useCallback(async (status: string) => {
    await api.appointment.changeStatus(appointmentId, status);

    loadAppointmentDetail().then().catch();
    refresh()
  }, [api.appointment, appointmentId, loadAppointmentDetail, refresh]);

  const confirmChangeStatus = useCallback( (status: any) => {
    let alertText = popupText.visited;
    if(status === "NO_SHOW") {
      alertText = popupText.noShow;
    }
    setPopupData(alertText, true, () => () => changeStatus(status));
    setIsPopupVisible(true)
  }, [changeStatus, popupText.noShow, popupText.visited]);

  const onTranslate = useCallback(async (text: string | undefined) => {
    setIsTranslated(p => !p)
    const textKey = appointmentId ? appointmentId : ''
    const data = (await api.translation.transalate(textKey , text ? text : '')).data
    setTranslatedText(data.payload.translatedText)
  }, [api.translation, appointmentId]);

  useEffect(() => {
    loadAppointmentDetail()
      .then().catch();
  }, [loadAppointmentDetail]);

  useEffect(() => {
    if(details?.status !== 'APPOINTED' && details?.status !== undefined) {
      setDisabledChange(true);
    }
  }, [details?.status]);

  return (
    <>
      <SubHeading>예약 정보</SubHeading>
      <Table>
        <tbody>
          <tr>
            <th style={{ width: '25%' }}>예약상태</th>
            <td style={{ width: '25%' }}>
              <Select optionList={optionList}
                      onChange={confirmChangeStatus}
                      value={details?.status}
                      disabled={disabledChange}
                      style={{ margin: 0 }} />
            </td>
            <th style={{ width: '25%' }}>방문일</th>
            <td style={{ width: '25%' }}>{details?.upcomingDateTime}</td>
          </tr>
          <tr>
            <th>성명(영문명)</th>
            <td>{details?.appointeeName}
              {details?.appointeeNameEn !== undefined ||
                details?.appointeeNameEn !== '' ? '(' + details?.appointeeNameEn + ')' : ''}</td>
            <th>국적</th>
            <td>{details?.nationality}</td>
          </tr>
          <tr>
            <th>생년월일</th>
            <td>{details?.birth}</td>
            <th>전화번호</th>
            <td>{details?.contact}</td>
          </tr>
          <tr>
            <th>의사</th>
            <td>{details?.doctorName}</td>
            <th>시술명</th>
            <td>{details?.treatmentName}</td>
          </tr>
          <tr>
            <th>요구사항</th>
            <td colSpan={3} style={{ whiteSpace: 'pre-wrap' }}>
              {details?.note !== '' ? (
                <ContentWrapper>
                  {isTranslated ? <p lang="ko">{translatedText}</p> : <p>{details?.note}</p>}
                  <TransButton onClick={() => onTranslate(details?.note)}>
                    {isTranslated ? '원문보기' : '번역보기'}
                  </TransButton>
                </ContentWrapper>) : ''
              }
            </td>
          </tr>
        </tbody>
      </Table>
      {isPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          isPopupVisible={isPopupVisible}
          setIsPopupVisible={setIsPopupVisible}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default UserRezInfoTable;
