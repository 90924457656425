import styled from 'styled-components';
import { fontStyle14 } from '../../styles/mixins';

type TagStatus = string | '예약' | '취소' | '완료' | '노쇼' | '미처리';

const stausCollections: any = {
  예약: '#f5f5f5',
  취소: '#FFC5C5',
  완료: '#A7FF7D',
  노쇼: '#D2F0FF',
  미처리: '#FFB1F74D',
};

const whatStatus = (status: TagStatus) => {
  return stausCollections[status];
};

export const StyledTag = styled.div<{ status: TagStatus }>`
  ${fontStyle14};
  width: 60px;
  height: 26px;
  padding: 2px 5px;
  margin: 0 auto 3px;
  border-radius: 4px;
  text-align: center;
  color: ${props => props.theme.secondary};
  background-color: ${({ status }) => whatStatus(status)};
`;
