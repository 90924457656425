import { ControlButtonBox, ScrollSection, StyledDailyTable, StyledDailyView, Wrapper } from './styles';
import { Card, InnerCellCard } from '../../../../../components';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useApi } from '../../../../../contexts/ApiContext';
import moment from 'moment/moment';
import { AppointmentTimeTableDoctor, TimeTableAppointment, TimeTableAppointmentItem } from '../../../../../model';
export const SCROLL_BUTTON_TRIGGER_LENGTH: number = 7;
interface DailyViewProps {
  currentDate: string;
}

const DailyView = ({ currentDate }: DailyViewProps) => {
  const api = useApi();

  const [doctors, setDoctors] = useState<AppointmentTimeTableDoctor[]>();
  const [timeLines, setTimeLines] = useState<string[]>();
  const [appointments, setAppointments] = useState<TimeTableAppointment>();

  const [availableMove, setAvailableMove] = useState(0);
  const [scrollButtonHide, setScrollButtonHide] = useState(false);
  const tableRef = useRef<any>(null);
  const [thLength, setThLength] = useState(0);

  const statusToText = (appointmentItem: TimeTableAppointmentItem) => {
    const status = appointmentItem.status;
    const today = moment();
    const upcomingAt = moment(appointmentItem.upcomingAt, 'YYYY.MM.DD hh:mm:ss');
    if (status === 'APPOINTED' && today.isAfter(upcomingAt)) {
      return '미처리';
    }
    if (status === 'APPOINTED' && today.isBefore(upcomingAt)) {
      return '예약';
    }
    if (status === 'CANCELED') {
      return '취소';
    }
    if (status === 'VISITED') {
      return '완료';
    }
    if (status === 'NO_SHOW') {
      return '노쇼';
    }
    return '';
  };

  const content = (appointedItem: TimeTableAppointmentItem) => {
    if (appointedItem.appointmentId === '00000000-0000-0000-0000-000000000000') {
      return;
    }

    return (
      <Card
        status={statusToText(appointedItem)}
        isNew={appointedItem.newer}
        doctorName=""
        treatName={appointedItem.treatment}
        visitDate={appointedItem.upcomingAt}
      >
        <p>
          <span>환자명</span>
          <span>
            {appointedItem.appointeeEn
              ? appointedItem.appointee + ' ' + appointedItem.appointeeEn
              : appointedItem.appointee}
          </span>
        </p>
        <p>
          <span>구분</span>
          <span>{appointedItem.newer ? '신규 고객' : '재방문 고객'}</span>
        </p>
        <p>
          <span>연락처</span>
          <span>{appointedItem.contact}</span>
        </p>
        <p>
          <span>예약일</span>
          <span>{appointedItem.appointedAt}</span>
        </p>
        {appointedItem.status !== 'CANCELED' && appointedItem.status !== 'NO_SHOW' ? (
          <p>
            <span>방문일</span>
            <span>{appointedItem.upcomingAt}</span>
          </p>
        ) : (
          ''
        )}
        {appointedItem.status === 'CANCELED' || appointedItem.status === 'NO_SHOW' ? (
          <p>
            <span>취소일</span>
            <span>{appointedItem.canceledAt}</span>
          </p>
        ) : (
          ''
        )}
        <p>
          <span>시술</span>
          <span>{appointedItem.treatment}</span>
        </p>
      </Card>
    );
  };

  function verifyData(column: any) {
    switch (column) {
      case 'offed':
        return {
          props: {
            style: { background: 'rgba(255, 239, 97, .5)' },
          },
        };
      default:
        return column;
    }
  }
  const columns: any = function (timeLines: string[] | undefined, doctors: AppointmentTimeTableDoctor[] | undefined) {
    const minHeaderCount = 8;
    const headerColumns = [];
    headerColumns.push({
      title: '',
      dataIndex: 'timeLine',
      key: 'timeLine',
      rowScope: 'row',
      fixed: 'left',
      width: 70,
      render: (title: any, record: any, index: number) => {
        return timeLines ? timeLines[index] : null;
      },
    });

    doctors?.forEach(doctor => {
      headerColumns.push({
        title: doctor.name,
        dataIndex: doctor.id,
        key: doctor.id,
        render: (value: any, record: any) => {
          if (!doctor.usedAppointment) {
            return { props: { className: 'disabled' }, children: value };
          } else {
            return verifyData(record[doctor.id]);
          }
        },
      });
    });

    if (headerColumns.length < minHeaderCount) {
      while (headerColumns.length < minHeaderCount) {
        headerColumns.push({
          title: '진료실',
          dataIndex: '진료실' + headerColumns.length,
          key: '진료실' + headerColumns.length,
          render: (value: any) => {
            return { props: { className: 'disabled' }, children: value };
          },
        });
      }
    }

    return headerColumns;
  };

  const appointmentData: any = function (
    timeLines: string[] | undefined,
    doctors: AppointmentTimeTableDoctor[] | undefined,
    appointments: TimeTableAppointment | undefined,
  ) {
    const items: any = [];
    timeLines?.forEach(timeLine => {
      let timeLieAppointment: any = {
        time: timeLine,
      };
      doctors?.forEach(doctor => {
        const doctorId = doctor.id;
        const timeTableKey = timeLine + '||' + doctorId;
        const appointedItem = appointments ? appointments[timeTableKey] : undefined;
        if (appointedItem !== undefined) {
          const item = appointedItem[doctorId];
          let columnStyle: any = { padding: '16px 0' };

          if (!item.doTreatment) {
            columnStyle['background'] = 'rgba(255, 239, 97, .5)';
          }
          if (!doctor.usedAppointment) {
            columnStyle['background'] = '#ABABAB';
          }
          timeLieAppointment['key'] = timeTableKey;
          if (item.appointmentId !== '00000000-0000-0000-0000-000000000000') {
            timeLieAppointment[doctorId] = (
              <InnerCellCard
                id={item.appointmentId}
                doctorId={doctor.id}
                currentDate={currentDate}
                timeLine={timeLine}
                status={item.status}
                statusText={statusToText(item)}
                treatName={item.treatment}
                userName={item.appointeeEn ? item.appointee + ' ' + item.appointeeEn : item.appointee}
                content={content(item)}
                columnStyle={columnStyle}
                onSwitchStatus={onSwitchStatus}
                onBreak={onClosingTime}
                isAppointed={item.appointmentId !== '00000000-0000-0000-0000-000000000000'}
              />
            );
          } else {
          }
        }
      });
      items.push(timeLieAppointment);
    });

    return items;
  };

  const loadAppointmentTimeTable = useCallback(async () => {
    const parsedDate = moment(currentDate).format(`YYYYMMDD`);
    const timeTable = (await api.appointment.getAppointmentTimeTable(parsedDate)).data.payload;

    setDoctors(timeTable.doctors);
    setTimeLines(timeTable.timeSchedules);
    setAppointments(timeTable.appointments);
  }, [api.appointment, currentDate]);

  const handlePrev = () => {
    const initialAvailableMove = thLength - 7;
    const firstThWidth = parseInt(tableRef.current.querySelector('col').style.width);
    const columnThWidth = parseInt(tableRef.current.querySelector('col').nextElementSibling.style.width);
    if (initialAvailableMove - availableMove === 0) return;
    if (initialAvailableMove - availableMove === 1) {
      tableRef.current.scrollLeft -= firstThWidth + columnThWidth;
    }
    tableRef.current.scrollLeft -= columnThWidth;
    setAvailableMove(p => p + 1);
  };

  const handleNext = () => {
    const initialAvailableMove = thLength - SCROLL_BUTTON_TRIGGER_LENGTH;
    const firstThWidth = parseFloat(tableRef.current.querySelector('col').style.width);
    const columnThWidth = parseFloat(tableRef.current.querySelector('col').nextElementSibling.style.width);
    if (availableMove === 0 || initialAvailableMove < availableMove) return;
    if (availableMove - 1 === 0) {
      tableRef.current.scrollLeft += firstThWidth + columnThWidth;
      setAvailableMove(p => p - 1);
    } else {
      tableRef.current.scrollLeft += columnThWidth;
      setAvailableMove(p => p - 1);
    }
  };

  const onSwitchStatus = useCallback(
    async (appointmentId: string, status: string) => {
      await api.appointment.changeStatus(appointmentId, status);

      loadAppointmentTimeTable().then().catch();
    },
    [api.appointment, loadAppointmentTimeTable],
  );

  const onClosingTime = useCallback(
    async (doctorId: string, currentDate: string, timeLine: string) => {
      const parsedDate = moment(currentDate).format('YYYYMMDD');
      await api.appointment.breakTime(doctorId, parsedDate, timeLine);

      loadAppointmentTimeTable().then().catch();
    },
    [api.appointment, loadAppointmentTimeTable],
  );

  useEffect(() => {
    if (tableRef.current && tableRef.current instanceof Element) {
      tableRef.current.scrollLeft = 0;
    }
    setAvailableMove(thLength - SCROLL_BUTTON_TRIGGER_LENGTH);
  }, [currentDate, thLength]);

  useEffect(() => {
    if (tableRef.current && tableRef.current instanceof Element) {
      const observer = new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const thElements = tableRef.current.querySelectorAll('th');
            const countLength = thElements.length;
            setThLength(countLength);
            setScrollButtonHide(countLength > SCROLL_BUTTON_TRIGGER_LENGTH ? false : true);
            setAvailableMove(countLength - SCROLL_BUTTON_TRIGGER_LENGTH);
          }
        }
      });
      const config = { childList: true, subtree: true };
      observer.observe(tableRef.current, config);
    }
  }, [thLength, currentDate, availableMove, scrollButtonHide]);

  useEffect(() => {
    loadAppointmentTimeTable().then().catch();
  }, [loadAppointmentTimeTable]);

  return (
    <StyledDailyView>
      <ControlButtonBox isHide={scrollButtonHide}>
        <Button disabled={thLength - SCROLL_BUTTON_TRIGGER_LENGTH - availableMove === 0} onClick={handlePrev}>
          <LeftOutlined />
        </Button>
        <Button disabled={availableMove < 1} onClick={handleNext}>
          <RightOutlined />
        </Button>
      </ControlButtonBox>
      <Wrapper ref={tableRef}>
        <ScrollSection thLength={thLength}>
          <StyledDailyTable
            columns={columns(timeLines, doctors)}
            dataSource={appointmentData(timeLines, doctors, appointments)}
            scroll={{ x: '56.5vw', y: '65vh' }}
            bordered
            pagination={false}
          />
        </ScrollSection>
      </Wrapper>
    </StyledDailyView>
  );
};

export default DailyView;
