import { Select } from '../../../../../components';
import { DateHeadingWrapper, FlexWrapper, HeaderWrapper } from './styles';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface CalendarHeaderProps {
  date?: any;
  changeMonth?: any;
  optionList?: any;
  view?: string;
  onChangeView?: any;
}

const CalendarHeader = ({ view, date, changeMonth, optionList, onChangeView }: CalendarHeaderProps) => {
  return (
    <HeaderWrapper>
      <FlexWrapper>
        <DateHeadingWrapper>
          <button onClick={date => changeMonth(date, `SUBTRACT_${view === '월별보기' ? 'MONTH' : 'DATE'}`)}>
            <LeftOutlined />
          </button>
          <h2>{date.format(`YYYY년 M월 ${view === '일별보기' ? 'D일' : ''}`)}</h2>
          <button onClick={date => changeMonth(date, `ADD_${view === '월별보기' ? 'MONTH' : 'DATE'}`)}>
            <RightOutlined />
          </button>
        </DateHeadingWrapper>
        <Select optionList={optionList}
                value={view}
                onChange={onChangeView}
                style={{ margin: 0 }} />
      </FlexWrapper>
    </HeaderWrapper>
  );
};

export default CalendarHeader;
