import styled from "styled-components";
import { flexbox } from "../../../styles/mixins";

export const StyledLayout = styled.div`
	@media (min-width: 821px) {
		margin: 35px 30px 50px;
	}

	@media screen and (min-width: 1025px) {
		margin: 35px 80px 50px;
	}
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "flex-end")};
	margin: 0 0 10px 0;

	strong {
		font-weight: 700;
	}

	.ant-select {
		margin: 0;
	}
`;
