import React, { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useEffect } from 'react';
import { useState } from 'react';
import ToggleButton from '../../../components/ToggleButton';
import SelectChat from './SelectChat';
import ChatList from './ChatList';
import ChatArea from './ChatArea';
import DefaultPopup from '../../../components/Popup/DefaultPopup';
import UsersDetailTable from './UsersDetailTable';
import { Empty } from 'antd';
import { SearchOutlined, MessageFilled } from '@ant-design/icons';
import { Wrapper, MenuSection, Header, Status, Search, EmptyList, ChatSection, PopupSubHeading } from './styles';
import { useSocket } from '../../../contexts/SocketContext';
import {
  CounselRoomItem,
  HospitalPartnersDetail, ListResponse,
  Member,
  MessageHistory,
  MessageItem,
  MessageType, PaymentListItem,
  SenderType,
} from '../../../model';
import moment, { Moment } from 'moment';
import { useApi } from '../../../contexts/ApiContext';
import UserRezHistoryTable from '../../Reservation/RezInquiry/UserRezHistoryTable';

const optionList = ['전체 메시지', '안 읽은 메시지'];
export enum WebsocketMessageType {
  NEW_COUNSEL_ROOM,
  NEW_MESSAGE,
  UNKNOWN,
}

interface ChatProps {
  isNewVisible: (visible: boolean) => void;
}

const RocketChat = (props: ChatProps) => {
  const api = useApi();
  const socket = useSocket();

  // 병원 정보
  const [hospital, setHospital] = useState<HospitalPartnersDetail>();
  const [hospitalName, setHospitalName] = useState<string>('');

  // 채팅 페이징과 검색
  const [roomList, setRoomList] = useState<CounselRoomItem[]>([]);
  const [roomPage, setRoomPage] = useState<number>(0);
  const [roomPageSize] = useState<number>(25);
  const [pagingResult, setPagingResult] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const [searchName, setSearchName] = useState<string>();
  const [optionKey, setOptionKey] = useState<string>('0');

  // 채팅 상세
  const [room, setRoom] = useState<CounselRoomItem>();
  const [messageHistory, setMessageHistory] = useState<MessageHistory>();
  const [memberData, setMemberData] = useState<Member>();
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const [messagePageSize] = useState<number>(25);
  const [messagePagingResult, setMessagePagingResult] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Moment>();
  const [toBottom, setToBottom] = useState<boolean>(true);

  const onToggleStatus = useCallback(
    (checked: boolean) => {
      socket.modifyStatus(checked);
    },
    [socket],
  );

  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const onClickDetail = useCallback(async () => {
    const data = (await api.counsel.getMember(messageHistory?.memberId!)).data;
    setMemberData(data.data);

    setIsDetailVisible(true);
  }, [api.counsel, messageHistory?.memberId]);

  // 대화방 메세지 이력
  const getMessages = useCallback(async () => {
    if (room && messagePagingResult && startDate) {
      const data = (
        await api.counsel.getCounselMessages(room.roomId, messagePageSize, startDate.format('YYYY-MM-DD HH:mm:ss.SSS'))
      ).data;

      setMessageHistory(data);
    }
  }, [api.counsel, messagePageSize, messagePagingResult, startDate]);

  // 대화 메시지 refresh
  const getRefreshMeessages = useCallback(async () => {
    const currentRoomId = localStorage.getItem('currentRoomId');
    const data = (
      await api.counsel.getCounselMessages(currentRoomId!, messagePageSize, moment().format('YYYY-MM-DD HH:mm:ss.SSS'))
    ).data;

    setMessageHistory(data);
  }, [api.counsel, messagePageSize]);

  // 채팅방 목록 호출 및 응답 처리
  const getCounselRooms = useCallback(
    async (optionMode: string) => {
      if (pagingResult) {
        const result = await socket.getCounselRooms(roomPage, roomPageSize, searchName);
        setPagingResult(result);

        const roomList = socket.rooms;
        if (optionMode === '0' && roomList.length > 0) {
          setRoomList(roomList);
        }
        if (optionMode === '1' && roomList.length > 0) {
          let unreadRooms = [];
          for (const room of socket.rooms) {
            if (room.isUnread === true) {
              unreadRooms.push(room);
            }
          }
          setRoomList(unreadRooms);
        }

        if (result === false) {
          setRoomPage(prev => --prev);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [pagingResult, roomPage, roomPageSize, searchName, setRoomList],
  );

  // 대화방 목록 option
  const optionChange = useCallback(
    async (value: any, option: any) => {
      setOptionKey(option.key);
      setPagingResult(true);
    },
    [optionKey, setOptionKey, setPagingResult],
  );

  // websocket message type
  const getMessageType = useCallback((message: any): WebsocketMessageType => {
    // websocket subscripbe message
    if (message.hasOwnProperty('msg') && message.hasOwnProperty('collection')) {
      if (message['msg'] === 'changed' && message['collection']) {
        if (message['fields']['args'][0] === 'inserted') {
          return WebsocketMessageType.NEW_COUNSEL_ROOM;
        } else if (message['fields']['args'][0] === 'updated') {
          return WebsocketMessageType.NEW_MESSAGE;
        }
      }
    }
    return WebsocketMessageType.UNKNOWN;
  }, []);

  // 신규 대화방 생성 수신 처리
  const handleNewCounselRoom = useCallback((message: any) => {
    const roomMessage = message['fields']['args'][1];

    const newRoom = [
      {
        roomId: roomMessage['_id'],
        memberName: roomMessage['fname'],
        lastMessage: getFirstCounselRoomMessage(),
        messageType: 'TEXT',
        lastMessageDateTime: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
        isUnread: true,
      },
    ];

    setRoomList(prev => newRoom.concat(prev) ?? prev);
  }, []);

  // 대화방 message 수신 처리
  const handleNewCounselMessage = useCallback((lastJsonMessage: any, roomList: CounselRoomItem[]) => {
    if (lastJsonMessage.hasOwnProperty('msg') && lastJsonMessage.hasOwnProperty('collection')) {
      const roomMessage = lastJsonMessage['fields']['args'][1];
      const lastMessage = roomMessage['lastMessage'];
      const messageRoomId = lastMessage['rid'];
      const currentRoomId = localStorage.getItem('currentRoomId');

      const messageType = getLastMessageType(lastMessage);
      if (messageRoomId === currentRoomId) {
        if (messageType === MessageType.TEXT) {
          setTextMessageItem(lastJsonMessage, MessageType.TEXT);
        }
        if (messageType === MessageType.RECOVERY_RECORD) {
          setTextMessageItem(lastJsonMessage, MessageType.RECOVERY_RECORD);
        }
        if (messageType === MessageType.FILE) {
          getFileMessageItem();
        }
      }

      setNewMessageRoom(roomList, messageRoomId, messageType, lastMessage['msg']);
    }
  }, []);

  // 신규 메시지 수신 시 해당 대화방 갱신
  const setNewMessageRoom = (
    roomList: CounselRoomItem[],
    messageRoomId: string,
    messageType: MessageType,
    message: string,
  ) => {
    let roomLastMessage = '';
    if (messageType === MessageType.TEXT) {
      roomLastMessage = message;
    }
    if (messageType === MessageType.RECOVERY_RECORD) {
      roomLastMessage = '회복 기록을 보냈습니다.';
    }
    if (messageType === MessageType.APPOINTMENT) {
      roomLastMessage = '예약 정보를 보냈습니다.';
    }
    if (messageType === MessageType.FILE) {
      roomLastMessage = '사진을 보냈습니다.';
    }

    let refreshRoomList = [];
    const currentRoomId = localStorage.getItem('currentRoomId');
    for (const roomItem of roomList) {
      if (roomItem.roomId === messageRoomId) {
        currentRoomId === messageRoomId ? (roomItem.isUnread = false) : (roomItem.isUnread = true);
        roomItem.lastMessage = roomLastMessage;
        roomItem.lastMessageDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      refreshRoomList.push(roomItem);
    }
    setRoomList(refreshRoomList);
  };

  const checkNewMessage = useCallback((newMessage: any): boolean => {
    if (newMessage.hasOwnProperty('fields')) {
      const messageField = newMessage['fields'];
      if (messageField.hasOwnProperty('args')) {
        const messageType = newMessage['fields']['args'][0];
        const lastMessageId = newMessage['fields']['args'][1]['lastMessage']['_id'];
        const alreadyAttachId = localStorage.getItem('newMessageId');
        // 대화방 생성 시에는 messageType이 'inserted', 'updated' 두가지 모두 수신됨.
        if (lastMessageId === alreadyAttachId && messageType === 'updated') {
          return false;
        } else {
          localStorage.setItem('newMessageId', lastMessageId);
          // 신규 메시지 표시를 위해 저장
          const messageRoomId = newMessage['fields']['args'][1]['lastMessage']['rid'];
          const currentRoomId = localStorage.getItem('currentRoomId');
          if (messageRoomId !== currentRoomId) {
            saveNewMessageRoomId(messageRoomId);
          }
        }
      }
    }
    return true;
  }, []);

  const saveNewMessageRoomId = (messageRoomId: string) => {
    const newMessageRoomIds = localStorage.getItem('newMessageRoomIds');
    if (!!!newMessageRoomIds?.includes(messageRoomId)) {
      localStorage.setItem(
        'newMessageRoomIds',
        newMessageRoomIds ? newMessageRoomIds + ',' + messageRoomId : messageRoomId,
      );
    }
    props.isNewVisible(true);
  };

  const getLastMessageType = useCallback((lastMessage: any): MessageType => {
    if (lastMessage.hasOwnProperty('file')) {
      return MessageType.FILE;
    } else {
      const msg = lastMessage['msg'].toString();

      if (msg.startsWith('###RECOVERY-RECORD###') && msg.endsWith('#####################')) {
        return MessageType.RECOVERY_RECORD;
      }
    }

    return MessageType.TEXT;
  }, []);

  // text message to message item
  const setTextMessageItem = (lastJsonMessage: any, messageType: MessageType) => {
    const lastMessage = lastJsonMessage['fields']['args'][1]['lastMessage'];
    let counselMessage = lastMessage['msg'].toString();
    if (messageType === MessageType.RECOVERY_RECORD) {
      counselMessage = counselMessage.replace('###RECOVERY-RECORD###', '');
      counselMessage = counselMessage.replace('#####################', '');
    }

    const newMessage = [
      {
        sender: lastMessage['u']['name'],
        senderType: lastMessage.hasOwnProperty('token') ? SenderType.USER : SenderType.HOSPITAL,
        type: messageType,
        messageId: lastMessage['_id'],
        message: counselMessage,
        sendDateTime: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
      },
    ];

    setMessages(prev => prev.concat(newMessage) ?? newMessage);
  };

  // file message to message item
  // websocket payload에 file 접근 가능한 'public url'이 존재하지 않음.
  const getFileMessageItem = () => {
    setMessages([]);
    getRefreshMeessages();
  };

  // 신규 대화방 최초 message 정의
  const getFirstCounselRoomMessage = () => {
    return '안녕하세요.' + hospitalName + ' 채팅화면에 오신것을 환영합니다. 궁금한 점을 편하게 물어보실수 있습니다.';
  };

  // 대화방 websocket subscribe
  const handleMessage = useCallback(
    (lastJsonMessage: string, roomList: CounselRoomItem[]) => {
      const messageType = getMessageType(lastJsonMessage);
      // websocket 수신 message 중복처리
      const attachable = checkNewMessage(lastJsonMessage);

      if (attachable) {
        switch (messageType) {
          case WebsocketMessageType.NEW_COUNSEL_ROOM:
            handleNewCounselRoom(lastJsonMessage);
            break;
          case WebsocketMessageType.NEW_MESSAGE:
            handleNewCounselMessage(lastJsonMessage, roomList);
            break;
        }
      }
    },
    [getMessageType, handleNewCounselRoom, handleNewCounselMessage],
  );

  // 검색
  const onSearch = (e: FormEvent) => {
    e.preventDefault();
    setSearchName(name);
  };

  // 검색 input box 'enter' key
  const handleOnKeyPress = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const result = await socket.getRefreshCounselRooms(0, roomPageSize, name);
        setPagingResult(result);
        setRoomList(socket.rooms);
      }
    },
    [setPagingResult, setRoomList, roomPageSize, name],
  );

  // 채팅방 선택
  const onClickRoom = useCallback((item: CounselRoomItem) => {
    item.isUnread = false;
    setRoom(item);
  }, []);

  // 신규 대화방 정보 localStorage 제거
  const removeNewRoomMark = (roomId: string) => {
    const prefixNewRoomId = ',' + roomId;
    if (localStorage.getItem('newMessageRoomIds')?.includes(prefixNewRoomId)) {
      const newRoomIds = localStorage.getItem('newMessageRoomIds');
      const removedCurrentRoom = newRoomIds!.replace(prefixNewRoomId, '');
      localStorage.setItem('newMessageRoomIds', removedCurrentRoom);
    }
    if (localStorage.getItem('newMessageRoomIds')?.includes(roomId)) {
      const newRoomIds = localStorage.getItem('newMessageRoomIds');
      const removedCurrentRoom = newRoomIds!.replace(roomId, '');
      localStorage.setItem('newMessageRoomIds', removedCurrentRoom);
    }

    if (localStorage.getItem('newMessageRoomIds') === null || localStorage.getItem('newMessageRoomIds') === '') {
      props.isNewVisible(false);
    }
  };

  // 병원 데이터 호출
  const getHospital = useCallback(async () => {
    const data = (await api.hospital.getHospital('ko')).data;
    setHospital(data);
    setHospitalName(data.hospitalName);
  }, [api.hospital, setHospital, setHospitalName]);

  // 채팅방 목록 첫 호출
  useEffect(() => {
    getCounselRooms(optionKey);
  }, [optionKey, getCounselRooms]);

  // 대화 히스토리
  useEffect(() => {
    getMessages();
  }, [getMessages]);

  // 히스토리 변경시 메세지 리스트 반영
  useEffect(() => {
    if (messageHistory?.messages) {
      const list = messageHistory?.messages;
      if (list.length > 0) {
        setMessages(prev => list.concat(prev) ?? prev);
      } else {
        setMessagePagingResult(false);
      }
    }
  }, [messageHistory]);

  // 룸 변경시
  useEffect(() => {
    if (room) {
      setToBottom(true);
      setMessagePagingResult(true);
      setMessages([]);
      setStartDate(moment());

      localStorage.setItem('currentRoomId', room.roomId);
      localStorage.removeItem('newMessageId');
      removeNewRoomMark(room.roomId);
    }
  }, [room]);

  // 웹 소켓 메세지 응답에 대한 처리
  useEffect(() => {
    if (socket.lastJsonMessage !== null) {
      handleMessage(socket.lastJsonMessage, roomList);
    }
  }, [socket]);

  // 병원 정보 조회
  useEffect(() => {
    getHospital();
  }, [getHospital]);

  return (
    <>
      <Wrapper>
        <MenuSection>
          <Header>
            <h1>{hospitalName}</h1>
            <Status>
              {socket.status ? <p>상담 가능</p> : <p>상담 준비중</p>}
              <ToggleButton checked={socket.status} onChange={onToggleStatus} />
            </Status>
          </Header>

          <SelectChat optionList={optionList} optionChange={optionChange} />

          <Search>
            <SearchOutlined style={{ fontSize: '18px' }} />
            <form onSubmit={onSearch}>
              <input
                type="text"
                value={name}
                onChange={onChangeName}
                onKeyDown={handleOnKeyPress}
                placeholder="성명을 입력해주세요."
              />
            </form>
          </Search>

          {socket.rooms?.length > 0 ? (
            <ChatList rooms={roomList} setPage={setRoomPage} onClick={onClickRoom} selected={room} />
          ) : (
            <EmptyList>
              <Empty description={false} />
              <p>결과 0 건</p>
            </EmptyList>
          )}
        </MenuSection>

        <ChatSection>
          {room ? (
            <ChatArea
              history={messageHistory}
              roomId={room.roomId}
              hospitalName={hospitalName}
              messages={messages}
              toBottom={toBottom}
              setToBottom={setToBottom}
              onClickDetail={onClickDetail}
              setStartDate={setStartDate}
            />
          ) : (
            <>
              <MessageFilled style={{ fontSize: '60px' }} />
              <p>대화 상대를 선택하세요.</p>
            </>
          )}
        </ChatSection>
      </Wrapper>

      {isDetailVisible && (
        <DefaultPopup
          title="상세보기"
          isPopupVisible={isDetailVisible}
          setIsPopupVisible={setIsDetailVisible}
          setIsConfirmVisible={(visible: boolean) => {}}
          setIsEditVisible={(visible: boolean) => {}}
          isWide
          isWithOkay
        >
          <PopupSubHeading>기본 정보</PopupSubHeading>
          <UsersDetailTable data={memberData} />
          <UserRezHistoryTable appointeeId={memberData?.memberId}/>
        </DefaultPopup>
      )}
    </>
  );
};
export default React.memo(RocketChat);
