import styled from "styled-components";
import { flexbox, fontStyle16, fontStyle14, fontStyle12, truncate, flexboxColumn, scrollBase } from "../../../../styles/mixins";

export const List = styled.ul`
	${scrollBase};
	background-color: ${(props) => props.theme.white};
	border-radius: 8px;
	// 100% - Header높이 - Select높이 - Search높이
	height: calc(100% - 43px - 61px - 61px);
	overflow-y: auto;
`;

export const Item = styled.li`
	width: 100%;
	padding: 0 16px;
	&.selected {
		background-color: ${(props) => props.theme.menuItem_bg};
	}
	&:hover {
		background-color: ${(props) => props.theme.menuItem_bg};

		p {
			color: ${(props) => props.theme.grey};
		}
	}
	> div {
		${flexboxColumn("center", "flex-start")};
		width: 100%;
		height: 80px;
		color: ${(props) => props.theme.primary};
		border-bottom: 1px solid #f5f5f5;
		transition: background-color 200ms ease-in-out;

		p {
			transition: color 200ms ease-in-out;
		}
	}
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "center")};
	width: 100%;

	&:first-of-type {
		margin: 0 0 7px 0;
	}
`;

export const Name = styled.p`
	${fontStyle16};
	font-weight: 500;
`;

export const Timestamp = styled.p`
	${fontStyle12};
	color: ${(props) => props.theme.grey};
`;

export const Content = styled.p`
	${fontStyle14};
	${truncate};
	width: 340px;
`;

export const Unread = styled.span`
	${flexbox("center", "center")};
	${fontStyle12};
	min-width: 20px;
	height: 20px;
	padding: 0 5px;
	border-radius: 4px;
	text-align: center;
	color: ${(props) => props.theme.white};
	background-color: ${(props) => props.theme.tag_bg};
`;
