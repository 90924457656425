import React, { useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LogIn from './pages/LogIn';
import Management from './pages/Management';
import Hospital from './pages/Management/Hospital';
import Doctor from './pages/Management/Doctor';
import DoctorEdit from './pages/Management/Doctor/DoctorEdit';
import Treat from './pages/Management/Treat';
import TreatEdit from './pages/Management/Treat/TreatEdit';
import ConsultationDefault from './pages/ConsultationDefault';
import Consultation from './pages/Consultation';
import Review from './pages/Review';
import Recovery from './pages/Recovery';

import AuthContextProvider from './helpers/AuthProvider';
import ApiContextProvider from './helpers/ApiProvider';

import jwt_decode from 'jwt-decode';
import { useAuth } from './contexts/AuthContext';
import { LoginUser } from './model';
import SocketContextProvider from './helpers/SocketProvider';
import Reservation from './pages/Reservation';
import RezStatus from './pages/Reservation/RezStatus';
import RezInquiry from './pages/Reservation/RezInquiry';
import RezSettings from './pages/Reservation/RezSettings';
import RezStatusCard from './pages/RezStatusCard';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  const accessToken = useMemo(() => {
    return auth?.accessToken;
  }, [auth]);

  useEffect(() => {
    if (accessToken && !auth.user) {
      auth.setUser(jwt_decode<LoginUser>(accessToken));
    }
  }, [accessToken, auth]);

  if (!accessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <ApiContextProvider>
      <AuthContextProvider>
        <SocketContextProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/management/hospital" />} />
            <Route path="/login" element={<LogIn />} />
            <Route
              path="/management"
              element={
                <RequireAuth>
                  <Management />
                </RequireAuth>
              }
            >
              <Route
                path="hospital"
                element={
                  <RequireAuth>
                    <Hospital />
                  </RequireAuth>
                }
              />
              <Route
                path="doctor"
                element={
                  <RequireAuth>
                    <Doctor />
                  </RequireAuth>
                }
              />
              <Route
                path="doctor/:id"
                element={
                  <RequireAuth>
                    <DoctorEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="doctor/write"
                element={
                  <RequireAuth>
                    <DoctorEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="treat"
                element={
                  <RequireAuth>
                    <Treat />
                  </RequireAuth>
                }
              />
              <Route
                path="treat/:id"
                element={
                  <RequireAuth>
                    <TreatEdit />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/reservation"
              element={
                <RequireAuth>
                  <Reservation />
                </RequireAuth>
              }
            >
              <Route
                path="status"
                element={
                  <RequireAuth>
                    <RezStatus />
                  </RequireAuth>
                }
              />
              <Route
                path="inquiry"
                element={
                  <RequireAuth>
                    <RezInquiry />
                  </RequireAuth>
                }
              />

              <Route
                path="settings"
                element={
                  <RequireAuth>
                    <RezSettings />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/consultation-default"
              element={
                <RequireAuth>
                  <ConsultationDefault />
                </RequireAuth>
              }
            />
            <Route
              path="/consultation"
              element={
                <RequireAuth>
                  <Consultation />
                </RequireAuth>
              }
            />
            <Route
              path="/review"
              element={
                <RequireAuth>
                  <Review />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/recovery"
              element={
                <RequireAuth>
                  <Recovery />
                </RequireAuth>
              }
            />
            <Route
              path="/rez-status-card"
              element={
                <RequireAuth>
                  <RezStatusCard />
                </RequireAuth>
              }
            />
          </Routes>
        </SocketContextProvider>
      </AuthContextProvider>
    </ApiContextProvider>
  );
}

export default App;
