import { ChangeEvent, FormEvent, useCallback, useState, useEffect, ReactNode, useMemo } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import PageHeading from '../../../../components/PageHeading';
import ContentHeading from '../../../../components/ContentHeading';
import LabelText from '../../../../components/Inputs/LabelText';
import Label from '../../../../components/Inputs/Label';
import LabelInput from '../../../../components/Inputs/LabelInput';
import LabelSelect from '../../../../components/Inputs/LabelSelect';
import AddImageButton from '../../../../components/AddImageButton';
import DefaultImage from '../../../../components/DefaultImage';
import TableHeading from '../../../../components/TableHeading';
import TableSort from '../../../../components/Tables/TableSort';
import TableDefault from '../../../../components/Tables/TableDefault';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../../../components/Popup/DefaultPopup';
import UpDownButton from '../../../../components/UpDownButton';
import InnerTdButton from '../../../../components/InnerTdButton';
import { Divider } from '../../../../components';
import { StyledLayout, Section, ImageWrapper, ButtonWrapper, Button, PopupSubHeading } from './styles';
import { StyledTabs } from '../../styles';
import { useApi } from '../../../../contexts/ApiContext';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  DoctorDetailForPartners,
  TreatmentForPartners,
  DetailItemImage,
  ShiftDirection,
  HospitalTreatmentsResponseItem,
  HospitalPartnersDetail,
  TreatmentItemForPartners,
  IntroductionImageByPartners,
  RequestDoctorCreateByPartners,
  DoctorTreatmentByPartners,
  RequestDoctorModifyByPartners,
} from '../../../../model';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import Image from '../../../../components/Image';
import { fileToDataUri } from '../../../../helpers/FileHelper';
import _ from 'lodash';
import DoctorEditTranslate from './DoctorEditTranslate';
import { doctorEditEnData, doctorEditzhData } from '../../../../mockData';

type URLParams = {
  id?: string;
};

const optionList = ['선택', '대표원장', '원장'];
const popupText = {
  delete: <>삭제 하시겠습니까?</>,
  unsave: (
    <>
      저장되지 않은 변경 내용이 있습니다. <br />
      저장하지 않고 나가려면 확인을 눌러주세요.
    </>
  ),
  validation: <>필수 항목을 다시 확인해 주세요.</>,
  save: (
    <>
      입력한 내용을 저장할까요? <br />
      확인을 누르면 앱에 바로 반영됩니다.
    </>
  ),
  unchange: <>변경 사항이 없습니다.</>,
};

const DoctorEdit = () => {
  const { TabPane } = Tabs;

  const api = useApi();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { id } = useParams<URLParams>();

  const [region, setRegion] = useState<string>('ko');
  // 의사 정보 (수정)
  const [doctor, setDoctor] = useState<DoctorDetailForPartners>();

  // 병원 데이터 및 시술 정보
  const [hospital, setHospital] = useState<HospitalPartnersDetail>();
  const [hospitalsTreats, setHospitalTreats] = useState<TreatmentItemForPartners[]>([]);

  // 의료진 기본 정보 입력 항목
  const [display, setDisplay] = useState(false);
  const [name, setName] = useState('');
  const [isEmptyName, setIsEmptyName] = useState(false);
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const [position, setPosition] = useState('');
  const [isEmptyPosition, setIsEmptyPosition] = useState(false);
  const onChangePosition = (value: any) => {
    setPosition(value !== '선택' ? value : '');
  };
  const [intro, setIntro] = useState('');
  const [isEmptyIntro, setIsEmptyIntro] = useState(false);
  const onChangeIntro = (e: ChangeEvent<HTMLInputElement>) => setIntro(e.target.value);

  const [representative, setRepresentative] = useState<string>();
  const [representativeFile, setRepresentativeFile] = useState<File>();
  const [isEmptyRepresentativeFile, setIsEmptyRepresentativeFile] = useState(false);
  const onChangeRepresentativeFile = (file?: File) => {
    setRepresentative(undefined);
    setRepresentativeFile(file);
  };

  // 소개 사진 목록
  const [images, setImages] = useState<DetailItemImage[]>([]);

  // 소개 사진 추가 팝업
  const [introduceFile, setIntroduceFile] = useState<File>();
  const [isEmptyIntroduceFile, setIsEmptyIntroduceFile] = useState(false);
  const onChangeIntroduceFile = (file?: File) => setIntroduceFile(file);

  // 소개 사진 수정
  const [editIndex, setEditIndex] = useState<number>();

  // 진료 시술
  const [selectedTreats, setSelectedTreats] = useState<HospitalTreatmentsResponseItem[]>([]);
  const [treats, setTreats] = useState<TreatmentForPartners[]>([]);

  // Comfirm Popup
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

  // 팝업 관리
  const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);
  const showAddPopup = () => setIsAddPopupVisible(true);
  const [isAddDoctorVisible, setIsAddDoctorVisible] = useState(false);
  const showAddDoctorPopup = () => {
    setEditIndex(undefined);
    setIntroduceFile(undefined);
    setIsAddDoctorVisible(true);
  };

  // 수정 시 의사 데이터 호출
  const getDoctor = useCallback(async () => {
    if (!pathname.includes('write')) {
      try {
        const data = (await api.doctor.getDoctor(id ?? '', region)).data;
        setDoctor(data);
        setOriginal(data);
      } catch (e) {
        navigate(-1);
      }
    }
  }, [api.doctor, id, navigate, pathname, region]);

  // 병원 데이터 호출
  const getHospital = useCallback(async () => {
    try {
      const data = (await api.hospital.getHospital(region)).data;
      setHospital(data);
    } catch (e) {
      navigate(-1);
    }
  }, [api.hospital, doctor?.hospitalId, id, navigate, pathname, region]);

  // 병원 시술 데이터 호출
  const getHospitalsTreats = useCallback(async () => {
    const data = (await api.treatment.getTreatments()).data;
    setHospitalTreats(data.data.map(row => ({ key: row.treatmentId, ...row })));
  }, [api.hospital, hospital, region]);

  const [original, setOriginal] = useState<DoctorDetailForPartners>();

  // 수정 시 의사 데이터 반영
  const setDoctorData = useCallback(() => {
    if (doctor) {
      setName(doctor.doctorName);
      setPosition(doctor.position);
      setIntro(doctor.introductionSummary);
      setRepresentative(doctor.representativeImageUrl);
      setImages(
        doctor.introductionImageUrls?.map((row, index) => ({
          key: index,
          imageUrl: row.imageUrl,
          displayOrder: index + 1,
        })),
      );
      setTreats(
        doctor.treatments?.map((row, index) => ({
          key: index,
          treatmentId: row.treatmentId,
          treatmentName: row.treatmentName,
          displayOrder: index + 1,
        })),
      );
    }
  }, [doctor]);

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk?: VoidFunction,
    handleCancel?: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 소개 사진 추가
  const onSaveIntroImage = useCallback(() => {
    if (introduceFile) {
      setIsEmptyIntroduceFile(false);

      if (editIndex !== undefined) {
        const imageData = {
          ...images[editIndex],
          imageUrl: URL.createObjectURL(introduceFile),
          file: introduceFile,
        };

        setImages(prev => {
          const copy = [...prev];
          copy[editIndex] = imageData;

          return copy;
        });
      } else {
        const imageData: DetailItemImage = {
          imageUrl: URL.createObjectURL(introduceFile),
          displayOrder: images.length + 1,
          key: images.length,
          file: introduceFile,
        };

        setImages(prev => {
          return [...prev].concat(imageData);
        });
      }
      setIsAddDoctorVisible(false);
    } else {
      setIsEmptyIntroduceFile(true);
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [editIndex, images, introduceFile]);

  // 소개 사진 삭제
  const onDeleteImage = useCallback((index: number) => {
    setImages(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        for (let i = 0; i < copy.length; i++) {
          copy[i].displayOrder = i + 1;
        }

        return copy;
      }

      return prev;
    });
  }, []);

  // 소개 사진 수정 버튼
  const onPressEditImage = useCallback((index: number, data: DetailItemImage) => {
    setEditIndex(index);
    onChangeIntroduceFile(data.file);
    setIsAddDoctorVisible(true);
  }, []);

  // 소개 사진 삭제 버튼
  const onPressDeleteImage = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteImage(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteImage],
  );

  // 진료 시술 저장 버튼
  const onSaveTreats = useCallback(() => {
    if (selectedTreats.length > 0) {
      setIsAddPopupVisible(false);
      setTreats(
        selectedTreats.map((row, index) => ({
          key: index,
          treatmentId: row.treatmentId,
          treatmentName: row.treatmentName,
          displayOrder: index + 1,
        })),
      );
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [selectedTreats]);

  // 진료 시술 삭제
  const onDeleteTreats = useCallback((index: number) => {
    setTreats(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        for (let i = 0; i < copy.length; i++) {
          copy[i].displayOrder = i + 1;
        }

        return copy;
      }

      return prev;
    });
  }, []);

  // 진료 시술 삭제 버튼
  const onPressDeleteTreats = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteTreats(index));
      setIsConfirmPopupVisible(true);
    },[onDeleteTreats]);

  // 진료 시술 순서 변경
  const swapTreatsOrder = useCallback(
    async (target: TreatmentForPartners, index: number, direction: ShiftDirection) => {
      let swapTarget;
      let swapIndex;
      if (treats.length >= 2) {
        switch (direction) {
          case ShiftDirection.FORWARD: {
            swapIndex = index - 1;
            swapTarget = treats.at(swapIndex);
            break;
          }
          case ShiftDirection.BACKWARD: {
            swapIndex = index + 1;
            swapTarget = treats.at(swapIndex);
            break;
          }
        }

        if (swapTarget) {
          const key = swapTarget.key;
          const order = swapTarget.displayOrder;

          swapTarget.key = target.key;
          swapTarget.displayOrder = target.displayOrder;
          target.key = key;
          target.displayOrder = order;

          const copy = [...treats];
          copy[swapIndex] = swapTarget;
          copy[index] = target;
          copy.sort((a, b) => a.displayOrder - b.displayOrder);

          setTreats(copy);
        }
      }
    },
    [treats],
  );

  // 유효성 검사
  const checkValidation = useCallback((): boolean => {
    let validation = true;
    if (!name) {
      setIsEmptyName(true);
      validation = false;
    } else {
      setIsEmptyName(false);
    }

    if (!position) {
      setIsEmptyPosition(true);
      validation = false;
    } else {
      setIsEmptyPosition(false);
    }

    if (!intro) {
      setIsEmptyIntro(true);
      validation = false;
    } else {
      setIsEmptyIntro(false);
    }

    if (!representative && !representativeFile) {
      setIsEmptyRepresentativeFile(true);
      validation = false;
    } else {
      setIsEmptyRepresentativeFile(false);
    }

    if (validation) {
      setIsEmptyName(false);
      setIsEmptyPosition(false);
      setIsEmptyIntro(false);
      setIsEmptyRepresentativeFile(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [intro, name, position, representative, representativeFile]);

  const isContentsChanged = useCallback(() => {
    if (original && doctor) {
      const originalData: RequestDoctorModifyByPartners = {
        doctorName: original.doctorName,
        position: original.position,
        introductionSummary: original.introductionSummary,
        representativeImageUrl: original.representativeImageUrl,
        introImages: original.introductionImageUrls.map((row, index) => {
          return {
            displayOrder: index + 1,
            imageUrl: row.imageUrl,
          };
        }),
        treatments: original.treatments.map((row, index) => {
          return {
            treatmentId: row.treatmentId,
            displayOrder: index + 1,
            treatmentName: row.treatmentName,
          };
        }),
      };

      const changedData: RequestDoctorModifyByPartners = {
        doctorName: name,
        position: position,
        introductionSummary: intro,
        representativeImageUrl: representative ?? '',
        introImages: images.map((row, index) => {
          return {
            displayOrder: index + 1,
            imageUrl: row.imageUrl,
          };
        }),
        treatments: treats.map((row, index) => {
          return {
            treatmentId: row.treatmentId,
            displayOrder: index + 1,
            treatmentName: row.treatmentName,
          };
        }),
      };

      return !_.isEqual(originalData, changedData);
    } else {
      const changedData: RequestDoctorModifyByPartners = {
        doctorName: name,
        position: position,
        introductionSummary: intro,
        representativeImageUrl: representative ?? '',
        introImages: images.map((row, index) => {
          return {
            displayOrder: index + 1,
            imageUrl: row.imageUrl,
          };
        }),
        treatments: treats.map((row, index) => {
          return {
            treatmentId: row.treatmentId,
            displayOrder: index + 1,
            treatmentName: row.treatmentName,
          };
        }),
      };

      if (name !== '') {
        return true;
      }

      if (position !== '') {
        return true;
      }

      if (intro !== '') {
        return true;
      }

      if ((representative ?? '') !== '') {
        return true;
      }

      if (images.length > 0) {
        return true;
      }

      if (treats.length > 0) {
        return true;
      }

      return false;
    }
  }, [original, doctor, name, position, intro, representative, images, treats]);

  // 취소
  const onReset = useCallback(() => {
    let isChanged = isContentsChanged();
    if (!isChanged) {
      navigate('/management/doctor');
      return;
    }

    setPopupData(popupText.unsave, true, () => () => navigate('/management/doctor'));
    setIsConfirmPopupVisible(true);
  }, [navigate, doctor, isContentsChanged]);

  // 저장
  const onSubmit = useCallback(async () => {
    if (hospital) {
      // 대표 사진 업로드
      let representativeFileUrl: string = representative ?? '';

      if (representativeFile) {
        const fileReader = await fileToDataUri(representativeFile);
        if (fileReader) {
          const data = (await api.file.getPresignedUrl(representativeFile.name)).data;
          await api.file.uploadFile(data.signedUrl, representativeFile);

          representativeFileUrl = data.uploadTargetUrl;
        }
      }

      // 소개 사진 목록 업로드
      const introductionImages: IntroductionImageByPartners[] = await Promise.all(
        images.map(async row => {
          let url = '';
          if (row.file) {
            const fileReader = await fileToDataUri(row.file);
            if (fileReader) {
              const data = (await api.file.getPresignedUrl(row.file.name)).data;
              await api.file.uploadFile(data.signedUrl, row.file);

              url = data.uploadTargetUrl;
            }
          } else {
            url = row.imageUrl;
          }

          return {
            imageUrl: url,
            displayOrder: row.displayOrder,
          };
        }),
      );

      // 진료 시술 목록 구성
      const treatments: DoctorTreatmentByPartners[] = treats.map(row => ({
        treatmentId: row.treatmentId,
        displayOrder: row.displayOrder,
        treatmentName: row.treatmentName,
      }));

      if (!doctor) {
        // 등록 시
        const submitData: RequestDoctorCreateByPartners = {
          hospitalName: hospital.hospitalName,
          doctorName: name,
          position: position,
          introductionSummary: intro,
          representativeImageUrl: representativeFileUrl,
          introductionImages: introductionImages,
          treatments: treatments,
        };

        const data = (await api.doctor.addDoctor(submitData)).data;
        if (data.created) {
          navigate('/management/doctor');
        }
      } else {
        // 수정 시
        const submitData: RequestDoctorModifyByPartners = {
          doctorName: name,
          position: position,
          introductionSummary: intro,
          representativeImageUrl: representativeFileUrl,
          introImages: introductionImages,
          treatments: treatments,
        };

        const data = (await api.doctor.modifyDoctor(doctor.doctorId, submitData)).data;
        if (data.modified) {
          navigate('/management/doctor');
        }
      }
    }
  }, [
    api.doctor,
    api.file,
    display,
    doctor,
    hospital,
    images,
    intro,
    name,
    navigate,
    position,
    representative,
    representativeFile,
    treats,
  ]);

  const onClickSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (checkValidation()) {
        setPopupData(popupText.save, true, () => () => onSubmit());
        setIsConfirmPopupVisible(true);
      }
    },
    [onSubmit, checkValidation],
  );

  const onRegionChange = useCallback(
    (key: string) => {
      switch (key) {
        case '1':
          setRegion('ko');
          break;
        case '2':
          setRegion('en');
          break;
        case '3':
          setRegion('zh');
      }
    },
    [getHospital],
  );

  // 소개 사진 칼럼
  const doctorColumns: ColumnsType<DetailItemImage> = [
    { title: '번호', dataIndex: 'displayOrder', key: 'displayOrder' },
    {
      title: '사진',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value: any, record: DetailItemImage) => {
        if (record.imageUrl) {
          return <Image src={record.imageUrl} />;
        } else {
          return <DefaultImage />;
        }
      },
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record: DetailItemImage, index: number) =>
        region !== 'ko' ? (
          <InnerTdButton isWithEdit isDisabled />
        ) : (
          <InnerTdButton
            isWithEdit
            onClickEdit={() => onPressEditImage(index, record)}
            onClickDelete={() => onPressDeleteImage(index)}
          />
        ),
    },
  ];

  // 진료 시술 칼럼
  const treatColumns: ColumnsType<TreatmentForPartners> = [
    {
      title: '순서 변경',
      dataIndex: 'change',
      key: 'change',
      width: 220,
      render: (value: any, record: TreatmentForPartners, index: number) =>
        region !== 'ko' ? (
          <>
            <UpDownButton isDisabled />
            <UpDownButton isDisabled isDown />
          </>
        ) : (
          <>
            <UpDownButton
              onClick={() => {
                if (index > 0) {
                  swapTreatsOrder(record, index, ShiftDirection.FORWARD);
                }
              }}
            />
            <UpDownButton
              onClick={() => {
                if (index < treats.length - 1) {
                  swapTreatsOrder(record, index, ShiftDirection.BACKWARD);
                }
              }}
              isDown
            />
          </>
        ),
    },
    {
      title: '노출 순서',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      width: 120,
    },
    { title: '시술명', dataIndex: 'treatmentName', key: 'treatmentName' },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record: TreatmentForPartners, index: number) =>
        region !== 'ko' ? (
          <InnerTdButton isDisabled />
        ) : (
          <InnerTdButton onClickDelete={() => onPressDeleteTreats(index)} />
        ),
    },
  ];

  // 병원 시술 목록 칼럼
  const popupColumns = [
    { title: '시술명', dataIndex: 'name', key: 'name' },
    { title: '진료과', dataIndex: 'departmentName', key: 'departmentName' },
    { title: '시술 카테고리', dataIndex: 'treatmentCategoryName', key: 'treatmentCategoryName' },
  ];

  // 병원 시술 체크 목록
  const selectedKeys = useMemo(() => {
    const selected = hospitalsTreats.filter(row => treats.find(selected => selected.treatmentId === row.treatmentId));

    return selected.map(row => row.key);
  }, [hospitalsTreats, treats]);

  // 병원 시술 체크 박스
  const rowSelection: TableRowSelection<HospitalTreatmentsResponseItem> = {
    onChange: (keys, rows) => {
      setSelectedTreats(rows);
    },
    defaultSelectedRowKeys: selectedKeys,
  };

  // 의사 호출
  useEffect(() => {
    getDoctor()
      .then().catch();
  }, [getDoctor]);

  // 병원 호출
  useEffect(() => {
    getHospital()
      .then().catch();
  }, [getHospital]);

  // 병원 시술 호출
  useEffect(() => {
    getHospitalsTreats()
      .then().catch();
  }, [getHospitalsTreats]);

  // 수정 시 불러온 데이터 적용
  useEffect(() => {
    setDoctorData();
  }, [setDoctorData]);

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/management/doctor">의료진 관리</Breadcrumb.Item>
        <Breadcrumb.Item href="">의료진 {pathname.includes('write') ? '추가' : '편집'}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeading>의료진 {pathname.includes('write') ? '추가' : '편집'}</PageHeading>
      <StyledLayout>
        <form onSubmit={onClickSubmit}>
          <StyledTabs type="card" defaultActiveKey="1" onChange={key => onRegionChange(key)}>
            <TabPane tab="한국어" key="1">
              <Section>
                <ContentHeading isRequired>의료진 기본 정보</ContentHeading>
                <LabelText text="병원명">{hospital?.hospitalName}</LabelText>
                <LabelInput
                  text="의사 성명"
                  placeholder="성명을 입력해주세요."
                  value={name}
                  onChange={onChangeName}
                  isRequired
                  isRequiredEmpty={isEmptyName}
                  maxLength={200}
                />
                <LabelSelect
                  text="직위"
                  value={position === '' ? '선택' : position}
                  onChange={onChangePosition}
                  isRequired
                  optionList={optionList}
                  isRequiredEmpty={isEmptyPosition}
                />
                <LabelInput
                  text="한줄 소개"
                  placeholder="한줄 소개를 입력해주세요."
                  value={intro}
                  onChange={onChangeIntro}
                  isRequired
                  isRequiredEmpty={isEmptyIntro}
                  maxLength={200}
                />
                <ImageWrapper>
                  <Label text="대표 사진" isRequired />
                  <AddImageButton
                    url={representative}
                    onChange={onChangeRepresentativeFile}
                    isRequiredEmpty={isEmptyRepresentativeFile}
                  />
                </ImageWrapper>
              </Section>
              <Divider />
              <Section>
                <ContentHeading>의료진 소개</ContentHeading>
                <TableHeading hasButton="추가" onClick={showAddDoctorPopup}>
                  소개 사진(선택)
                </TableHeading>
                <TableDefault dataSource={images} columns={doctorColumns} />
              </Section>
              <Section>
                <TableHeading hasButton="추가" onClick={showAddPopup}>
                  진료 시술(선택)
                </TableHeading>
                <TableDefault columns={treatColumns} dataSource={treats} />
              </Section>
              <Divider />
              <ButtonWrapper>
                <Button type="button" isBig onClick={() => onReset()}>
                  취소
                </Button>
                <Button type="submit" isFilled isBig>
                  저장
                </Button>
              </ButtonWrapper>
            </TabPane>
            <TabPane tab="영어" key="2">
              <DoctorEditTranslate
                display={true}
                hospitalName={hospital?.hospitalName}
                name={name}
                position={position}
                intro={intro}
                representative={representative}
                doctor={images}
                doctorColumns={doctorColumns}
                treat={treats}
                treatColumns={treatColumns}
              />
            </TabPane>
            <TabPane tab="중국어" key="3">
              <DoctorEditTranslate
                display={true}
                hospitalName={hospital?.hospitalName}
                name={name}
                position={position}
                intro={intro}
                representative={representative}
                doctor={images}
                doctorColumns={doctorColumns}
                treat={treats}
                treatColumns={treatColumns}
              />
            </TabPane>
          </StyledTabs>
        </form>
      </StyledLayout>
      {/* 11 팝업 */}
      {isAddDoctorVisible && (
        <DefaultPopup
          title={editIndex !== undefined ? '수정' : '추가'}
          onClickOk={onSaveIntroImage}
          isPopupVisible={isAddDoctorVisible}
          setIsPopupVisible={setIsAddDoctorVisible}
          okWithoutClose
          isWithCancel
          isWithSave
          setIsConfirmVisible={(visible: boolean) => {}}
          setIsEditVisible={(visible: boolean) => {}}
        >
          <ImageWrapper>
            <Label text="사진" isRequired isPopup />
            <AddImageButton
              url={editIndex !== undefined ? images[editIndex]?.imageUrl : undefined}
              onChange={onChangeIntroduceFile}
              isRequiredEmpty={isEmptyIntroduceFile}
            />
          </ImageWrapper>
        </DefaultPopup>
      )}
      {/* 14-2 팝업 */}
      {isAddPopupVisible && (
        <DefaultPopup
          title="추가"
          onClickOk={onSaveTreats}
          isPopupVisible={isAddPopupVisible}
          setIsPopupVisible={setIsAddPopupVisible}
          setIsConfirmVisible={(visible: boolean) => {}}
          setIsEditVisible={(visible: boolean) => {}}
          isWithCancel
          isWithSave
        >
          <PopupSubHeading>진료 시술</PopupSubHeading>
          <TableSort columns={popupColumns} data={hospitalsTreats} scroll={{ y: 275 }} rowSelection={rowSelection} />
        </DefaultPopup>
      )}
      {/* 16-1 팝업 */}
      {/* {isCancelPopupVisible && (
				<ConfirmPopup isPopupVisible={isCancelPopupVisible} setIsPopupVisible={setIsCancelPopupVisible} isWithCancel>
					저장되지 않은 변경 내용이 있습니다. <br />
					저장하지 않고 나가려면 확인을 눌러주세요.
				</ConfirmPopup>
			)} */}
      {/* 17-1 팝업 */}
      {/* {isSavePopupVisible && (
				<ConfirmPopup isPopupVisible={isSavePopupVisible} setIsPopupVisible={setIsSavePopupVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)} */}
      {/* 17-2 팝업 */}
      {/* {isSavePopupVisible && (
				<ConfirmPopup isPopupVisible={isSavePopupVisible} setIsPopupVisible={setIsSavePopupVisible} isWithCancel>
					변경한 내용을 저장할까요? <br />
					확인을 누르면 앱에 바로 반영됩니다.
				</ConfirmPopup>
			)} */}
      {/* 17-3 팝업 */}
      {/* {isSavePopupVisible && (
				<ConfirmPopup isPopupVisible={isSavePopupVisible} setIsPopupVisible={setIsSavePopupVisible}>
					변경 사항이 없습니다
				</ConfirmPopup>
			)} */}
      {/* 15-5 팝업 */}
      {/* {isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제 하시겠습니까?
				</ConfirmPopup>
			)} */}
      {isConfirmPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmPopupVisible}
          setIsPopupVisible={setIsConfirmPopupVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default DoctorEdit;
