import { Layout } from 'antd';
import { useCallback, useState } from 'react';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import RocketChat from './RocketChat';
import { StyledLayout } from './styles';

const Consultation = () => {
  const [newVisible, setNewVisible] = useState<boolean>(false);

  const setIsNewVisible = (visible: boolean) => {
    setNewVisible(visible);
  };

  return (
    <>
      <Layout>
        <GNB />
        <Layout>
          <LNB defaultMenu={['7']} defaultOpenKeys={['7']} isNewVisible={newVisible} />
          <StyledLayout>
            <RocketChat isNewVisible={setIsNewVisible} />
          </StyledLayout>
        </Layout>
      </Layout>
    </>
  );
};

export default Consultation;
