import React, { ChangeEventHandler } from "react";
import Label from "../Label";
import { FlexWrapper, TextareaWrapper, Textarea, HelperText, MaxLength } from "./styles";

interface LabelTextAreaProps {
	isRequired?: boolean;
	text: string;
	placeholder?: string;
	value?: any;
	onChange?: ChangeEventHandler;
	maxLength?: number;
	isRequiredEmpty?: boolean;
	isPopup?: boolean;
}

const LabelTextarea = (props: LabelTextAreaProps) => {
	return (
		<FlexWrapper>
			<Label text={props.text} isRequired={props.isRequired} isPopup={props.isPopup} />
			<TextareaWrapper>
				<Textarea 
					placeholder={props.placeholder} 
					value={props.value} 
					onChange={props.onChange} 
					isPopup={props.isPopup} 
					maxLength={props.maxLength} 
					isRequiredEmpty={props.isRequiredEmpty} 
				/>
				{props.isRequiredEmpty && <HelperText>필수 입력 값입니다.</HelperText>}
				{props.maxLength && (
					<MaxLength isPopup={props.isPopup}>
						{props.value?.length} / <span>{props.maxLength}자 </span>
					</MaxLength>
				)}
			</TextareaWrapper>
		</FlexWrapper>
	);
};

export default React.memo(LabelTextarea);
