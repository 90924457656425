import { Card, Popover } from 'antd';
import styled, { css } from 'styled-components';
import { flexbox, flexboxColumn, fontStyle12 } from '../../styles/mixins';

type TagStatus = string | '예약' | '취소' | '완료' | '노쇼' | '미처리';

const statusCollections: any = {
  예약: '#f5f5f5',
  취소: '#FFC5C5',
  완료: '#A7FF7D',
  노쇼: '#D2F0FF',
  미처리: 'rgba(84, 69, 255, 0.3)',
};

const whatStatus = (status: TagStatus) => {
  return statusCollections[status];
};

export const InnerCellCardWrapper = styled.div``;

export const StyledCard = styled(Card)<{ isNew?: boolean }>`
  ${flexbox('center', 'center')}
  position: relative;
  width: 100%;

  .ant-card-body {
    width: 100%;
    /* padding: 4px 12px 4px 24px; */
    padding: 16px 0;
  }

  ${props =>
    props.isNew &&
    css`
      &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid black;
        border-top-left-radius: 1px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    `}
`;

// 상태값 받아서 색상 변경
export const Status = styled.div<{ status: TagStatus }>`
  width: 12px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ status }) => whatStatus(status)};
  opacity: 0.5;
`;

export const DataWrapper = styled.div`
  ${flexboxColumn('center', 'center')}
  width: 100%;
`;

export const StyledUser = styled.p`
  ${fontStyle12}
  font-weight: 900;
  margin-bottom: 0;
`;

export const StyledTreat = styled.p`
  ${fontStyle12}
`;

export const ChangeStatusPopover = styled(Popover)`
  border: 1px solid ${props => props.theme.inputBorder};
  transition: all 200ms ease-in-out;
`;

export const DisplayButton = styled.button<{ isLast?: boolean }>`
  ${flexboxColumn};
  position: absolute;
  background-color: ${props => props.theme.white};
  z-index: 999;

  ${props =>
    props.isLast
      ? css`
          top: 80%;
          right: 0;
        `
      : css`
          top: 50%;
          right: -30%;
        `}
`;

export const ChangeStatusButton = styled.button`
  display: block;
  width: 100%;
  transition: background-color 200ms ease-in-out;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
  }
  span {
    padding: 4px 20px;
    display: block;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.inputBorder};
  }
`;
