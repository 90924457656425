import styled from 'styled-components';
import {
  flexbox,
  flexboxColumn,
  fontStyle12,
  fontStyle14,
  fontStyle16,
  fontStyle18,
  fontStyle20,
} from '../../styles/mixins';

export const StatusCard = styled.div`
  width: 410px;
  border-radius: 10px;
  background-color: ${props => props.theme.white};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px 30px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background-color: ${props => props.theme.divider_bg};
`;

export const StatusSection = styled.section`
  margin-bottom: 16px;
`;

export const Texts = styled.div`
  h2 {
    ${fontStyle20};
    font-weight: 700;
  }

  span {
    ${fontStyle14};
    display: block;
    margin-bottom: 12px;
  }

  p {
    ${fontStyle12}
  }
`;

export const InfoSection = styled.section``;

export const Infos = styled.div`
  h3 {
    ${fontStyle18}
    font-weight: 600;
  }

  span {
    display: block;
    strong {
      ${fontStyle16}
      font-weight: 800;
    }
  }
  margin-bottom: 24px;
`;

export const Details = styled.div`
  h4 {
    ${fontStyle14}
    font-weight: 600;
    margin-bottom: 8px;
  }

  div {
    margin-bottom: 4px;
  }
`;

export const StatusWrapper = styled.ul`
  ${flexbox('space-between', 'center')};
  gap: 2px;
  margin-bottom: 20px;
`;

export const StatusItem = styled.li<{ isActive?: boolean; isReminder?: boolean }>`
  ${flexboxColumn('center', 'center')}
  gap: 4px;
  width: 100%;

  div {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 100%;
    }
  }
  span {
    ${fontStyle14}
    display: block;
    color: ${props => (props.isActive ? props.theme.accentLight : 'rgba(0, 0, 0, 0.4) ')};
  }
  img {
    display: block;
    width: 100%;
  }
`;

export const StatusDivider = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${props => props.theme.chatGrey};
  position: relative;
  bottom: 12px;
`;
