import styled from "styled-components";
import { flexbox, fontStyle18 } from "../../../styles/mixins";

export const StyledLayout = styled.div`
  @media (min-width: 821px) {
    margin: 35px 30px 50px;
  }

  @media screen and (min-width: 1025px) {
    margin: 35px 0 50px;
  }
`;

export const FlexWrapper = styled.div`
  ${flexbox("space-between", "flex-end")};
  margin: 0 0 10px 0;

  span {
    font-size: 15px;
    line-height: 1.5em;
  }

  strong {
    ${fontStyle18};
    font-weight: 700;
  }

  .ant-select {
    margin: 0;
  }

  .ant-select-arrow {
    top: 61%;
  }
`;

export const Stars = styled.div`
  .ant-rate {
    font-size: 15px;
  }

  .ant-rate-star:not(:last-child) {
    margin: 0 1px 0 0;
  }

  .ant-rate-star:last-child {
    margin: 0 10px 0 0;
  }
`;

export const StatusText = styled.div`
  ${flexbox("center", "center")};
  height: 32px;
  padding: 10px;
  border-radius: 4px;
  background-color: #90c8ac;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;

export const StatusButton = styled.button`
  ${flexbox("center", "center")};
  height: 32px;
  padding: 10px;
  border-radius: 4px;
  background-color: #fd5d5d;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;

export const PopupSubHeading = styled.h2`
  ${fontStyle18};
  font-weight: 500;
  margin: 0 0 18px 0;
`;
