import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { StatusBadge } from '../../../../components';
import { CalendarContainer, Days, Header, StatusWrapper, Week, Weekdays } from './styles';
import CalendarCell from './CalendarCell';
import CalendarHeader from './CalendarHeader';
import DailyView from './DailyView';
import { useApi } from '../../../../contexts/ApiContext';
import { AppointmentDailyStatus } from '../../../../model';

const statusBadges: string[] = ['예약', '취소', '완료', '노쇼', '미처리', '신규', '휴진'];

interface CalendarProps {
  onChangeView?: any;
  view?: any;
  optionList?: any;
  onSelectedDate?: any;
  onDoubleClickDate?: any;
  onChangeMonth: any;
  currentDate: any;
}

const Calendar = ({ onChangeView, view, optionList, onSelectedDate, onDoubleClickDate, onChangeMonth,
                    currentDate }: CalendarProps) => {
  const api = useApi();

  const [dailyStatues, setDailyStatuses] = useState<AppointmentDailyStatus>();
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYYMMDD'));

  const weekdays = moment.weekdays();

  const date = moment(currentDate);
  const year = date.year();
  const month = date.month();
  const daysInMonth = date.daysInMonth();
  const firstDayOfMonth = date.clone().startOf('month').day();

  const weeks = [];
  let week = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    week.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    week.push(i);
    if (week.length === 7) {
      weeks.push(week);
      week = [];
    }
  }
  if (week.length > 0) {
    for (let i = week.length; i < 7; i++) {
      week.push(null);
    }
    weeks.push(week);
  }

  const handleClickDate = (year: any, month: any, day: any) => {
    const stringId = [year, month, day].join('');
    setSelectedDate(stringId);

    const selectedDate = currentDate.clone().startOf('month').add(day-1, "days").format('YYYY-MM-DD');
    onSelectedDate(selectedDate);
  };

  const handleDoubleClickDate = (year: any, month: any, day: any) => {
    const selectedDate = currentDate.clone().startOf('month').add(day-1, "days").format('YYYY-MM-DD');
    onDoubleClickDate(selectedDate);
  }

  const loadAppointmentStatus = useCallback(async () => {
    const currentMonth = moment(currentDate).format(`YYYYMM`);
    const statuses = (await api.appointment.getDailyStatus(currentMonth)).data.payload;

    setDailyStatuses(statuses.dailyStatus)
  }, [api.appointment, currentDate]);

  const dailyStatusParsingToArray = (statusIndex: string) => {
    if(dailyStatues === undefined) {
      return ;
    }
    const statuses = [];
    const statusItem = dailyStatues[statusIndex];
    if(statusItem !== undefined && statusItem.appointed > 0) {
      statuses.push({ status: '예약', totalNum: statusItem.appointed })
    }
    if(statusItem !== undefined && statusItem.canceled > 0) {
      statuses.push({ status: '취소', totalNum: statusItem.canceled })
    }
    if(statusItem !== undefined && statusItem.noShow > 0) {
      statuses.push({ status: '노쇼', totalNum: statusItem.noShow })
    }
    if(statusItem !== undefined && statusItem.visited > 0) {
      statuses.push({ status: '완료', totalNum: statusItem.visited })
    }
    if(statusItem !== undefined && statusItem.unprocessed > 0) {
      statuses.push({ status: '미처리', totalNum: statusItem.unprocessed })
    }

    return statuses;
  }

  useEffect(() => {
    loadAppointmentStatus().then().catch();
  }, [loadAppointmentStatus]);

  return (
    <CalendarContainer>
      <Header>
        <CalendarHeader
          changeMonth={onChangeMonth}
          date={moment(currentDate, 'YYYY년 MM월 DD일 (dd)')}
          optionList={optionList}
          view={view}
          onChangeView={onChangeView}
        />
        <StatusWrapper>
          {statusBadges.map((badge: string, i: number) => (
            <StatusBadge key={i} status={badge} />
          ))}
        </StatusWrapper>
      </Header>

      {view === '월별보기' ? (
        <>
          <Weekdays>
            {weekdays.map(day => (
              <div key={day}>{day}</div>
            ))}
          </Weekdays>
          <Days>
            {weeks.map((week, index) => (
              <Week key={index}>
                {week.map((day, index) => {
                  const id = [year, month, day].join('');
                  const statusIndex = date.clone()
                    .startOf('month').add(day ? day-1:day, "days")
                    .format('YYYY-MM-DD');

                  return (
                    <CalendarCell
                      key={index}
                      id={id}
                      handleClickDate={handleClickDate}
                      handleDoubleClickDate={handleDoubleClickDate}
                      isSelected={id === selectedDate}
                      year={year}
                      month={month}
                      day={day}
                      isClosed={dailyStatues ?
                        dailyStatues[statusIndex] ? dailyStatues[statusIndex].closed : false : false}
                      totalStatus={dailyStatues ?
                        dailyStatues[statusIndex] ? dailyStatues[statusIndex].total : 0 : 0}
                      data={day && dailyStatusParsingToArray(statusIndex)}
                    />
                  );
                })}
              </Week>
            ))}
          </Days>
        </>
      ) : (
        <DailyView currentDate={currentDate}/>
      )}
    </CalendarContainer>
  );
};

export default Calendar;
