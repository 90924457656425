import { ImageWrapper, Section } from '../styles';
import {
  AddImageButton,
  ContentHeading,
  Divider,
  SubDivider,
  Label,
  LabelRadioTime,
  LabelText,
  LabelToggle,
} from '../../../../components';

interface HospitalTranslateProps {
  display?: boolean;
  hospitalName?: string;
  departments?: any;
  treatmentCategories?: any;
  tel: string;
  address: string;
  map?: string;
  intro: string;
  introLong: string;
  representative?: string;
  weekdays?: any;
  weekend?: any;
  breaktime?: any;
  significant?: string;
}

const HospitalTranslate = (props: HospitalTranslateProps) => {
  return (
    <>
      <Section>
        <ContentHeading isRequired isHelperText>
          기본 정보
        </ContentHeading>
        <LabelToggle text="노출" checked={props.display} />
        <LabelText text="병원명">{props.hospitalName}</LabelText>
        <LabelText text="진료과" nodata={props.departments.length <= 0}>
          {props.departments.length <= 0
            ? '등록된 진료과가 없습니다. 시술을 등록해주세요.'
            : props.departments.join(', ')}
        </LabelText>
        <LabelText text="시술 카테고리" nodata={props.treatmentCategories.length <= 0}>
          {props.treatmentCategories.length <= 0
            ? '등록된 시술이 없습니다. 시술을 등록해주세요.'
            : props.treatmentCategories.join(', ')}
        </LabelText>
        <LabelText text="대표전화">{props.tel}</LabelText>
        <LabelText text="주소" isRequired>
          {props.address}
        </LabelText>
        <ImageWrapper>
          <Label text="주소 지도 사진" isRequired />
          <AddImageButton url={props.map} isDisabled />
        </ImageWrapper>
      </Section>
      <Divider />
      <Section>
        <ContentHeading isRequired>병원 소개</ContentHeading>
        <LabelText text="한줄 소개" isRequired>
          {props.intro}
        </LabelText>
        <LabelText text="소개 내용" isRequired>
          {props.introLong}
        </LabelText>
        <ImageWrapper>
          <Label text="대표 사진" isRequired />
          <AddImageButton url={props.representative} isDisabled />
        </ImageWrapper>
      </Section>
      <Divider />
      <Section>
        <ContentHeading isRequired>진료 시간</ContentHeading>
        {props.weekdays.map((item: any, i: any) => (
          <LabelRadioTime
            key={i}
            text={item.dayOfWeek}
            value={item.treatment}
            start={item.opensAt}
            end={item.closesAt}
            isRequired
            disabled
          />
        ))}
        <SubDivider />
        {props.weekend.map((item: any, i: any) => (
          <LabelRadioTime
            key={i + 5}
            text={item.dayOfWeek}
            value={item.treatment}
            start={item.opensAt}
            end={item.closesAt}
            isRequired
            disabled
          />
        ))}
        <SubDivider />
        {props.breaktime.map((item: any, i: any) => (
          <LabelRadioTime
            key={i + 7}
            text={item.dayOfWeek}
            value={item.treatment}
            start={item.opensAt}
            end={item.closesAt}
            isBreaktime
            disabled
          />
        ))}
        <SubDivider />
        <LabelText text="진료특이사항(선택)">{props.significant}</LabelText>
      </Section>
    </>
  );
};

export default HospitalTranslate;
