import moment from 'moment';
import { StatusBadge } from '../../../../../components';
import { CalendarBadgeWrapper, Day, Total } from './styles';

interface DateProps {
  id?: string;
  day?: any;
  year?: any;
  month?: any;
  data?: any;
  totalStatus?: number;
  isSide?: boolean;
  handleClickDate?: any;
  handleDoubleClickDate?: any;
  isToday?: boolean;
  isSelected?: boolean;
  isNone?: any;
  isClosed?: any;
}

const CalendarCell = ({ id, day, year, month, data, totalStatus, handleClickDate, handleDoubleClickDate,
                        isToday, isSelected, isClosed }: DateProps) => {

  return (
    <Day
      onClick={() => handleClickDate(year, month, day)}
      onDoubleClick={() => handleDoubleClickDate(year, month, day)}
      empty={day === null}
      today={day === moment().date() && year === moment().year() && month === moment().month()}
      isSelected={isSelected}
      isToday={isToday}
      isOffed={isClosed}
    >
      <h3>{day}</h3>
      {data && (
        <Total>
          <strong>총</strong> {totalStatus}건
        </Total>
      )}
      <CalendarBadgeWrapper>
        {data
          ? data.map((item: any, i: number) => (
              <StatusBadge key={i} status={item.status} totalNum={item.totalNum} calendarMode />
            ))
          : null}
      </CalendarBadgeWrapper>
    </Day>
  );
};

export default CalendarCell;
