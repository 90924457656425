import { useCallback, useEffect, useState } from 'react';
import { Card, ContentHeading, DefaultPopup, PageHeading, Select, StatusBadge, SubDivider } from '../../../components';
import { SyncOutlined } from '@ant-design/icons';
import Calendar from './Calendar';
import {
  CalendarSection,
  CardSection,
  RefreshButton,
  ReservationDataWrapper,
  ReservationDetailWrapper,
  ReservationHistoryWrapper,
  StyledLayout,
  Total,
} from './styles';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import moment from 'moment';
import 'moment/locale/ko';

import generateCalendar from 'antd/es/calendar/generateCalendar';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { useApi } from '../../../contexts/ApiContext';
import {
  AppointmentHistoryFilter,
  AppointmentListItem,
  DailyAppointmentPayloadHistories,
  DailyAppointmentPayloadStatus,
} from '../../../model';
import UserRezInfoTable from '../RezInquiry/UserRezInfoTable';
import UserRezPaymentTable from '../RezInquiry/UserRezPaymentTable';
import UserRezHistoryTable from '../RezInquiry/UserRezHistoryTable';

const SubCalendar = generateCalendar<Moment>(momentGenerateConfig);

const RezStatus = () => {
  const api = useApi();

  const [view, setView] = useState('월별보기');
  const [currentDate, setCurrentDate] = useState(() => moment());
  const [historiesFilter, setHistoriesFilter] = useState<string>('ALL');
  const [appointmentStatus, setAppointmentStatus] = useState<DailyAppointmentPayloadStatus>();
  const [statuses, setStatuses] = useState<any[]>();
  const [appointmentHistories, setAppointmentHistories] = useState<DailyAppointmentPayloadHistories[]>();
  const onChangeView = (value: any) => {
    setView(value);
  };
  const optionList: any = ['월별보기', '일별보기'];

  const onChangeFilter = (value: any) => {
    setHistoriesFilter(value);
  };
  const filterOption = [
    { value: AppointmentHistoryFilter.ALL, text: '전체' },
    { value: AppointmentHistoryFilter.APPOINTED, text: '예약' },
    { value: AppointmentHistoryFilter.VISITED, text: '완료' },
    { value: AppointmentHistoryFilter.CANCELED, text: '취소' },
    { value: AppointmentHistoryFilter.NO_SHOW, text: '노쇼' },
  ];

  const onSubCalendarDateChange = (date: any) => {
    const selectedDate = moment(date);
    setCurrentDate(selectedDate);
  };

  const [isReloading, setIsReloading] = useState(false);
  const handleReloadPage = () => {
    setIsReloading(true);
    window.location.replace('/reservation/status');
  };
  const onSelectedDate = (date: string) => {
    const selectedDate = moment(date);
    setCurrentDate(selectedDate);
  };

  const loadDailyAppointmentHistories = useCallback(async () => {
    const targetDate = moment(currentDate).format('YYYYMMDD');
    const appointments = (await api.appointment.getDailyAppointmentHistories(targetDate, historiesFilter)).data.payload;

    setAppointmentStatus(appointments.status);
    setAppointmentHistories(appointments.histories);
    createStatuses(appointments.status);
  }, [api.appointment, currentDate, historiesFilter]);

  const createStatuses = (status: DailyAppointmentPayloadStatus) => {
    const statuses = [];
    statuses.push({ status: '예약', totalNum: status.appointed });
    statuses.push({ status: '취소', totalNum: status.canceled });
    statuses.push({ status: '완료', totalNum: status.visited });
    statuses.push({ status: '노쇼', totalNum: status.noShow });

    setStatuses(statuses);
  };

  const onDoubleClickDate = (doubleClickDate: string) => {
    setCurrentDate(moment(doubleClickDate));
    setView('일별보기');
  };

  const onChangeMonth = useCallback(
    (date: any, changeType: string) => {
      switch (changeType) {
        case 'ADD_MONTH':
          const addMonthChangedMonth = moment(currentDate).clone().add(1, 'month');
          return setCurrentDate(addMonthChangedMonth);
        case 'SUBTRACT_MONTH':
          const subChangedMonth = moment(currentDate).clone().subtract(1, 'month');
          return setCurrentDate(subChangedMonth);
        case 'ADD_DATE':
          const addChangedDate = moment(currentDate).clone().add(1, 'day');
          return setCurrentDate(addChangedDate);
        case 'SUBTRACT_DATE':
          const subChangedDate = moment(currentDate).clone().subtract(1, 'day');
          return setCurrentDate(subChangedDate);
        default:
          return currentDate;
      }
    },
    [currentDate],
  );

  const statusToText = (status: string) => {
    if (status === 'APPOINTED') {
      return '예약';
    }
    if (status === 'CANCELED') {
      return '취소';
    }
    if (status === 'VISITED') {
      return '완료';
    }
    if (status === 'NO_SHOW') {
      return '노쇼';
    }
    return '';
  };

  useEffect(() => {
    loadDailyAppointmentHistories().then().catch();
  }, [loadDailyAppointmentHistories]);

  const [isDetailPopupVisible, setIsDetailPopupVisible] = useState(false);
  const [detailAppointmentItem, setDetailAppointmentItem] = useState<AppointmentListItem>();
  const onClickDetail = (item: AppointmentListItem) => {
    setDetailAppointmentItem(item);
    setIsDetailPopupVisible(true);
  };

  return (
    <>
      <PageHeading>예약 현황</PageHeading>
      <RefreshButton onClick={handleReloadPage}>
        {moment().format('YYYY.MM.DD hh:mm:ss')} <SyncOutlined spin={isReloading} />
      </RefreshButton>
      <StyledLayout>
        <CalendarSection>
          <Calendar
            view={view}
            currentDate={currentDate}
            onChangeView={onChangeView}
            onSelectedDate={onSelectedDate}
            onDoubleClickDate={onDoubleClickDate}
            onChangeMonth={onChangeMonth}
            optionList={optionList}
          />
        </CalendarSection>
        <ReservationHistoryWrapper>
          {view === '일별보기' && (
            <>
              <SubCalendar
                locale={locale}
                fullscreen={false}
                value={currentDate}
                onSelect={onSubCalendarDateChange}
                headerRender={({ value, onChange }) => {
                  return (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginBottom: 10 }}>
                      <button
                        onClick={() => {
                          const prev = value.clone().subtract(1, 'month');
                          onChange(prev);
                        }}
                      >
                        <LeftOutlined />
                      </button>
                      <Typography.Title level={4}>{value.format('YYYY년 MM월')}</Typography.Title>
                      <button
                        onClick={() => {
                          const next = value.clone().add(1, 'month');
                          onChange(next);
                        }}
                      >
                        <RightOutlined />
                      </button>
                    </div>
                  );
                }}
              />
            </>
          )}
          <ContentHeading>예약 내역</ContentHeading>
          <ReservationDataWrapper>
            <ReservationDetailWrapper>
              <h3>{moment(currentDate).format('YYYY년 MM월 DD일 (dd)')}</h3>
              <Total>
                총 : <strong>{appointmentStatus?.totalElements}</strong> 건
              </Total>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {statuses?.map((item, index) => (
                  <StatusBadge key={index} status={item.status} totalNum={item.totalNum} />
                ))}
              </div>
            </ReservationDetailWrapper>
            <Select
              value={historiesFilter}
              optionList={filterOption}
              onChange={onChangeFilter}
              style={{ width: 100 }}
            />
          </ReservationDataWrapper>
          <CardSection view={view}>
            {appointmentHistories?.map((item: any) => (
              <Card
                key={item.appointmentId}
                isNew={item.newer}
                status={statusToText(item.status)}
                userName={item.appointeeName + item.appointeeNameEn ? ' ' + item.appointeeName : ''}
                doctorName={item.doctorName}
                treatName={item.treatmentName}
                visitDate={item.upcomingDate + ' ' + item.upcomingTime}
              >
                <div onClick={() => onClickDetail(item)}>
                  <p>성명 : {item.appointeeName + item.appointeeNameEn ? ' ' + item.appointeeName : ''}</p>
                  <p>
                    의사 : {item.doctorName} / {item.treatmentName}
                  </p>
                  <p>방문 시간 : {item.upcomingDate + ' ' + item.upcomingTime}</p>
                </div>
              </Card>
            ))}
          </CardSection>
        </ReservationHistoryWrapper>
        {isDetailPopupVisible && (
          <DefaultPopup
            title="상세보기"
            isPopupVisible={isDetailPopupVisible}
            setIsPopupVisible={setIsDetailPopupVisible}
            isWide
            isWithOkay
          >
            <UserRezInfoTable item={detailAppointmentItem} refresh={''} />
            <SubDivider />
            <UserRezPaymentTable item={detailAppointmentItem} />
            <SubDivider />
            <UserRezHistoryTable appointeeId={detailAppointmentItem?.appointeeId} />
          </DefaultPopup>
        )}
      </StyledLayout>
    </>
  );
};

export default RezStatus;
