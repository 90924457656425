import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import PageHeading from '../../../../components/PageHeading';
import ContentHeading from '../../../..//components/ContentHeading';
import LabelText from '../../../../components/Inputs/LabelText';
import LabelInput from '../../../../components/Inputs/LabelInput';
import LabelTextarea from '../../../../components/Inputs/LabelTextarea';
import Label from '../../../../components/Inputs/Label';
import AddImageButton from '../../../../components/AddImageButton';
import LabelSelect from '../../../../components/Inputs/LabelSelect';
import DefaultImage from '../../../../components/DefaultImage';
import TableHeading from '../../../../components/TableHeading';
import TableDefault from '../../../../components/Tables/TableDefault';
import InnerTdButton from '../../../../components/InnerTdButton';
import UpDownButton from '../../../../components/UpDownButton';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../../../components/Popup/DefaultPopup';
import { Divider } from '../../../../components';
import { ItemDefault } from '../../../../components/Inputs/Select';
import { ColumnsType } from 'antd/lib/table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  BeforeAndAfterTreatmentItem,
  HospitalPartnersDetail,
  ShiftDirection,
  CreateTreatmentRequest,
  DepartmentItem,
  TreatmentCategoryItem,
  TreatmentAdvantageAndEffectItem,
  TreatmentPartnersDetails,
  TreatmentFaqItem,
  TreatmentProcedureItem,
  TreatmentRepresentative,
  ModifyTreatmentRequest,
} from '../../../../model';
import {
  BreadWrapper,
  StyledTabs,
  Layout,
  ImageWrapper,
  DetailImageWrapper,
  ButtonWrapper,
  Button,
  Section,
  HelperText,
  LabelImageWrapper,
  PopupImageWrapper,
  FlexWrapper,
  ImageTextWrapper,
  StyledSubTabs,
} from './styles';
import { useApi } from '../../../../contexts/ApiContext';
import Image from '../../../../components/Image';
import { fileToDataUri } from '../../../../helpers/FileHelper';
import TreatBasicTranslate from './Tabs/TreatBasicTranslate';
import TreatDetailTranslate from './Tabs/TreatDetailTranslate';

type URLParams = {
  id?: string;
};

const popupText = {
  delete: <>삭제 하시겠습니까?</>,
  validation: <>필수 항목을 다시 확인해 주세요.</>,
  unsave: (
    <>
      저장되지 않은 변경 내용이 있습니다. <br />
      저장하지 않고 나가려면 확인을 눌러주세요.
    </>
  ),
  save: (
    <>
      입력한 내용을 저장할까요? <br />
      확인을 누르면 앱에 바로 반영됩니다.
    </>
  ),
  unchanged: (
    <>
      변경 사항이 없습니다.
    </>
  ),
};

const TreatEdit = () => {
  const api = useApi();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { id } = useParams<URLParams>();

  const { TabPane } = Tabs;
  // 현재 탭 키
  const [region, setRegion] = useState<string>('ko');
  const [activeKey, setActiveKey] = useState('1');
  const [changedRegion, setChangedRegion] = useState<boolean>(false);
  const [isNotModified, setIsNotModified] = useState<boolean>(true);
  const onKeyChange = (key: string) => setActiveKey(key);

  // 현재 서브 탭 키
  const [activeSubTabKey, setActiveSubTabKey] = useState('1');
  const onSubKeyChange = (key: string) => setActiveSubTabKey(key);

  // 병원 정보
  const [hospital, setHospital] = useState<HospitalPartnersDetail>();

  // 진료과 및 시술 카테고리 정보
  const [allDepartments, setAllDepartments] = useState<DepartmentItem[]>([]);
  const [allCategories, setAllCategories] = useState<TreatmentCategoryItem[]>([]);

  // 시술명
  const [treatName, setTreatName] = useState('');
  const [isEmptyTreatName, setIsEmptyTreatName] = useState<boolean>(false);
  const onChangeTreatName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTreatName(e.target.value);
  }, []);

  // 진료과
  const [departments, setDepartments] = useState<ItemDefault[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>('');
  const [isEmptyDepartment, setIsEmptyDepartment] = useState<boolean>(false);
  const onChangeDepartment = useCallback((value: string) => {
    setSelectedDepartmentId(value);
    setSelectedCategoryId('');
  }, []);

  // 시술 카테고리
  const [categories, setCategories] = useState<ItemDefault[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [isEmptyCategory, setIsEmptyCategory] = useState<boolean>(false);
  const onChangeCategory = useCallback((value: string) => {
    setSelectedCategoryId(value);
  }, []);

  // 대표 시술 여부
  const [representativeOptionList] = useState<ItemDefault[]>([
    { value: '', text: '전체' },
    { value: TreatmentRepresentative.NORMAL, text: '일반시술' },
    { value: TreatmentRepresentative.REPRESENTATIVE, text: '대표시술' },
  ]);
  const [enRepresentativeOptionList] = useState<ItemDefault[]>([
    { value: '', text: 'All' },
    { value: TreatmentRepresentative.NORMAL, text: 'General Procedures' },
    { value: TreatmentRepresentative.REPRESENTATIVE, text: 'Representative Procedures' },
  ]);
  const [zhRepresentativeOptionList] = useState<ItemDefault[]>([
    { value: '', text: '全部' },
    { value: TreatmentRepresentative.NORMAL, text: '一般程序' },
    { value: TreatmentRepresentative.REPRESENTATIVE, text: '签名程序' },
  ]);
  const [representative, setRepresentative] = useState<TreatmentRepresentative | string>('');
  const [isEmptyRepresentative, setIsEmptyRepresentative] = useState<boolean>(false);
  const onChangeRepresentativeOption = useCallback((value: string) => {
    setRepresentative(value as TreatmentRepresentative);
  }, []);

  // 가격
  const [cost, setCost] = useState<number>(0);
  const [isEmptyCost, setIsEmptyCost] = useState<boolean>(false);
  const onChangeCost = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const change = Number(e.target.value.replace(/\D/, ''));
    setCost(isNaN(change) ? 0 : change);
  }, []);

  // 가격 옵션
  const [costOption, setCostOption] = useState('');
  const onChangeCostOption = (e: ChangeEvent<HTMLInputElement>) => setCostOption(e.target.value);

  // 시술 소개
  const [treatIntro, setTreatIntro] = useState('');
  const [isEmptyIntro, setIsEmptyIntro] = useState<boolean>(false);
  const onChangeTreatIntro = (e: ChangeEvent<HTMLInputElement>) => setTreatIntro(e.target.value);

  // 시술 이미지형 사진
  const [image, setImage] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();
  const [isEmptyImageFile, setIsEmptyImageFile] = useState(false);
  const onChangeImageFile = (file?: File) => {
    setImage(undefined);
    setImageFile(file);
  };

  // 시술 텍스트형 사진
  const [textImage, setTextImage] = useState<string>();
  const [textImageFile, setTextImageFile] = useState<File>();
  const [isEmptyTextImageFile, setIsEmptyTextImageFile] = useState(false);
  const onChangeTextImageFile = (file?: File) => {
    setTextImage(undefined);
    setTextImageFile(file);
  };
  // TreatBasic end

  // TreatDetail state start
  // 시술 설명1
  const [firstExplain, setFirstExplain] = useState<string>('');
  const [isEmptyFirstExplain, setIsEmptyFirstExplain] = useState<boolean>(false);
  const onChangeFirstExplain = (e: ChangeEvent<HTMLInputElement>) => setFirstExplain(e.target.value);

  // 시술 설명2
  const [secondExplain, setSecondExplain] = useState<string>('');
  const [isEmptySecondExplain, setIsEmptySecondExplain] = useState<boolean>(false);
  const onChangeSecondExplain = (e: ChangeEvent<HTMLInputElement>) => setSecondExplain(e.target.value);

  // 시술 설명3
  const [thirdExplain, setThirdExplain] = useState<string>('');
  const [isEmptyThirdExplain, setIsEmptyThirdExplain] = useState<boolean>(false);
  const onChangeThirdExplain = (e: ChangeEvent<HTMLInputElement>) => setThirdExplain(e.target.value);

  // 시술 설명4
  const [fourthExplain, setFourthExplain] = useState<string>('');
  const onChangeFourthExplain = (e: ChangeEvent<HTMLInputElement>) => setFourthExplain(e.target.value);

  // 시술 설명5
  const [fifthExplain, setFifthExplain] = useState<string>('');
  const onChangeFifthExplain = (e: ChangeEvent<HTMLInputElement>) => setFifthExplain(e.target.value);

  // 시술 장점 목록
  const [advantages, setAdvantages] = useState<TreatmentAdvantageAndEffectItem[]>([]);
  const [isNotEnoughAdvantages, setIsNotEnoughAdvantages] = useState<boolean>(false);

  // 시술 장점 추가 팝업 - 파일
  const [advantageFile, setAdvantageFile] = useState<File>();
  const [isEmptyAdvantageFile, setIsEmptyAdvantageFile] = useState(false);
  const onChangeAdvantageFile = (file?: File) => setAdvantageFile(file);

  // 시술 장점 추가 팝업 - 설명
  const [advantage, setAdvantage] = useState<string>('');
  const [isEmptyAdvantage, setIsEmptyAdvantage] = useState<boolean>(false);
  const onChangeAdvantage = (e: ChangeEvent<HTMLInputElement>) => setAdvantage(e.target.value);

  // 시술 장점 수정
  const [editAdvantageIndex, setEditAdvantageIndex] = useState<number>();

  // 시술 장점 팝업
  const [isEffectVisible, setIsEffectVisible] = useState(false);
  const showEffectPopup = () => {
    if (advantages.length < 3) {
      setEditAdvantageIndex(undefined);
      setAdvantageFile(undefined);
      setAdvantage('');
      setIsEffectVisible(true);
    }
  };

  // 시술 과정 목록
  const [procedures, setProcedures] = useState<TreatmentProcedureItem[]>([]);
  const [isNotEnoughProcedures, setIsNotEnoughProcedures] = useState<boolean>(false);

  // 시술 과정 추가 팝업 - 파일
  const [procedureFile, setProcedureFile] = useState<File>();
  const [isEmptyProcedureFile, setIsEmptyProcedureFile] = useState(false);
  const onChangeProcedureFile = (file?: File) => setProcedureFile(file);
  // 시술 과정 추가 팝업 - 설명
  const [procedure, setProcedure] = useState<string>('');
  const [isEmptyProcedure, setIsEmptyProcedure] = useState<boolean>(false);
  const onChangeProcedure = (e: ChangeEvent<HTMLInputElement>) => setProcedure(e.target.value);

  // 시술 과정 수정
  const [editProcedureIndex, setEditProcedureIndex] = useState<number>();

  // 시술 과정 팝업
  const [isProcessVisible, setIsProcessVisible] = useState(false);
  const showProcessPopup = () => {
    if (procedures.length < 8) {
      setEditProcedureIndex(undefined);
      setProcedureFile(undefined);
      setProcedure('');
      setIsProcessVisible(true);
    }
  };

  // 시술 전후 목록
  const [beforeAndAfters, setBeforeAndAfters] = useState<BeforeAndAfterTreatmentItem[]>([]);

  // 시술 전후 추가 팝업 파일
  // - 시술 전 파일
  const [beforeFile, setBeforeFile] = useState<File>();
  const [isEmptyBeforeFile, setIsEmptyBeforeFile] = useState(false);
  const onChangeBeforeFile = (file?: File) => setBeforeFile(file);
  // - 시술 후 파일
  const [afterFile, setAfterFile] = useState<File>();
  const [isEmptyAfterFile, setIsEmptyAfterFile] = useState(false);
  const onChangeAfterFile = (file?: File) => setAfterFile(file);

  // 시술 전후 추가 팝업 - 설명
  const [beforeAndAfter, setBeforeAndAfter] = useState<string>('');
  const [isEmptyBeforeAndAfter, setIsEmptyBeforeAndAfter] = useState<boolean>(false);
  const onChangeBeforeAndAfter = (e: ChangeEvent<HTMLInputElement>) => setBeforeAndAfter(e.target.value);

  // 시술 전후 수정
  const [editBeforeAndAfterIndex, setEditBeforeAndAfterIndex] = useState<number>();

  // 시술 전후 팝업
  const [isBeforeVisible, setIsBeforeVisible] = useState(false);
  const showBeforePopup = () => {
    if (beforeAndAfters.length < 10) {
      setEditBeforeAndAfterIndex(undefined);
      setBeforeFile(undefined);
      setAfterFile(undefined);
      setBeforeAndAfter('');
      setIsBeforeVisible(true);
    }
  };

  // 자주 묻는 질문 목록
  const [faqs, setFaqs] = useState<TreatmentFaqItem[]>([]);
  const [isNotEnoughFaqs, setIsNotEnoughFaqs] = useState<boolean>(false);

  // 자주 묻는 질문 제목
  const [faqTitle, setFaqTitle] = useState<string>('');
  const [isEmptyFaqTitle, setIsEmptyFaqTitle] = useState<boolean>(false);
  const onChangeFaqTitle = (e: ChangeEvent<HTMLInputElement>) => setFaqTitle(e.target.value);

  // 자주 묻는 질문 내용
  const [faqContents, setFaqContents] = useState<string>('');
  const [isEmptyFaqContents, setIsEmptyFaqContents] = useState<boolean>(false);
  const onChangeFaqContents = (e: ChangeEvent<HTMLInputElement>) => setFaqContents(e.target.value);

  // 자주 묻는 수정
  const [editFaqIndex, setEditFaqIndex] = useState<number>();

  const [isFaqVisible, setIsFaqVisible] = useState(false);
  const showFaqPopup = () => {
    if (faqs.length < 6) {
      setEditFaqIndex(undefined);
      setFaqTitle('');
      setFaqContents('');
      setIsFaqVisible(true);
    }
  };

  // TreatDetail state end

  // Confirm Popup
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

  // 수정 시 시술 데이터 호출
  const getTreatments = useCallback(async () => {
    if (!pathname.includes('edit')) {
      try {
        const data = (await api.treatment.getTreatment(id ?? '', region)).data;
        console.log(data);
        setTreatment(data.data);
        setOriginal(data.data);
      } catch (e) {
        navigate(-1);
      }
    }
  }, [api.treatment, id, navigate, pathname, region]);

  // 시술 정보 (수정)
  const [treatment, setTreatment] = useState<TreatmentPartnersDetails>();
  const [original, setOriginal] = useState<TreatmentPartnersDetails>();

  // 병원 데이터 호출
  const getHospital = useCallback(async () => {
    const data = (await api.hospital.getHospital(region)).data;
    setHospital(data);
  }, [api.hospital, region]);

  // 진료과 목록 호출
  const getDepartments = useCallback(async () => {
    const data = (await api.category.getDepartments()).data;

    if (data.result) {
      setAllDepartments(data.result);
    }
  }, [api.category]);

  // 시술 카테고리 호출
  const getCategories = useCallback(async () => {
    if (selectedDepartmentId) {
      const data = (await api.category.getCategories(selectedDepartmentId)).data;
      setAllCategories(data.data);
    }
  }, [api.category, selectedDepartmentId]);

  // 수정 시 시술 데이터 반영
  const setTreatsData = useCallback(() => {
    if (treatment) {
      // 시술 기본 정보
      setTreatName(treatment.treatmentName);
      setSelectedDepartmentId(treatment.departmentId);
      setSelectedCategoryId(treatment.treatmentCategoryId);
      setRepresentative(treatment.representative);
      setCost(treatment.price);
      setCostOption(treatment.priceOption);
      setTreatIntro(treatment.introduction);
      setImage(treatment.imageUrl);
      setTextImage(treatment.textImageUrl);

      // 시술 상세 정보
      setFirstExplain(treatment.description?.[0] ?? '');
      setSecondExplain(treatment.description?.[1] ?? '');
      setThirdExplain(treatment.description?.[2] ?? '');
      setFourthExplain(treatment.description?.[3] ?? '');
      setFifthExplain(treatment.description?.[4] ?? '');
      setAdvantages(treatment.treatmentAdvantagesAndEffects.map((row, index) => ({ key: index + 1, ...row })));
      setProcedures(treatment.treatmentProcedures.map((row, index) => ({ key: index + 1, ...row })));
      setBeforeAndAfters(treatment.beforeAndAfterTreatment?.map((row, index) => ({ key: index + 1, ...row })) ?? []);
      setFaqs(treatment.faq.map((row, index) => ({ key: index + 1, ...row })));
    }
  }, [treatment]);

  // 진료과 목록 select 박스에 맞춰 편집
  const setDepartmentsOptionList = useCallback(() => {
    if (allDepartments?.length > 0) {
      let list: ItemDefault[] = [{ value: '', text: '선택' }];
      list = list.concat(allDepartments.map(row => ({ value: row.id, text: row.name, ...row })));

      setDepartments(list);
    }
  }, [allDepartments]);

  // 시술 카테고리 목록 select 박스에 맞춰 편집
  const setCategoryOptionList = useCallback(() => {
    if (allCategories?.length > 0) {
      let list: ItemDefault[] = [{ value: '', text: '선택' }];
      list = list.concat(allCategories.map(row => ({ value: row.categoryId, text: row.categoryName, ...row })));

      setCategories(list);
    }
  }, [allCategories]);

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk?: VoidFunction,
    handleCancel?: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 시술 기본 정보 유효성 여부
  const validationBasicInfo = useMemo(() => {
    let validation = true;
    if (!treatName) {
      validation = false;
    }

    if (!selectedDepartmentId) {
      validation = false;
    }

    if (!selectedCategoryId) {
      validation = false;
    }

    if (!representative) {
      validation = false;
    }

    if (cost <= 0 || !cost || isNaN(cost)) {
      validation = false;
    }

    if (!treatIntro) {
      validation = false;
    }

    if (!image && !imageFile) {
      validation = false;
    }

    if (!textImage && !textImageFile) {
      validation = false;
    }

    return validation;
  }, [
    cost,
    image,
    imageFile,
    representative,
    selectedCategoryId,
    selectedDepartmentId,
    textImage,
    textImageFile,
    treatIntro,
    treatName,
  ]);

  // 시술 기본 정보 유효성 체크
  const checkValidationBasicInfo = useCallback((): boolean => {
    let validation = true;
    if (!treatName) {
      setIsEmptyTreatName(true);
      validation = false;
    } else {
      setIsEmptyTreatName(false);
    }

    if (!selectedDepartmentId) {
      setIsEmptyDepartment(true);
      validation = false;
    } else {
      setIsEmptyDepartment(false);
    }

    if (!selectedCategoryId) {
      setIsEmptyCategory(true);
      validation = false;
    } else {
      setIsEmptyCategory(false);
    }

    if (!representative) {
      setIsEmptyRepresentative(true);
      validation = false;
    } else {
      setIsEmptyRepresentative(false);
    }

    if (cost <= 0 || !cost || isNaN(cost)) {
      setIsEmptyCost(true);
      validation = false;
    } else {
      setIsEmptyCost(false);
    }

    if (!treatIntro) {
      setIsEmptyIntro(true);
      validation = false;
    } else {
      setIsEmptyIntro(false);
    }

    if (!image && !imageFile) {
      setIsEmptyImageFile(true);
      validation = false;
    } else {
      setIsEmptyImageFile(false);
    }

    if (!textImage && !textImageFile) {
      setIsEmptyTextImageFile(true);
      validation = false;
    } else {
      setIsEmptyTextImageFile(false);
    }

    if (validation) {
      setIsEmptyTreatName(false);
      setIsEmptyDepartment(false);
      setIsEmptyCategory(false);
      setIsEmptyRepresentative(false);
      setIsEmptyCost(false);
      setIsEmptyIntro(false);
      setIsEmptyImageFile(false);
      setIsEmptyTextImageFile(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validationBasicInfo;
  }, [
    cost,
    image,
    imageFile,
    representative,
    selectedCategoryId,
    selectedDepartmentId,
    textImage,
    textImageFile,
    treatIntro,
    treatName,
    validationBasicInfo,
  ]);

  // 파일 저장
  const onSaveFile = useCallback(
    async (file: File): Promise<string | undefined> => {
      const fileReader = await fileToDataUri(file);
      if (fileReader) {
        const data = (await api.file.getPresignedUrl(file.name)).data;
        await api.file.uploadFile(data.signedUrl, file);

        return data.uploadTargetUrl;
      }

      return undefined;
    },
    [api.file],
  );

  const isContentsChanged = useCallback(() => {
    const description: string[] = [];
    description.push(firstExplain, secondExplain, thirdExplain);

    if (fourthExplain) {
      description.push(fourthExplain);
    }
    if (fifthExplain) {
      description.push(fifthExplain);
    }

    if (original && treatment) {
      const originalData: CreateTreatmentRequest = {
        treatmentName: original.treatmentName,
        departmentId: original.departmentId,
        treatmentCategoryId: original.treatmentCategoryId,
        representative: original.representative,
        price: original.price,
        priceOption: original.priceOption,
        introduction: original.introduction,
        imageUrl: original.imageUrl,
        textImageUrl: original.textImageUrl,
        description: original.description,
        treatmentAdvantagesAndEffects: original.treatmentAdvantagesAndEffects.map((row, index) => {
          return {
            key: index + 1,
            imageUrl: row.imageUrl,
            content: row.content,
            file: row.file,
          };
        }),
        treatmentProcedures: original.treatmentProcedures.map((row, index) => {
          return {
            key: index + 1,
            imageUrl: row.imageUrl,
            title: row.title,
            file: row.file,
          };
        }),
        beforeAndAfterTreatment:
          original.beforeAndAfterTreatment === undefined
            ? []
            : original.beforeAndAfterTreatment.map((row, index) => {
                return {
                  key: index + 1,
                  beforeImageUrl: row.beforeImageUrl,
                  afterImageUrl: row.afterImageUrl,
                  title: row.title,
                  beforeFile: row.beforeFile,
                  afterFile: row.afterFile,
                };
              }),
        faq: original.faq.map((row, index) => {
          return {
            key: index + 1,
            title: row.title,
            content: row.content,
          };
        }),
      };

      const changedData: CreateTreatmentRequest = {
        treatmentName: treatName,
        departmentId: selectedDepartmentId,
        treatmentCategoryId: selectedCategoryId,
        representative: representative,
        price: cost,
        priceOption: costOption,
        introduction: treatIntro,
        imageUrl: image ?? '',
        textImageUrl: textImage ?? '',
        description: description,
        treatmentAdvantagesAndEffects: advantages,
        treatmentProcedures: procedures,
        beforeAndAfterTreatment: beforeAndAfters,
        faq: faqs,
      };

      return JSON.stringify(originalData) !== JSON.stringify(changedData);
    } else {
      const changedData: CreateTreatmentRequest = {
        treatmentName: treatName,
        departmentId: selectedDepartmentId,
        treatmentCategoryId: selectedCategoryId,
        representative: representative,
        price: cost,
        priceOption: costOption,
        introduction: treatIntro,
        imageUrl: image ?? '',
        textImageUrl: textImage ?? '',
        description: description,
        treatmentAdvantagesAndEffects: advantages,
        treatmentProcedures: procedures,
        beforeAndAfterTreatment: beforeAndAfters,
        faq: faqs,
      };

      if (changedData.treatmentName !== '') {
        return true;
      }

      if (changedData.departmentId !== '') {
        return true;
      }

      if (changedData.treatmentCategoryId !== '') {
        return true;
      }

      if (changedData.representative !== '') {
        return true;
      }

      if (changedData.price !== 0) {
        return true;
      }

      if (changedData.priceOption !== '') {
        return true;
      }

      if (changedData.introduction !== '') {
        return true;
      }

      let filteredDesc = changedData.description.filter(item => item !== '');
      if (filteredDesc.length > 0) {
        return true;
      }

      if (changedData.treatmentAdvantagesAndEffects && changedData.treatmentAdvantagesAndEffects.length > 0) {
        return true;
      }

      if (changedData.treatmentProcedures && changedData.treatmentProcedures.length > 0) {
        return true;
      }

      if (changedData.beforeAndAfterTreatment && changedData.beforeAndAfterTreatment.length > 0) {
        return true;
      }

      return changedData.faq && changedData.faq.length > 0;
    }
  }, [
    firstExplain,
    secondExplain,
    thirdExplain,
    fourthExplain,
    fifthExplain,
    original,
    treatment,
    treatName,
    selectedDepartmentId,
    selectedCategoryId,
    representative,
    cost,
    costOption,
    treatIntro,
    image,
    textImage,
    advantages,
    procedures,
    beforeAndAfters,
    faqs,
  ]);

  // 시술 기본 정보 취소
  const onResetBasic = useCallback(() => {
    if (isContentsChanged()) {
      setPopupData(popupText.unsave, true, () => () => navigate('/management/treat'));
      setIsConfirmPopupVisible(true);
      return;
    }

    navigate('/management/treat');
  }, [navigate, isContentsChanged]);

  // 시술 기본 정보 다음
  const onSubmitBasic = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (checkValidationBasicInfo()) {
        setActiveKey('2');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    [checkValidationBasicInfo],
  );

  // TreatDetail start
  // 시술 장점 및 효과 추가
  const onSaveAdvantage = useCallback(() => {
    if (advantageFile && advantage) {
      setIsEmptyAdvantageFile(false);
      setIsEmptyAdvantage(false);

      if (editAdvantageIndex !== undefined) {
        const advantageData = {
          ...advantages[editAdvantageIndex],
          imageUrl: URL.createObjectURL(advantageFile),
          content: advantage,
          file: advantageFile,
        };

        setAdvantages(prev => {
          const copy = [...prev];
          copy[editAdvantageIndex] = advantageData;

          return copy;
        });
      } else {
        const advantageData: TreatmentAdvantageAndEffectItem = {
          imageUrl: URL.createObjectURL(advantageFile),
          content: advantage,
          key: advantages.length,
          file: advantageFile,
        };

        setAdvantages(prev => {
          return [...prev].concat(advantageData);
        });
      }
      setIsEffectVisible(false);
    } else {
      if (!advantageFile) {
        setIsEmptyAdvantageFile(true);
      }
      if (!advantage) {
        setIsEmptyAdvantage(true);
      }
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [advantage, advantageFile, advantages, editAdvantageIndex]);

  // 시술 장점 및 효과 삭제
  const onDeleteAdvantage = useCallback((index: number) => {
    setAdvantages(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        return copy;
      }

      return prev;
    });
  }, []);

  // 시술 장점 및 효과 수정 버튼
  const onPressEditAdvantage = useCallback((index: number, data: TreatmentAdvantageAndEffectItem) => {
    setEditAdvantageIndex(index);
    onChangeAdvantageFile(data.file);
    setAdvantage(data.content);
    setIsEffectVisible(true);
  }, []);

  // 시술 장점 및 효과 삭제 버튼
  const onPressDeleteAdvantage = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteAdvantage(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteAdvantage],
  );

  // 시술 과정 추가
  const onSaveProcedure = useCallback(() => {
    if (procedureFile && procedure) {
      setIsEmptyProcedureFile(false);
      setIsEmptyProcedure(false);

      if (editProcedureIndex !== undefined) {
        const procedureData = {
          ...procedures[editProcedureIndex],
          imageUrl: URL.createObjectURL(procedureFile),
          title: procedure,
          file: procedureFile,
        };

        setProcedures(prev => {
          const copy = [...prev];
          copy[editProcedureIndex] = procedureData;

          return copy;
        });
      } else {
        const procedureData: TreatmentProcedureItem = {
          imageUrl: URL.createObjectURL(procedureFile),
          title: procedure,
          key: procedures.length,
          file: procedureFile,
        };

        setProcedures(prev => {
          return [...prev].concat(procedureData);
        });
      }
      setIsProcessVisible(false);
    } else {
      if (!procedureFile) {
        setIsEmptyProcedureFile(true);
      }
      if (!procedure) {
        setIsEmptyProcedure(true);
      }
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [editProcedureIndex, procedure, procedureFile, procedures]);

  // 시술 과정 삭제
  const onDeleteProcedure = useCallback((index: number) => {
    setProcedures(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        return copy;
      }

      return prev;
    });
  }, []);

  // 시술 과정 수정 버튼
  const onPressEditProcedure = useCallback((index: number, data: TreatmentProcedureItem) => {
    setEditProcedureIndex(index);
    onChangeProcedureFile(data.file);
    setProcedure(data.title);
    setIsProcessVisible(true);
  }, []);

  // 시술 과정 삭제 버튼
  const onPressDeleteProcedure = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteProcedure(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteProcedure],
  );

  // 시술 전후 추가
  const onSaveBeforeAndAfter = useCallback(() => {
    if (beforeFile && afterFile && beforeAndAfter) {
      setIsEmptyBeforeFile(false);
      setIsEmptyAfterFile(false);
      setIsEmptyBeforeAndAfter(false);

      if (editBeforeAndAfterIndex !== undefined) {
        const beforeAndAfterData = {
          ...beforeAndAfters[editBeforeAndAfterIndex],
          beforeImageUrl: URL.createObjectURL(beforeFile),
          afterImageUrl: URL.createObjectURL(afterFile),
          title: beforeAndAfter,
          beforeFile: beforeFile,
          afterFile: afterFile,
        };

        setBeforeAndAfters(prev => {
          const copy = [...prev];
          copy[editBeforeAndAfterIndex] = beforeAndAfterData;

          return copy;
        });
      } else {
        const beforeAndAfterData: BeforeAndAfterTreatmentItem = {
          beforeImageUrl: URL.createObjectURL(beforeFile),
          afterImageUrl: URL.createObjectURL(afterFile),
          title: beforeAndAfter,
          beforeFile: beforeFile,
          afterFile: afterFile,
          key: beforeAndAfters.length + 1,
        };

        setBeforeAndAfters(prev => {
          return [...prev].concat(beforeAndAfterData);
        });
      }
      setIsBeforeVisible(false);
    } else {
      if (!beforeFile) {
        setIsEmptyBeforeFile(true);
      }
      if (!afterFile) {
        setIsEmptyAfterFile(true);
      }
      if (!beforeAndAfter) {
        setIsEmptyBeforeAndAfter(true);
      }
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [afterFile, beforeAndAfter, beforeAndAfters, beforeFile, editBeforeAndAfterIndex]);

  // 시술 전후 삭제
  const onDeleteBeforeAndAfter = useCallback((index: number) => {
    setBeforeAndAfters(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        for (let i = 0; i < copy.length; i++) {
          copy[i].key = i + 1;
        }

        return copy;
      }

      return prev;
    });
  }, []);

  // 시술 전후 수정 버튼
  const onPressEditBeforeAndAfter = useCallback((index: number, data: BeforeAndAfterTreatmentItem) => {
    setEditBeforeAndAfterIndex(index);
    onChangeBeforeFile(data.beforeFile);
    onChangeAfterFile(data.afterFile);
    setBeforeAndAfter(data.title);
    setIsBeforeVisible(true);
  }, []);

  // 시술 전후 삭제 버튼
  const onPressDeleteBeforeAndAfter = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteBeforeAndAfter(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteBeforeAndAfter],
  );

  // 시술 전후 순서 변경
  const swapBeforeAndAfterOrder = useCallback(
    async (target: BeforeAndAfterTreatmentItem, index: number, direction: ShiftDirection) => {
      let swapTarget;
      let swapIndex;
      if (beforeAndAfters.length >= 2) {
        switch (direction) {
          case ShiftDirection.FORWARD: {
            swapIndex = index - 1;
            swapTarget = beforeAndAfters.at(swapIndex);
            break;
          }
          case ShiftDirection.BACKWARD: {
            swapIndex = index + 1;
            swapTarget = beforeAndAfters.at(swapIndex);
            break;
          }
        }

        if (swapTarget) {
          const key = swapTarget.key;

          swapTarget.key = target.key;
          target.key = key;

          const copy = [...beforeAndAfters];
          copy[swapIndex] = target;
          copy[index] = swapTarget;
          copy.sort((a, b) => a.key - b.key);

          setBeforeAndAfters(copy);
        }
      }
    },
    [beforeAndAfters],
  );

  // 자주 묻는 질문 추가
  const onSaveFaq = useCallback(() => {
    if (faqTitle && faqContents) {
      setIsEmptyFaqTitle(false);
      setIsEmptyFaqContents(false);

      if (editFaqIndex !== undefined) {
        const faqData = {
          ...faqs[editFaqIndex],
          title: faqTitle,
          content: faqContents,
        };

        setFaqs(prev => {
          const copy = [...prev];
          copy[editFaqIndex] = faqData;

          return copy;
        });
      } else {
        const faqData: TreatmentFaqItem = {
          title: faqTitle,
          content: faqContents,
          key: faqs.length + 1,
        };

        setFaqs(prev => {
          return [...prev].concat(faqData);
        });
      }
      setIsFaqVisible(false);
    } else {
      if (!faqTitle) {
        setIsEmptyFaqTitle(true);
      }
      if (!faqContents) {
        setIsEmptyFaqContents(true);
      }
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [editFaqIndex, faqContents, faqTitle, faqs]);

  // 자주 묻는 질문 삭제
  const onDeleteFaq = useCallback((index: number) => {
    setFaqs(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        for (let i = 0; i < copy.length; i++) {
          copy[i].key = i + 1;
        }

        return copy;
      }

      return prev;
    });
  }, []);

  // 자주 묻는 질문 수정 버튼
  const onPressEditFaq = useCallback((index: number, data: TreatmentFaqItem) => {
    setEditFaqIndex(index);
    setFaqTitle(data.title);
    setFaqContents(data.content);
    setIsFaqVisible(true);
  }, []);

  // 자주 묻는 질문 삭제 버튼
  const onPressDeleteFaq = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteFaq(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteFaq],
  );

  // 자주 묻는 질문 순서 변경
  const swapFaqOrder = useCallback(
    async (target: TreatmentFaqItem, index: number, direction: ShiftDirection) => {
      let swapTarget;
      let swapIndex;
      if (faqs.length >= 2) {
        switch (direction) {
          case ShiftDirection.FORWARD: {
            swapIndex = index - 1;
            swapTarget = faqs.at(swapIndex);
            break;
          }
          case ShiftDirection.BACKWARD: {
            swapIndex = index + 1;
            swapTarget = faqs.at(swapIndex);
            break;
          }
        }

        if (swapTarget) {
          const key = swapTarget.key;

          swapTarget.key = target.key;
          target.key = key;

          const copy = [...faqs];
          copy[swapIndex] = target;
          copy[index] = swapTarget;
          copy.sort((a, b) => a.key - b.key);

          setFaqs(copy);
        }
      }
    },
    [faqs],
  );

  // 시술 상세 정보 유효성 여부
  const validationDetailInfo = useMemo(() => {
    let validation = true;
    if (!firstExplain) {
      validation = false;
    }

    if (!secondExplain) {
      validation = false;
    }

    if (!thirdExplain) {
      validation = false;
    }

    if (advantages.length > 0 && advantages.length < 2) {
      validation = false;
    }

    if (procedures.length > 0 && procedures.length < 3) {
      validation = false;
    }

    if (faqs.length >= 0 && faqs.length < 2) {
      validation = false;
    }

    return validation;
  }, [firstExplain, secondExplain, thirdExplain, advantages, procedures, faqs]);

  // 시술 상세 정보 유효성 체크
  const checkValidationDetailInfo = useCallback((): boolean => {
    let validation = true;
    if (!firstExplain) {
      setIsEmptyFirstExplain(true);
      validation = false;
    } else {
      setIsEmptyFirstExplain(false);
    }

    if (!secondExplain) {
      setIsEmptySecondExplain(true);
      validation = false;
    } else {
      setIsEmptySecondExplain(false);
    }

    if (!thirdExplain) {
      setIsEmptyThirdExplain(true);
      validation = false;
    } else {
      setIsEmptyThirdExplain(false);
    }

    // 시술 장점 및 효과, 선택 옵션이나 등록 시는 최소 2개 이상 등록
    if (advantages.length > 0 && advantages.length < 2) {
      setIsNotEnoughAdvantages(true);
      validation = false;
    } else {
      setIsNotEnoughAdvantages(false);
    }

    // 시술 과정, 선택 옵션이나 등록 시는 최소 3개 이상 등록
    if (procedures.length > 0 && procedures.length < 3) {
      setIsNotEnoughProcedures(true);
      validation = false;
    } else {
      setIsNotEnoughProcedures(false);
    }

    // 자주 묻는 질문 최소 2개 이상 등록
    if (faqs.length >= 0 && faqs.length < 2) {
      setIsNotEnoughFaqs(true);
      validation = false;
    } else {
      setIsNotEnoughFaqs(false);
    }

    if (validation) {
      setIsEmptyFirstExplain(false);
      setIsEmptySecondExplain(false);
      setIsEmptyThirdExplain(false);
      setIsNotEnoughAdvantages(false);
      setIsNotEnoughProcedures(false);
      setIsNotEnoughFaqs(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validationBasicInfo;
  }, [
    advantages.length,
    faqs.length,
    firstExplain,
    procedures.length,
    secondExplain,
    thirdExplain,
    validationBasicInfo,
  ]);

  // 시술 상세 정보 이전
  const goPrev = useCallback((e: any) => {
    e.preventDefault();
    setActiveKey('1');
  }, []);

  // 시술 저장
  const onSubmit = useCallback(async () => {
    if (hospital) {
      // 시술 이미지형 사진
      let imageFileUrl: string = image ?? '';

      if (imageFile) {
        imageFileUrl = (await onSaveFile(imageFile)) ?? '';
      }

      // 시술 텍스트형 사진
      let textImageFileUrl: string = textImage ?? '';

      if (textImageFile) {
        textImageFileUrl = (await onSaveFile(textImageFile)) ?? '';
      }

      // 시술 설명 배열화
      const description: string[] = [];
      description.push(firstExplain, secondExplain, thirdExplain);

      if (fourthExplain) {
        description.push(fourthExplain);
      }
      if (fifthExplain) {
        description.push(fifthExplain);
      }

      // 시술 장점 및 효과 이미지 업로드
      const advantageList = await Promise.all(
        advantages.map(async row => {
          if (row.file) {
            row.imageUrl = (await onSaveFile(row.file)) ?? '';
          }

          return row;
        }),
      );

      // 시술 과정 이미지 업로드
      const procedureList = await Promise.all(
        procedures.map(async row => {
          if (row.file) {
            row.imageUrl = (await onSaveFile(row.file)) ?? '';
          }

          return row;
        }),
      );

      // 시술 전후 이미지 업로드
      const beforeAndAfterList = await Promise.all(
        beforeAndAfters.map(async row => {
          if (row.beforeFile) {
            row.beforeImageUrl = (await onSaveFile(row.beforeFile)) ?? '';
          }

          if (row.afterFile) {
            row.afterImageUrl = (await onSaveFile(row.afterFile)) ?? '';
          }

          return row;
        }),
      );

      if (!treatment) {
        // 등록 시
        const submitData: CreateTreatmentRequest = {
          treatmentName: treatName,
          departmentId: selectedDepartmentId,
          treatmentCategoryId: selectedCategoryId,
          representative: representative,
          price: cost,
          priceOption: costOption,
          introduction: treatIntro,
          imageUrl: imageFileUrl,
          textImageUrl: textImageFileUrl,
          description: description,
          treatmentAdvantagesAndEffects: advantageList.length > 0 ? advantageList : undefined,
          treatmentProcedures: procedureList.length > 0 ? procedureList : undefined,
          beforeAndAfterTreatment: beforeAndAfterList.length > 0 ? beforeAndAfterList : undefined,
          faq: faqs,
        };

        const data = (await api.treatment.addTreatment(submitData)).data;
        if (data.created) {
          navigate('/management/treat');
        }
      } else {
        // 수정 시
        const submitData: ModifyTreatmentRequest = {
          treatmentName: treatName,
          departmentId: selectedDepartmentId,
          treatmentCategoryId: selectedCategoryId,
          representative: representative,
          price: cost,
          priceOption: costOption,
          introduction: treatIntro,
          imageUrl: imageFileUrl,
          textImageUrl: textImageFileUrl,
          description: description,
          treatmentAdvantagesAndEffects: advantageList.length > 0 ? advantageList : undefined,
          treatmentProcedures: procedureList.length > 0 ? procedureList : undefined,
          beforeAndAfterTreatment: beforeAndAfterList.length > 0 ? beforeAndAfterList : undefined,
          faq: faqs,
        };

        const data = (await api.treatment.modifyTreatment(treatment.id, submitData)).data;
        if (data.modified) {
          navigate('/management/treat');
        }
      }
    }
  }, [
    advantages,
    api.treatment,
    beforeAndAfters,
    cost,
    costOption,
    faqs,
    fifthExplain,
    firstExplain,
    fourthExplain,
    hospital,
    image,
    imageFile,
    navigate,
    onSaveFile,
    procedures,
    representative,
    secondExplain,
    selectedCategoryId,
    selectedDepartmentId,
    textImage,
    textImageFile,
    thirdExplain,
    treatIntro,
    treatName,
    treatment,
  ]);

  const onClickSubmit = useCallback(async (e: FormEvent) => {e.preventDefault();
      checkValidationDetailInfo();

      if (isNotModified) {
        setPopupData(popupText.unchanged, false);
        setIsConfirmPopupVisible(true);
      } else if (validationBasicInfo && validationDetailInfo) {
        setPopupData(popupText.save, true, () => () => onSubmit());
        setIsConfirmPopupVisible(true);
      }
    },
    [checkValidationDetailInfo, isNotModified, onSubmit, validationBasicInfo, validationDetailInfo],
  );

  const advantageColumns: ColumnsType<TreatmentAdvantageAndEffectItem> = [
    {
      title: '사진',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value: any, record) => {
        if (record.imageUrl) {
          return <Image src={record.imageUrl} />;
        } else {
          return <DefaultImage />;
        }
      },
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'cotent',
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record, index: number) =>
        region !== 'ko' ? (
          <InnerTdButton isWithEdit isDisabled />
        ) : (
          <InnerTdButton
            isWithEdit
            onClickEdit={() => onPressEditAdvantage(index, record)}
            onClickDelete={() => onPressDeleteAdvantage(index)}
          />
        ),
    },
  ];

  const procedureColumns: ColumnsType<TreatmentProcedureItem> = [
    {
      title: '사진',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value: any, record) => {
        if (record.imageUrl) {
          return <Image src={record.imageUrl} />;
        } else {
          return <DefaultImage />;
        }
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record, index: number) => (
        <InnerTdButton
          isWithEdit
          onClickEdit={() => onPressEditProcedure(index, record)}
          onClickDelete={() => onPressDeleteProcedure(index)}
        />
      ),
    },
  ];

  const beforeAndAfterColumns: ColumnsType<BeforeAndAfterTreatmentItem> = [
    {
      title: '순서 변경',
      dataIndex: 'change',
      key: 'change',
      render: (value: any, record, index: number) => {
        return region !== 'ko' ? (
          <>
            <UpDownButton isDisabled />
            <UpDownButton isDisabled isDown />
          </>
        ) : (
          <>
            <UpDownButton
              onClick={() => {
                if (index > 0) {
                  swapBeforeAndAfterOrder(record, index, ShiftDirection.FORWARD);
                }
              }}
            />
            <UpDownButton
              onClick={() => {
                if (index < beforeAndAfters.length - 1) {
                  swapBeforeAndAfterOrder(record, index, ShiftDirection.BACKWARD);
                }
              }}
              isDown
            />
          </>
        );
      },
    },
    {
      title: '노출 순서',
      dataIndex: 'key',
      key: 'key',
      width: 120,
    },
    {
      title: '사진',
      dataIndex: 'photo',
      key: 'photo',
      render: (value, record) => {
        return (
          <DetailImageWrapper>
            {record.beforeImageUrl ? <Image src={record.beforeImageUrl} /> : <DefaultImage />}
            {record.afterImageUrl ? <Image src={record.afterImageUrl} /> : <DefaultImage />}
          </DetailImageWrapper>
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record, index: number) =>
        region !== 'ko' ? (
          <InnerTdButton isWithEdit isDisabled />
        ) : (
          <InnerTdButton
            isWithEdit
            onClickEdit={() => onPressEditBeforeAndAfter(index, record)}
            onClickDelete={() => onPressDeleteBeforeAndAfter(index)}
          />
        ),
    },
  ];

  const faqColumns: ColumnsType<TreatmentFaqItem> = [
    {
      title: '순서 변경',
      dataIndex: 'order',
      key: 'order',
      render: (value: any, record, index: number) =>
        region !== 'ko' ? (
          <>
            <UpDownButton isDisabled />
            <UpDownButton isDisabled isDown />
          </>
        ) : (
          <>
            <UpDownButton
              onClick={() => {
                if (index > 0) {
                  swapFaqOrder(record, index, ShiftDirection.FORWARD);
                }
              }}
            />
            <UpDownButton
              onClick={() => {
                if (index < faqs.length - 1) {
                  swapFaqOrder(record, index, ShiftDirection.BACKWARD);
                }
              }}
              isDown
            />
          </>
        ),
    },
    {
      title: '노출 순서',
      dataIndex: 'key',
      key: 'key',
      width: 120,
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record, index: number) =>
        region !== 'ko' ? (
          <InnerTdButton isWithEdit isDisabled />
        ) : (
          <InnerTdButton
            isWithEdit
            onClickEdit={() => onPressEditFaq(index, record)}
            onClickDelete={() => onPressDeleteFaq(index)}
          />
        ),
    },
  ];

  const departmentIdToText = () => {
    const departmentName: Array<string> = new Array<string>();

    for (const index in allDepartments) {
      if (treatment?.departmentId === allDepartments[index].id) {
        const department = allDepartments[index];
        departmentName.push(
          region === 'en' && department.nameEn !== '' && department.nameEn !== undefined
            ? department.nameEn
            : region === 'zh' && department.nameZh !== '' && department.nameZh !== undefined
            ? department.nameZh
            : department.name,
        );
      }
    }

    return departmentName;
  };

  const categoryIdToText = () => {
    const categoryName: Array<string> = new Array<string>();

    for (const index in allCategories) {
      if (treatment?.treatmentCategoryId === allCategories[index].categoryId) {
        const category = allCategories[index];
        categoryName.push(
          region === 'en' && category.categoryNameEn !== '' && category.categoryNameEn !== undefined
            ? category.categoryNameEn
            : region === 'zh' && category.categoryNameZh !== '' && category.categoryNameZh !== undefined
            ? category.categoryNameZh
            : category.categoryName,
        );
      }
    }
    return categoryName;
  };

  const multilingualRepresentative = () => {
    if (region === 'en') {
      for (const index in enRepresentativeOptionList) {
        if (representative === enRepresentativeOptionList[index].value) {
          return enRepresentativeOptionList[index].text;
        }
      }
    }
    if (region === 'zh') {
      for (const index in zhRepresentativeOptionList) {
        if (representative === zhRepresentativeOptionList[index].value) {
          return zhRepresentativeOptionList[index].text;
        }
      }
    }
    return '';
  };

  // 시술 호출 (수정)
  useEffect(() => {
    getTreatments().then().catch();
  }, [getTreatments]);

  // 병원 호출
  useEffect(() => {
    getHospital().then().catch();
  }, [getHospital]);

  // 진료과 호출
  useEffect(() => {
    getDepartments().then().catch();
  }, [getDepartments]);

  // 진료과 목록 편집
  useEffect(() => {
    setDepartmentsOptionList();
  }, [setDepartmentsOptionList]);

  // 시술 카테고리 호출
  useEffect(() => {
    getCategories().then().catch();
  }, [getCategories]);

  // 시술 카테고리 목록 편집
  useEffect(() => {
    setCategoryOptionList();
  }, [setCategoryOptionList]);

  // 수정 시 시술 데이터 반영
  useEffect(() => {
    setTreatsData();
  }, [setTreatsData]);

  const onRegionChange = useCallback((key: any) => {
    if (key === '1') {
      setRegion('ko');
    }
    if (key === '2') {
      setRegion('en');
    }
    if (key === '3') {
      setRegion('zh');
    }
    setChangedRegion(true)
  }, []);

  useEffect(() => {
    if(changedRegion) {
      return () => {
        setIsNotModified(true)
        setChangedRegion(false)
      }
    } else if(treatment !== undefined) {
      return () => {
        setIsNotModified(false)
      }
    } else {
      return () => {
        setIsNotModified(false)
      }
    }
  }, [treatName, selectedDepartmentId, selectedCategoryId, representative, cost, costOption, treatIntro, image, textImage,
    firstExplain, secondExplain, thirdExplain, fourthExplain, fifthExplain, advantages, procedures, beforeAndAfters, faqs, changedRegion]);

  return (
    <>
      <BreadWrapper>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/management/treat">시술 관리</Breadcrumb.Item>
          <Breadcrumb.Item href="">시술 {pathname.includes('edit') ? '추가' : '편집'}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadWrapper>
      <PageHeading>시술 {pathname.includes('edit') ? '추가' : '편집'}</PageHeading>

      <StyledTabs type="card" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
        {/* 시술 기본 정보 시작 */}
        <TabPane tab="시술 기본 정보" key="1">
          <Layout>
            <ContentHeading isRequired>시술 기본 정보</ContentHeading>
            <StyledSubTabs
              type="card"
              defaultActiveKey="1"
              activeKey={activeSubTabKey}
              onChange={onSubKeyChange}
              onTabClick={key => onRegionChange(key)}
            >
              <TabPane tab="한국어" key="1">
                <form onSubmit={onSubmitBasic}>
                  <LabelText text="병원명">{hospital?.hospitalName}</LabelText>
                  <LabelInput
                    text="시술명"
                    placeholder="시술명을 입력해주세요."
                    value={treatName}
                    onChange={onChangeTreatName}
                    isRequired
                    isRequiredEmpty={isEmptyTreatName}
                    maxLength={200}
                  />
                  <LabelSelect
                    text="진료과"
                    value={selectedDepartmentId}
                    isRequired
                    optionList={departments}
                    onChange={onChangeDepartment}
                    isRequiredEmpty={isEmptyDepartment}
                  />
                  <LabelSelect
                    text="시술 카테고리"
                    value={selectedCategoryId}
                    isRequired
                    optionList={categories}
                    onChange={onChangeCategory}
                    disabled={!selectedDepartmentId}
                    isRequiredEmpty={isEmptyCategory}
                  />
                  <LabelSelect
                    text="대표 시술 여부"
                    value={representative}
                    isRequired
                    optionList={representativeOptionList}
                    onChange={onChangeRepresentativeOption}
                    isRequiredEmpty={isEmptyRepresentative}
                  />
                  <LabelInput
                    text="가격"
                    value={cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    onChange={onChangeCost}
                    isRequiredEmpty={isEmptyCost}
                    isRequired
                    isCost
                  />
                  <LabelInput
                    text="가격 옵션(선택)"
                    placeholder="예)4cc/1회"
                    maxLength={200}
                    value={costOption}
                    onChange={onChangeCostOption}
                    hasHelperText
                  >
                    가격하단에 표시되는 문구입니다.
                    <br />
                    (용량, 횟수)
                  </LabelInput>
                  <LabelTextarea
                    text="시술 소개"
                    placeholder="시술 소개를 입력해주세요."
                    maxLength={2000}
                    value={treatIntro}
                    onChange={onChangeTreatIntro}
                    isRequired
                    isRequiredEmpty={isEmptyIntro}
                  />
                  <ImageWrapper>
                    <Label text="시술 이미지형 사진" isRequired />
                    <AddImageButton url={image} onChange={onChangeImageFile} isRequiredEmpty={isEmptyImageFile} />
                  </ImageWrapper>
                  <ImageWrapper>
                    <Label text="시술 텍스트형 사진" isRequired />
                    <AddImageButton
                      url={textImage}
                      onChange={onChangeTextImageFile}
                      isRequiredEmpty={isEmptyTextImageFile}
                    />
                  </ImageWrapper>

                  <ButtonWrapper>
                    <Button type="button" isBig onClick={() => onResetBasic()}>
                      취소
                    </Button>
                    <Button isBig isFilled>
                      다음
                    </Button>
                  </ButtonWrapper>
                </form>
              </TabPane>
              <TabPane tab="영어" key="2">
                <TreatBasicTranslate
                  hospitalName={hospital?.hospitalName}
                  treatName={treatName}
                  departments={departmentIdToText()}
                  categories={categoryIdToText()}
                  representative={multilingualRepresentative()}
                  cost={cost}
                  costOption={costOption}
                  treatIntro={treatIntro}
                  treatImage={image}
                  treatText={textImage}
                />
              </TabPane>
              <TabPane tab="중국어" key="3">
                <TreatBasicTranslate
                  hospitalName={hospital?.hospitalName}
                  treatName={treatName}
                  departments={departmentIdToText()}
                  categories={categoryIdToText()}
                  representative={multilingualRepresentative()}
                  cost={cost}
                  costOption={costOption}
                  treatIntro={treatIntro}
                  treatImage={image}
                  treatText={textImage}
                />
              </TabPane>
            </StyledSubTabs>
          </Layout>
        </TabPane>
        {/* 시술 기본 정보 끝 */}

        {/* 시술 상세 정보 시작 */}
        <TabPane disabled={!validationBasicInfo} tab="시술 상세 정보" key="2">
          <Layout>
            {region !== 'ko' ? (
              <ContentHeading isRequired isHelperText>
                시술 상세 정보
              </ContentHeading>
            ) : (
              <ContentHeading isRequired>시술 상세 정보</ContentHeading>
            )}
            <StyledSubTabs
              type="card"
              defaultActiveKey="1"
              activeKey={activeSubTabKey}
              onChange={onSubKeyChange}
              onTabClick={key => onRegionChange(key)}
            >
              <TabPane tab="한국어" key="1">
                <form onSubmit={onClickSubmit}>
                  <Section>
                    <TableHeading>시술 추천 대상 설명</TableHeading>
                    <LabelInput
                      text="시술 설명1"
                      value={firstExplain}
                      onChange={onChangeFirstExplain}
                      isRequiredEmpty={isEmptyFirstExplain}
                      placeholder="내용을 입력해주세요."
                      maxLength={200}
                      isRequired
                    />
                    <LabelInput
                      text="시술 설명2"
                      value={secondExplain}
                      onChange={onChangeSecondExplain}
                      isRequiredEmpty={isEmptySecondExplain}
                      placeholder="내용을 입력해주세요."
                      maxLength={200}
                      isRequired
                    />
                    <LabelInput
                      text="시술 설명3"
                      value={thirdExplain}
                      onChange={onChangeThirdExplain}
                      isRequiredEmpty={isEmptyThirdExplain}
                      placeholder="내용을 입력해주세요."
                      maxLength={200}
                      isRequired
                    />
                    <LabelInput
                      text="시술 설명4 (선택)"
                      value={fourthExplain}
                      onChange={onChangeFourthExplain}
                      placeholder="내용을 입력해주세요."
                      maxLength={200}
                    />
                    <LabelInput
                      text="시술 설명5 (선택)"
                      value={fifthExplain}
                      onChange={onChangeFifthExplain}
                      placeholder="내용을 입력해주세요."
                      maxLength={200}
                    />
                  </Section>
                  <Divider />
                  <Section>
                    <TableHeading hasButton="추가" onClick={showEffectPopup} isButtonDisabled={advantages.length >= 3}>
                      시술 장점 및 효과(선택)
                      <small>선택 시 최소 2개, 최대 3개까지 추가 가능합니다.</small>
                    </TableHeading>
                    <TableDefault columns={advantageColumns} dataSource={advantages} />
                    {isNotEnoughAdvantages && <HelperText>필수로 2개 항목을 추가 해야합니다.</HelperText>}
                  </Section>
                  <Divider />
                  <Section>
                    <TableHeading hasButton="추가" onClick={showProcessPopup} isButtonDisabled={procedures.length >= 8}>
                      시술 과정(선택)
                      <small>선택 시 최소 3개, 최대 8개까지 추가 가능합니다.</small>
                    </TableHeading>
                    <TableDefault columns={procedureColumns} dataSource={procedures} />
                    {isNotEnoughProcedures && <HelperText>필수로 3개 항목을 추가 해야합니다.</HelperText>}
                  </Section>
                  <Divider />
                  <Section>
                    <TableHeading
                      hasButton="추가"
                      onClick={showBeforePopup}
                      isButtonDisabled={beforeAndAfters.length >= 10}
                    >
                      시술 전후(선택)
                      <small>최대 10개까지 추가 가능합니다.</small>
                    </TableHeading>
                    <TableDefault columns={beforeAndAfterColumns} dataSource={beforeAndAfters} />
                  </Section>
                  <Divider />
                  <Section>
                    <TableHeading
                      hasButton="추가"
                      onClick={showFaqPopup}
                      isRequired
                      isButtonDisabled={faqs.length >= 6}
                    >
                      자주 묻는 질문
                      <small>최소 2개, 최대 6개까지 추가 가능합니다.</small>
                    </TableHeading>
                    <TableDefault columns={faqColumns} dataSource={faqs} />
                    {isNotEnoughFaqs && <HelperText>필수로 2개 항목을 추가 해야합니다.</HelperText>}
                  </Section>
                  <Divider />
                  <ButtonWrapper>
                    <Button isBig onClick={goPrev}>
                      이전
                    </Button>
                    <Button isBig isFilled>
                      저장
                    </Button>
                  </ButtonWrapper>
                </form>
              </TabPane>
              <TabPane tab="영어" key="2">
                <TreatDetailTranslate
                  firstExplain={firstExplain}
                  secondExplain={secondExplain}
                  thirdExplain={thirdExplain}
                  fourthExplain={fourthExplain}
                  fifthExplain={fifthExplain}
                  advantageColumns={advantageColumns}
                  advantages={advantages}
                  procedureColumns={procedureColumns}
                  procedures={procedures}
                  beforeAndAfterColumns={beforeAndAfterColumns}
                  beforeAndAfters={beforeAndAfters}
                  faqColumns={faqColumns}
                  faqs={faqs}
                />
              </TabPane>
              <TabPane tab="중국어" key="3">
                <TreatDetailTranslate
                  firstExplain={firstExplain}
                  secondExplain={secondExplain}
                  thirdExplain={thirdExplain}
                  fourthExplain={fourthExplain}
                  fifthExplain={fifthExplain}
                  advantageColumns={advantageColumns}
                  advantages={advantages}
                  procedureColumns={procedureColumns}
                  procedures={procedures}
                  beforeAndAfterColumns={beforeAndAfterColumns}
                  beforeAndAfters={beforeAndAfters}
                  faqColumns={faqColumns}
                  faqs={faqs}
                />
              </TabPane>
            </StyledSubTabs>
          </Layout>
          {/* 시술 상세 정보 끝 */}
        </TabPane>
      </StyledTabs>

      {/* 시술 상세 정보 팝업 시작 */}
      {/*  5-2 추가 버튼 클릭하는 경우, 1번 팝업 */}
      {isEffectVisible && (
        <DefaultPopup
          title={editAdvantageIndex !== undefined ? '수정' : '추가'}
          isPopupVisible={isEffectVisible}
          setIsPopupVisible={setIsEffectVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          onClickOk={onSaveAdvantage}
          okWithoutClose
          isWithCancel
          isWithSave={editAdvantageIndex === undefined}
          isWithEdit={editAdvantageIndex !== undefined}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              onSaveAdvantage();
            }}
          >
            <LabelImageWrapper>
              <Label text="사진" isRequired isPopup />
              <AddImageButton
                url={editAdvantageIndex !== undefined ? advantages[editAdvantageIndex]?.imageUrl : undefined}
                onChange={onChangeAdvantageFile}
                isRequiredEmpty={isEmptyAdvantageFile}
              />
            </LabelImageWrapper>
            <LabelTextarea
              text="내용"
              placeholder="내용을 입력해주세요"
              value={advantage}
              onChange={onChangeAdvantage}
              isRequiredEmpty={isEmptyAdvantage}
              maxLength={2000}
              isRequired
              isPopup
            />
          </form>
        </DefaultPopup>
      )}
      {/* 6-2 추가 버튼 클릭하는 경우, 2번 팝업 */}
      {isProcessVisible && (
        <DefaultPopup
          title={editProcedureIndex !== undefined ? '수정' : '추가'}
          isPopupVisible={isProcessVisible}
          setIsPopupVisible={setIsProcessVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          onClickOk={onSaveProcedure}
          okWithoutClose
          isWithCancel
          isWithSave={editProcedureIndex === undefined}
          isWithEdit={editProcedureIndex !== undefined}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              onSaveProcedure();
            }}
          >
            <LabelImageWrapper>
              <Label text="사진" isRequired isPopup />
              <AddImageButton
                url={editProcedureIndex !== undefined ? procedures[editProcedureIndex]?.imageUrl : undefined}
                onChange={onChangeProcedureFile}
                isRequiredEmpty={isEmptyProcedureFile}
              />
            </LabelImageWrapper>
            <LabelInput
              text="제목"
              placeholder="제목을 입력해주세요"
              value={procedure}
              onChange={onChangeProcedure}
              isRequiredEmpty={isEmptyProcedure}
              maxLength={200}
              isRequired
              isPopup
            />
          </form>
        </DefaultPopup>
      )}
      {/* 7-2 추가 버튼 클릭하는 경우, 3번 팝업 */}
      {isBeforeVisible && (
        <DefaultPopup
          title={editBeforeAndAfterIndex !== undefined ? '수정' : '추가'}
          isPopupVisible={isBeforeVisible}
          setIsPopupVisible={setIsBeforeVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          onClickOk={onSaveBeforeAndAfter}
          okWithoutClose
          isWithCancel
          isWithSave={editBeforeAndAfterIndex === undefined}
          isWithEdit={editBeforeAndAfterIndex !== undefined}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              onSaveBeforeAndAfter();
            }}
          >
            <PopupImageWrapper>
              <FlexWrapper>
                <Label text="사진" isRequired isPopup />
                <ImageTextWrapper style={{ margin: '0 20px 0 0' }}>
                  <AddImageButton
                    url={
                      editBeforeAndAfterIndex !== undefined
                        ? beforeAndAfters[editBeforeAndAfterIndex]?.beforeImageUrl
                        : undefined
                    }
                    onChange={onChangeBeforeFile}
                    notext
                  />
                  <p>시술 전</p>
                </ImageTextWrapper>
                <ImageTextWrapper>
                  <AddImageButton
                    url={
                      editBeforeAndAfterIndex !== undefined
                        ? beforeAndAfters[editBeforeAndAfterIndex]?.afterImageUrl
                        : undefined
                    }
                    onChange={onChangeAfterFile}
                    notext
                  />
                  <p>시술 후</p>
                </ImageTextWrapper>
              </FlexWrapper>
              {(isEmptyBeforeFile || isEmptyAfterFile) && (
                <HelperText style={{ margin: '5px 0 0 130px' }}>필수 입력 값입니다.</HelperText>
              )}
            </PopupImageWrapper>
            <LabelInput
              text="제목"
              placeholder="예)[20대] 김**님"
              value={beforeAndAfter}
              onChange={onChangeBeforeAndAfter}
              isRequiredEmpty={isEmptyBeforeAndAfter}
              maxLength={200}
              isRequired
              isPopup
            />
          </form>
        </DefaultPopup>
      )}
      {/* 8-2 추가 버튼 클릭하는 경우 팝업 */}
      {isFaqVisible && (
        <DefaultPopup
          title={editFaqIndex !== undefined ? '수정' : '추가'}
          isPopupVisible={isFaqVisible}
          setIsPopupVisible={setIsFaqVisible}
          setIsConfirmVisible={() => {}}
          setIsEditVisible={() => {}}
          onClickOk={onSaveFaq}
          okWithoutClose
          isWithCancel
          isWithSave={editFaqIndex === undefined}
          isWithEdit={editFaqIndex !== undefined}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              onSaveFaq();
            }}
          >
            <LabelInput
              text="제목"
              placeholder="제목을 입력해주세요."
              value={faqTitle}
              onChange={onChangeFaqTitle}
              isRequiredEmpty={isEmptyFaqTitle}
              maxLength={200}
              isRequired
              isPopup
            />
            <LabelTextarea
              text="내용"
              placeholder="내용을 입력해주세요."
              value={faqContents}
              onChange={onChangeFaqContents}
              isRequiredEmpty={isEmptyFaqContents}
              maxLength={2000}
              isRequired
              isPopup
            />
          </form>
        </DefaultPopup>
      )}
      {/* 시술 상세 정보 팝업 끝 */}
      {isConfirmPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmPopupVisible}
          setIsPopupVisible={setIsConfirmPopupVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default TreatEdit;
