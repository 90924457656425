import { StyledTag } from './styles';

/**
 * 예약 상태
 * - 예약
 * - 취소
 * - 완료
 * - 노쇼
 * - 미처리
 */
interface InnterTableTagTagProps {
  status: any;
  children?: any;
}

const InnterTableTag = ({ status, children }: InnterTableTagTagProps) => {
  return <StyledTag status={status}>{children}</StyledTag>;
};

export default InnterTableTag;
