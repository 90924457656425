import React from "react";

import Authentication from "../api/Authentication";
import Hospital from "../api/Hospital";
import Doctor from "../api/Doctor";
import Treatment from "../api/Treatment";
import Category from "../api/Category";
import File from "../api/File";
import Review from "../api/Review";
import Counsel from "../api/Counsel";
import Appointment from "../api/Appointment";
import Translation from '../api/Translation';

export interface ApiContextType {
  authentication: typeof Authentication;
  file: typeof File;
  hospital: typeof Hospital;
  doctor: typeof Doctor;
  treatment: typeof Treatment;
  category: typeof Category;
  review: typeof Review;
  counsel: typeof Counsel;
  appointment: typeof Appointment;
  translation: typeof Translation;
}

export let ApiContext = React.createContext<ApiContextType>(null!);

export const useApi = () => {
  return React.useContext(ApiContext);
}
