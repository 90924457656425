import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { StyledSider, StyledMenu, StyledLink, Unread } from './styles';
import {
  MedicineBoxOutlined,
  CommentOutlined,
  FormOutlined,
  NotificationOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

interface LNBProps {
  defaultMenu?: string[];
  defaultOpenKeys?: string[];
  isNewVisible?: boolean;
}

// 페이지 전체 레이아웃 우측 Side Navigation Bar
const LNB = ({ defaultMenu, defaultOpenKeys, isNewVisible }: LNBProps) => {
  const [menu, setMenu] = useState<string[] | undefined>(defaultMenu ?? []);
  const [openKeys, setOpenKeys] = useState<string[] | undefined>(defaultOpenKeys ?? []);

  const { SubMenu, Item } = Menu;

  const onClick = (e: any) => {
    localStorage.removeItem('currentRoomId');
    setMenu([e.key]);
  };

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  };

  useEffect(() => setMenu(defaultMenu), [defaultMenu]);
  useEffect(() => setOpenKeys(defaultOpenKeys), [defaultOpenKeys]);

  return (
    <StyledSider>
      <StyledMenu
        mode="inline"
        onClick={onClick}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['1']}
        selectedKeys={menu}
        openKeys={openKeys}
      >
        <SubMenu key="sub1" title="병원 관리" icon={<MedicineBoxOutlined />}>
          <Item key="1">
            <Link to="/management/hospital">병원 정보</Link>
          </Item>
          <Item key="2">
            <Link to="/management/doctor">의료진 관리</Link>
          </Item>
          <Item key="3">
            <Link to="/management/treat">시술 관리</Link>
          </Item>
        </SubMenu>
        <SubMenu key="sub2" title="예약 관리" icon={<CalendarOutlined />}>
          <Item key="4">
            <Link to="/reservation/status">예약 현황</Link>
          </Item>
          <Item key="5">
            <Link to="/reservation/inquiry">예약 조회</Link>
          </Item>
          <Item key="6">
            <Link to="/reservation/settings">예약 설정</Link>
          </Item>
        </SubMenu>
        <Item key="7" icon={<CommentOutlined />}>
          <Link to="/consultation">
            1:1 상담
            {isNewVisible ? <Unread>N</Unread> : ''}
          </Link>
        </Item>
        <Item key="8" icon={<FormOutlined />}>
          <Link to="/review">리뷰 관리</Link>
        </Item>
        <Item key="9" icon={<NotificationOutlined />}>
          <a href="https://quilt-basketball-675.notion.site/_-80e8d2c6cf0649eaade481dae0a70c1f" target="_blank">
            공지사항
          </a>
        </Item>
      </StyledMenu>
      <StyledLink
        href="https://docs.google.com/forms/d/13Cl58c2H-WPyObSgq_CEpIy1X3sqNNDwl7CwGz9yomI/edit"
        target="_blank"
      >
        서비스 문의
      </StyledLink>
    </StyledSider>
  );
};

export default React.memo(LNB);
