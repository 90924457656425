import { useCallback, useEffect, useState } from 'react';
import { Rate } from 'antd';
import { ContentWrapper, Table, TransButton } from './styles';
import { useApi } from '../../../../contexts/ApiContext';
import { ReviewDetailItem } from '../../../../model';
import { Stars } from '../styles';
import { DetailImageWrapper } from '../../../Management/Treat/TreatEdit/styles';
import DefaultImage from '../../../../components/DefaultImage';
import Image from '../../../../components/Image';
import moment from 'moment';

interface reviewTableProps {
  reviewId: string;
}

const ReviewTable = (props: reviewTableProps) => {
  const api = useApi();
  const dateFormat = 'YYYY. MM. DD.';

  // 리뷰 상세
  const [reviewDetail, setReviewDetailData] = useState<ReviewDetailItem>();
  const [isTranslateVisible, setIsTranslateVisible] = useState<boolean>(false);
  const [translatedText, setTranslatedText] = useState<string>();

  // 리뷰 상세 조회
  const getReviewDetail = useCallback(async () => {
    const data = (await api.review.getReviewDetail(props.reviewId)).data;

    setReviewDetailData(data);
  }, [api.review, props.reviewId]);

  const onTranslate = useCallback(async (text: string | undefined) => {
    setIsTranslateVisible(p => !p)
    const textKey = reviewDetail?.reviewId ? reviewDetail?.reviewId : ''
    const data = (await api.translation.transalate(textKey , text ? text : '')).data
    setTranslatedText(data.payload.translatedText)
  }, [api.translation, reviewDetail?.reviewId]);

  // 리뷰 상세 호출
  useEffect(() => {
    getReviewDetail().then().catch();
  }, [getReviewDetail]);

  return (
    <Table>
      <tbody>
        <tr>
          <th style={{ width: '100px' }}>작성일</th>
          <td colSpan={3}>{moment(reviewDetail?.wroteAt).format(dateFormat)}</td>
        </tr>
        <tr>
          <th>시술명</th>
          <td>{reviewDetail?.treatmentName}</td>
          <th>별점</th>
          <td>
            <Stars>
              <Rate disabled value={reviewDetail?.rating} />
              <span>{reviewDetail?.rating}</span>
            </Stars>
          </td>
        </tr>
        <tr>
          <th>성명</th>
          <td>{reviewDetail?.userName}</td>
          <th>이메일</th>
          <td>{reviewDetail?.userEmail}</td>
        </tr>
        <tr>
          <th>내용</th>
          <td colSpan={3} style={{ whiteSpace: 'pre-wrap' }}>
            {reviewDetail?.contents ? (
              <ContentWrapper>
                {isTranslateVisible ? <p lang="ko">{translatedText}</p> : <p>{reviewDetail?.contents}</p>}
                <TransButton onClick={() => onTranslate(reviewDetail?.contents)}>
                  {isTranslateVisible ? '원문보기' : '번역보기'}
                </TransButton>
              </ContentWrapper> ) : ''
            }
          </td>
        </tr>
        <tr>
          <th>사진</th>
          <td colSpan={3}>
            <DetailImageWrapper>
              {reviewDetail?.imageUrls.map((imageUrl, index) => {
                return imageUrl ? <Image key={index} src={imageUrl} /> : <DefaultImage />;
              })}
            </DetailImageWrapper>
          </td>
        </tr>
        {reviewDetail?.domestic && (
          <tr>
            <th>영수증 인증</th>
            <td colSpan={3}>
              <DetailImageWrapper>
                {reviewDetail?.receiptImageUrl ? <Image src={reviewDetail?.receiptImageUrl} /> : <DefaultImage />}
              </DetailImageWrapper>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ReviewTable;
