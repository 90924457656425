import { useCallback, useEffect, useMemo, useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import { useApi } from '../contexts/ApiContext';
import { useAuth } from '../contexts/AuthContext';
import { SocketContext, SocketContextType } from '../contexts/SocketContext';
import { CounselRoomItem, HospitalAgentAccountResponse } from '../model';

let id = 1;

export enum MessageType {
  PING,
  CONNECTED,
  RESULT,
  UNKNOWN,
}

export enum MethodType {
  METHOD = "method",
  SUBSCRIPTION = "sub",
}

export interface MethodCall {
  msg: string;
  method: string;
  id: string
  params: any[];
}

export interface User {
  id: string;
  token: string;
  tokenExpires: any;
  type: string;
}

const SocketContextProvider: React.FunctionComponent<any> = (props) => {
  const auth = useAuth();
  const api = useApi();

  const accessToken = useMemo(() => auth.accessToken, [auth.accessToken]);
  const socket = useWebSocket(process.env.REACT_APP_ROCKET_CHAT_URL ?? "");
	const {sendJsonMessage, readyState, lastJsonMessage} = socket;

  const [methodCalls] = useState<MethodCall[]>([]);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);

  // 로그인한 로켓챗 유저 정보
  const [user, setUser] = useState<User>();

  // 로켓챗 에이전트 정보
  const [agent, setAgent] = useState<HospitalAgentAccountResponse>();

  // 채팅 가능 여부
  const [status, setStatus] = useState<boolean>(false);

  // 채팅방 목록
  const [rooms, setRooms] = useState<CounselRoomItem[]>([]);

  // 에이전트 호출 api
  const getHospitalAgent = useCallback(async () => {
    const data = (await api.hospital.getHospitalAgent()).data;

    setAgent(data);
  }, [api.hospital]);

  // 채팅방 목록 api
  const getCounselRooms = useCallback(async (page: number, size: number, name?: string) => {
    if(page === 0) {
      setRooms([])
    }
    const data = (await api.counsel.getCounselRooms(page, size, name)).data;

    if (data.rooms.length > 0) {
      setUnreadRoom(data.rooms)
      setRooms((prev) => [...prev].concat(data.rooms));

      return true;
    } else {
      return false;
    }
  }, [api.counsel]);

  const setUnreadRoom = (rooms: CounselRoomItem[]) => {
    const newMessageRoomIds = localStorage.getItem("newMessageRoomIds")
    if(newMessageRoomIds !== null && newMessageRoomIds !== "") {
      const roomIdArry = newMessageRoomIds?.split(',')
      console.log(roomIdArry)
      for(const roomId of roomIdArry!) {
        for(const targetRoom of rooms) {
          if(targetRoom.roomId === roomId) {
            targetRoom.isUnread = true
          }
        }
      }
    }
  }

  const getRefreshCounselRooms = useCallback(async (page: number, size: number, name?: string) => {
    const data = (await api.counsel.getCounselRooms(page, size, name)).data;
    setUnreadRoom(data.rooms)
    setRooms(data.rooms);

    return true;
  }, [api.counsel]);

  // 응답 받은 메세지의 타입 구분
  const getMessageType = useCallback((message: any): MessageType => {
    if (message.hasOwnProperty("msg")) {
      switch(message["msg"]) {
        case 'ping': return MessageType.PING;
        case 'connected': return MessageType.CONNECTED;
        case 'result': return MessageType.RESULT;
        default: return MessageType.UNKNOWN;
      }
    }
    
    return MessageType.UNKNOWN;
  }, []);
          
  // 메세지 발송
  const sendMessage = useCallback((message: any) => {
    sendJsonMessage(message);
  }, [sendJsonMessage]);

  // 대화방 메시지 구독
  const streamRoomMessage = useCallback((userId: String) : void => {
    sendSubscribeMessage(userId)
  }, [sendMessage]);

  // 핸드쉐이킹 메세지 발송
  const sendHandshakeMessage = useCallback((): void => {
    sendMessage({
      "msg": "connect",
      "version": "1",
      "support": ["1"]
    });
  }, [sendMessage])

  // 상담 메시지 발송
  const sendCounselMessage = useCallback((roomId: String, message: String) : void => {
    sendMethodMessage(
      MethodType.METHOD, 
      "sendMessage",
      [
        {
            "_id": id.toString,
            "rid": roomId,
            "msg": message
        }
      ]
    );

    id++;
  }, [sendMessage])

  // 핑퐁 응답
  const replyPong = useCallback((): void => {
    sendMessage({
      "msg": "pong"
    });
  }, [sendMessage])

  // method 형 메소드 콜 메세지
  const sendMethodMessage = useCallback((methodType: MethodType, method: string, params: any[]) => {
    const data = {
      "msg": methodType.toString(),
      "method": method,
      "id": id.toString(),
      "params": params,
    };
    sendMessage(data);

    methodCalls[id] = data;
    id++;
  }, [methodCalls, sendMessage]);

  // 대화방 관련 event subscribe request message
  const sendSubscribeMessage = useCallback((userId: String) => {
    const data = {
      "msg": MethodType.SUBSCRIPTION,
      "id": uuidv4(),
      "name": "stream-notify-user",
      "params":[
        userId + "/rooms-changed",
        false
      ]
    };

    sendMessage(data);
  },[])

  // 활성 상태 변경 - 서버 api 호출
  const modifyStatus = useCallback(async (status: boolean) => {
    sendMethodMessage(MethodType.METHOD, "UserPresence:setDefaultStatus", [status ? "online" : "offline"]);
    setStatus(status);

    if (status) {
      await api.hospital.activateCounsel();
    } else {
      await api.hospital.deactivateCounsel();
    }
  }, [api.hospital, sendMethodMessage]);

  // result 처리
  const handleMessageResult = useCallback((message: any) => {
    if (message.hasOwnProperty('id')) {
      const id = message["id"];
      const call = methodCalls[id];

      if (call) {
        switch (call.method) {
          case "login": {
            if (!message.hasOwnProperty('error') && message.hasOwnProperty('result')) {
              setIsLogin(true);
              setUser(message["result"]);
              setUserToLocalstorage(message["result"])

              const rocketChatUserId = localStorage.getItem("rocket.chat-user-id")
              if(rocketChatUserId) {
                streamRoomMessage(rocketChatUserId)
              }
            }
            break
          }
          case "logout": {
            setIsLogin(false);
            removeUserFromLocalstorage()
            break
          }
        }
      }
    }
  }, [methodCalls]);

  const setUserToLocalstorage = (user: User) => {
    localStorage.setItem("rocket.chat-user-id", user?.id)
    localStorage.setItem("rocket.chat-auth-token", user?.token)
  }

  const removeUserFromLocalstorage = () => {
    localStorage.removeItem("rocket.chat-user-id")
    localStorage.removeItem("rocket.chat-auth-token")
    localStorage.removeItem("currentRoomId")
    localStorage.removeItem("newMessageId")
  }

  // 메세지 응답 처리
  const handleMessage = useCallback(() => {
    if (lastJsonMessage !== null) {
      const messageType = getMessageType(lastJsonMessage);
      
      switch (messageType) {
        case MessageType.CONNECTED: setIsConnected(true); break;
        case MessageType.PING: replyPong(); break;
        case MessageType.RESULT: handleMessageResult(lastJsonMessage); break;
      }
    }
  }, [getMessageType, handleMessageResult, lastJsonMessage, replyPong]);
  
  // 연결 후 핸드쉐이크 성립 
  // 연결 후 핸드쉐이크를 하지 않을 경우 연결 자동 종료이므로 필수
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendHandshakeMessage();
    }
  }, [readyState, sendHandshakeMessage]);

  // 에이전트 정보 호출
  useEffect(() => {
    if (accessToken) {
      getHospitalAgent();
    }
  }, [accessToken, getHospitalAgent]);

  // 로그인 로그아웃에 대한 처리
  useEffect(() => {
    if (isConnected && agent) {
      if (accessToken) {
        // 로그인
        sendMethodMessage(
          MethodType.METHOD, 
          "login",
          [
            {
                "user": { "username": agent.agentId },
                "password": {
                    "digest": agent.agentPassword,
                    "algorithm":"sha-256"
                }
            }
          ]
        );
        // 활성 상태 변경
        modifyStatus(true);
      } else {
        // 로그아웃
        sendMethodMessage(
          MethodType.METHOD, 
          "logout",
          [
            {
                "user": { "username": agent.agentId },
                "password": {
                    "digest": agent.agentPassword,
                    "algorithm":"sha-256"
                }
            }
          ]
        );

        // 활성 상태 변경
        modifyStatus(false);

        setIsLogin(false);
        setUser(undefined);
      }
    }
  }, [accessToken, agent, isConnected, modifyStatus, sendMethodMessage]);

  // 메세지 응답에 대한 처리
  useEffect(() => {
    handleMessage();
  }, [handleMessage]);

  let value: SocketContextType = {
    socket: socket,
    isConnected: isConnected,
    isLogin: isLogin,
    user: user,
    rooms: rooms,
    status: status,
    lastJsonMessage: lastJsonMessage,
    modifyStatus: modifyStatus,
    getCounselRooms: getCounselRooms,
    getRefreshCounselRooms: getRefreshCounselRooms,
    sendCounselMessage: sendCounselMessage,
    streamRoomMessage:  streamRoomMessage,
  };

	return (
		<SocketContext.Provider value={value}>
			{props.children}
		</SocketContext.Provider>
	);
}

export default SocketContextProvider;