import React from 'react';
import { SelectProps } from "antd";
import Label from "../Label";
import Select from "../Select";
import { FlexWrapper } from "./styles";

interface LabelSelectProps extends SelectProps {
	text: string;
	isRequired?: boolean;
	isRequiredEmpty?: boolean;
	optionList: any[];
	style?: any;
	disabled?: boolean;
}

const LabelSelect = ({ value, text, isRequired, isRequiredEmpty, optionList, style, onChange, disabled }: LabelSelectProps) => {
	return (
		<FlexWrapper>
			<Label text={text} isRequired={isRequired} />
			<Select onChange={onChange} value={value} optionList={optionList} style={style} isRequiredEmpty={isRequiredEmpty} disabled={disabled} />
		</FlexWrapper>
	);
};

export default React.memo(LabelSelect);
