import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* ---------------------------------------------------------------------------------------------------- */
/* Custom */

:root{
	--font-main:'Noto Sans KR', sans-serif;
}

* {
	box-sizing: border-box;
	margin: 0;
  padding:0;
  font-family: var(--font-main);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
  font-family: var(--font-main);
	font-size: 16px;
	--antd-wave-shadow-color: ${props => props.theme.accent};
}

body {
  font-family: var(--font-main);
	color:${props => props.theme.primary};
}

a {
	text-decoration: none;
	color: inherit;
}

button,
input,
select,
textarea {
	border: 0;
	background-color: transparent;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

a,
button {
	cursor: pointer;
}

button {
	padding: 0;
}

ul,
ol {
	list-style: none;
	padding-left: 0;
}

table{
	font-size:15px;
}

th,
td{
	text-align:center !important;
}

th div.text-left,
td div.text-left{
	text-align:left !important;
}


h1,h2,h3,h4,h5,h6{
	color:${props => props.theme.primary};
}

td.ant-table-cell{
	color:${props => props.theme.primary};
}


.ant-layout{
background-color:${props => props.theme.white}
}

.ant-popover-inner-content {
    padding: 10px 12px;
  }


/*  breadcrumb */
.ant-breadcrumb-link{
	font-weight:500;
}

a.ant-breadcrumb-link:hover{
	color:${props => props.theme.accent};
}

.ant-breadcrumb > span:last-child a{
	color:${props => props.theme.accent};
}

/* 테이블 filter 관련*/
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner{
	border-color:${props => props.theme.accent};
}

.ant-checkbox-checked::after{
	border: 1px solid ${props => props.theme.accent};
}
.ant-checkbox-checked .ant-checkbox-inner{
	background-color:${props => props.theme.accent};
	border-color:${props => props.theme.accent};
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
	color:${props => props.theme.accent};
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
	background-color:${props => props.theme.submenuItem_bg};
}

.ant-btn-link{
	color:${props => props.theme.primary};
	
	&:hover{
	color:${props => props.theme.accentLight};

	}
}

.ant-btn-primary:hover,
.ant-btn-primary:focus{
	background:${props => props.theme.accentDark};
	border-color:${props => props.theme.accentDark};
}

.ant-table-filter-trigger.active{
	color:${props => props.theme.accent};
}
/* 모달 */

.ant-modal-body{
	padding:36px;
}

/* antd table */

.ant-table-thead > tr > th{
	color:${props => props.theme.primary};
}

.ant-table-tbody > tr > td{
	vertical-align:middle;
}

/* select */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
	padding:0 16px;
}


/* select의 drop down  */
.ant-select-dropdown{
	color: ${props => props.theme.primary};
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
	background-color:rgba(208, 165, 108, 0.1);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
	background-color:rgba(208, 165, 108, 0.4);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
	color:${props => props.theme.primary};
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
	position:relative;
	top:5px;
}

/* range picker */
.ant-picker-dropdown{
	color:${props => props.theme.primary};
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
	background:${props => props.theme.accentLight};
		opacity:0.6;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border: 1px solid ${props => props.theme.accent};
	}

	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
	.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
		background: ${props => props.theme.accent};
	}
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
	.ant-picker-cell-in-view.ant-picker-cell-in-range::before{
		background:${props => props.theme.accentLight};
		opacity:0.6;
	}

	.ant-picker-cell-in-view.ant-picker-cell-range.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before
	.ant-picker-panel > :not(.ant-picker-data-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
	.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
		background:${props => props.theme.accentLight};
		opacity:0.4;
	}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
	background:${props => props.theme.accentLight};
		opacity:0.4;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
	background:${props => props.theme.accentDark};
}


.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
	border-top:1px dashed ${props => props.theme.accent};
	border-bottom:1px dashed ${props => props.theme.accent};
}

tr > .ant-picker-cell-in-view.ant-picker-cell.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after{
	border-left:1px dashed  ${props => props.theme.accent};
}

	tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
	tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
	.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
	.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
	.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after{
		border-right:1px dashed  ${props => props.theme.accent};
	}

/* TimePicker - RangePicker */
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner{
		color: ${props => props.theme.primary};
	}
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
		background: ${props => props.theme.submenuItem_bg} ;
	}

	.ant-btn-primary {
		color: ${props => props.theme.white};
    border-color: ${props => props.theme.accent};
    background: ${props => props.theme.accent};
    text-shadow: unset;
    box-shadow: unset;
  }
	
`;

export default GlobalStyle;
