import { SubHeading, Table } from '../styles';
import { AppointmentListItem, PaymentDetails } from '../../../../model';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../../../../contexts/ApiContext';

export interface UserRezPaymentTableProps {
  item?: AppointmentListItem
}
const UserRezPaymentTable = ({item}: UserRezPaymentTableProps) => {
  const api = useApi();

  const [payment, setPayment] = useState<PaymentDetails>();

  const loadPaymentDetail = useCallback(async (appointmentId: string | undefined, paymentId: string | undefined) => {
    const payment = (await api.appointment.paymentDetail(appointmentId, paymentId)).data

    setPayment(payment.payload);
  }, [api.appointment]);

  useEffect(() => {
    loadPaymentDetail(item?.appointmentId, item?.paymentId)
      .then().catch()
  }, [item?.appointmentId, item?.paymentId, loadPaymentDetail]);

  return (
    <>
      <SubHeading>결제 정보</SubHeading>
      <Table>
        <tbody>
          <tr>
            <th>결제/취소번호</th>
            <td>{payment?.paymentNumber}</td>
          </tr>
          <tr>
            <th>결제/취소일</th>
            <td>{payment?.executionDateTime}</td>
            <th>결제방법</th>
            <td>{payment?.paymentMethod}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default UserRezPaymentTable;
