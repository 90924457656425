import {
  ContentHeading,
  DefaultPopup,
  Divider,
  LabelSelect,
  LabelToggle,
  PageHeading,
  TableDefault,
} from '../../../components';
import ToggleButton from '../../../components/ToggleButton';
import EditButton from '../../../components/InnerTdButton/EditButton';
import { useCallback, useEffect, useState } from 'react';
import RezRadioTime from './RezRadioTime';
import { StyledLayout } from './styles';
import { useApi } from '../../../contexts/ApiContext';
import {
  AppointmentDoctorItem,
  DailySchedule,
  ListResponse,
} from '../../../model';
const RezSettings = () => {
  const api = useApi();

  const [useAppointment, setUseAppointment] = useState<boolean>(true);
  const [appointmentTimeInterval, setAppointmentTimeInterval] = useState<string>('EVERY_30_MIN');
  const [sameDayAvailability, setSameDayAvailability] = useState<string>('HALF_HOUR_AGO');

  const [appointmentDoctors, setAppointmentDoctors] = useState<ListResponse<AppointmentDoctorItem>>();
  const [doctorItems, setDoctorItems] = useState<AppointmentDoctorItem[]>();
  const [mangeDoctorId, setMangeDoctorId] = useState<string>();

  const [doctorNameAndPosition, setDoctorNameAndPosition] = useState<string>();
  const [weeklySchedule, setWeeklySchedule] = useState<DailySchedule[]>([]);

  const [isEditVisible, setIsEditVisible] = useState(false);

  const timeIntervalOptions = [
    {value: 'EVERY_30_MIN', text: '30분 간격'},
    {value: 'EVERY_1_HOUR', text: '1시간 간격'},
    {value: 'EVERY_1_HOUR_30_MIN', text: '1시간 30분 간격'},
    {value: 'EVERY_2_HOUR', text: '2시간 간격'}
  ];

  const sameDayAvailabilityOptions = [
    {value: 'HALF_HOUR_AGO', text: '30분 전'},
    {value: 'ONE_HOUR_AGO', text: '1시간 전'},
    {value: 'ONE_HALF_HOUR_AGO', text: '1시간 30분 전'},
    {value: 'TWO_HOUR_AGO', text: '2시간 전'},
    {value: 'TREE_HOUR_AGO', text: '3시간 전'},
    {value: 'NOT_AVAILABLE', text: '당일 예약 불가'}
  ];

  const columns = [
    { title: '노출 순서',
      key: 'id',
      dataIndex: 'id',
      render: (value: any, record: AppointmentDoctorItem, index: number) => {
        return (index + 1);
      }
    },
    { title: '성명', key: 'name', dataIndex: 'name' },
    { title: '직위', key: 'position', dataIndex: 'position' },
    {
      title: '예약 노출 상태',
      key: 'rezCurrentStatus',
      dataIndex: 'rezCurrentStatus',
      render: (value: string, record: AppointmentDoctorItem) => {
        return <ToggleButton checked={record.appointmentAvailability === 'ENABLED'}
                             disabled={!useAppointment}
                             onChange={(checked) => changeDoctorAppointment(checked, record)}/>
      },
    },
    {
      title: '관리',
      key: 'edit',
      dataIndex: 'edit',
      render: (value: any, doctorItem: AppointmentDoctorItem,) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EditButton
            isDisabled={!useAppointment}
            onClick={() => {
              setDoctorNameAndPosition(doctorItem.name + ' ' + doctorItem.position);
              scheduleManagement(doctorItem.doctorId).then().catch();
              setIsEditVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  const loadAppointmentSettings = useCallback(async () => {
    const settings = (await api.appointment.getSettings()).data;

    setUseAppointment(settings.payload.usedService);
    setAppointmentTimeInterval(settings.payload.appointmentIntervalTime);
    setSameDayAvailability(settings.payload.sameDayAvailabilityTime);
  }, [api.appointment])

  const loadAppointmentDoctors = useCallback(async () => {
    const doctors = (await api.appointment.getDoctors()).data;

    setAppointmentDoctors(doctors);
  }, [api.appointment]);

  const applyAppointmentAvailable = useCallback((checked: boolean) => {
    if(checked) {
      api.appointment.activateAppointment().then().catch()
    }
    if(!checked) {
      api.appointment.inactiveAppointment().then().catch()
    }
    setUseAppointment(checked)
  }, [api.appointment]);

  const applyAppointmentTimeInterval = useCallback(async (timeInterval: string) => {
    (await api.appointment.applyAppointmentTimeInterval(timeInterval));

    setAppointmentTimeInterval(timeInterval);
  }, [api.appointment])

  const applySameDayAvailability = useCallback(async (availability: string) => {
    (await api.appointment.applySameDayAvailability(availability));

    setSameDayAvailability(availability);
  }, [api.appointment]);

  const scheduleManagement = useCallback( async (doctorId: string) => {
    const doctorSchedule = (await api.appointment.getDoctorSchedule(doctorId)).data;

    setMangeDoctorId(doctorId);
    setWeeklySchedule([
      { ...doctorSchedule.payload.weeklySchedule.monday, dayOfWeek: '월요일' },
      { ...doctorSchedule.payload.weeklySchedule.tuesday, dayOfWeek: '화요일' },
      { ...doctorSchedule.payload.weeklySchedule.wednesday, dayOfWeek: '수요일' },
      { ...doctorSchedule.payload.weeklySchedule.thursday, dayOfWeek: '목요일' },
      { ...doctorSchedule.payload.weeklySchedule.friday, dayOfWeek: '금요일' },
      { ...doctorSchedule.payload.weeklySchedule.saturday, dayOfWeek: '토요일' },
      { ...doctorSchedule.payload.weeklySchedule.sunday, dayOfWeek: '일요일' },
    ]);
  }, [api.appointment]);

  const onChangeSchedule = (index: number, item: DailySchedule) => {
    setWeeklySchedule(prev => {
      prev[index] = item;
      return [...prev];
    });
  }

  const saveDoctorSchedule = useCallback(async () => {
    (await api.appointment.saveDoctorSchedule(mangeDoctorId, weeklySchedule));
  }, [api.appointment, mangeDoctorId, weeklySchedule]);

  const changeDoctorAppointment = useCallback(async (checked: boolean, doctorItem: AppointmentDoctorItem) => {
    const doctorId = doctorItem.doctorId;
    if(checked) {
      (await api.appointment.activateDoctorAppointment(doctorId).then().catch())
    } else {
      (await api.appointment.inactiveDoctorAppointment(doctorId).then().catch())
    }
    loadAppointmentDoctors().then().catch();
  },[api.appointment, loadAppointmentDoctors]);

  useEffect(() => {
    loadAppointmentSettings().then().catch();
  }, [loadAppointmentSettings]);

  useEffect(() => {
    loadAppointmentDoctors().then().catch();
  }, [loadAppointmentDoctors])

  useEffect(() => {
    const itemsContainKey = appointmentDoctors?.items.map(row => {
      return { key: row.doctorId, ...row };
    });
    if(itemsContainKey) {
      setDoctorItems(itemsContainKey);
    }
  }, [appointmentDoctors?.items]);

  return (
    <>
      <PageHeading>예약 설정</PageHeading>
      <StyledLayout>
        <section>
          <ContentHeading isRequired>예약 정보</ContentHeading>
          <LabelToggle text="예약 서비스 사용"
                       checked={useAppointment}
                       onChange={applyAppointmentAvailable} />
          <LabelSelect text="예약 시간 설정"
                       disabled={!useAppointment}
                       optionList={timeIntervalOptions}
                       value={appointmentTimeInterval}
                       onChange={applyAppointmentTimeInterval}/>
          <LabelSelect text="당일 예약 가능 시간"
                       disabled={!useAppointment}
                       optionList={sameDayAvailabilityOptions}
                       value={sameDayAvailability} onChange={applySameDayAvailability}/>
        </section>
        <Divider />
        <section>
          <ContentHeading>의료진 예약 가능 시간 설정</ContentHeading>
          <TableDefault columns={columns} dataSource={doctorItems} />
        </section>
      </StyledLayout>
      {isEditVisible && (
        <DefaultPopup
          title="편집"
          isPopupVisible={isEditVisible}
          setIsPopupVisible={setIsEditVisible}
          onClickOk={saveDoctorSchedule}
          isWide
          isWithSave
          isWithCancel
        >
          <section style={{ marginBottom: 50 }}>
            <>
              <ContentHeading>{doctorNameAndPosition}</ContentHeading>
              {weeklySchedule?.map((item, index) => (
                <RezRadioTime key={index} dayOfWeek={item.dayOfWeek}
                              scheduleType={item.type}
                              morningStartAt={item.morningStartAt}
                              morningEndAt={item.morningEndAt}
                              afternoonStartAt={item.afternoonStartAt}
                              afternoonEndAt={item.afternoonEndAt}
                              onChangeScheduleType={event => {
                                item.type = event.target.value;
                                onChangeSchedule(index, item);
                              }}
                              onChangeMorningTime={values => {
                                item.morningStartAt = values?.[0]?.format('HH:mm') ?? item.morningStartAt;
                                item.morningEndAt = values?.[1]?.format('HH:mm') ?? item.morningEndAt;
                                onChangeSchedule(index, item);
                              }}
                              onChangeAfternoonTime={values => {
                                item.afternoonStartAt = values?.[0]?.format('HH:mm') ?? item.afternoonStartAt;
                                item.afternoonEndAt = values?.[1]?.format('HH:mm') ?? item.afternoonEndAt;
                                onChangeSchedule(index, item);
                              }}
                />
              ))}
            </>
          </section>
        </DefaultPopup>
      )}
    </>
  );
};

export default RezSettings;
