import styled from "styled-components";
import { fontStyle16 } from "../../../styles/mixins";

export const StyledLabel = styled.div<{isPopup?: boolean}>`
	min-width: ${(props) => (props.isPopup ? "130px" : "220px")};

	label {
		${fontStyle16};
		font-weight: 500;
	}

	span {
		color: ${(props) => props.theme.red};
	}
`;
