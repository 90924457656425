import {
  StatusCard,
  Divider,
  StatusSection,
  Texts,
  InfoSection,
  Infos,
  Details,
  StatusWrapper,
  StatusItem,
  StatusDivider,
} from './styles';
import submit_default from '../../assets/submit-default.svg';
import reserve_default from '../../assets/reserve-default.svg';
import reserve_active from '../../assets/reserve-active.svg';
import reserve_cancel from '../../assets/reserve-cancel.svg';
import review_default from '../../assets/review-default.svg';
import review_active from '../../assets/review-active.svg';
import visit_default from '../../assets/visit-default.svg';
import visit_active from '../../assets/visit-active.svg';

interface RezStatusCardProps {
  id: string;
  rezStatus: string;
  hospitalName: string;
  doctorName: string;
  doctorPosition: string;
  treatmentName: string;
  visitDate?: string;
  cancelDate?: string;
  cancelTime?: string;
  appointmentDate?: string;
}

const mockRezStatusCard: RezStatusCardProps = {
  id: '1',
  rezStatus: 'sample',
  hospitalName: '메디컬 오 한의원',
  doctorName: '장동훈',
  doctorPosition: 'Doctor',
  treatmentName: '모공 리액터',
  visitDate: '2022.07.25 13:00',
  cancelDate: '2023. 02. 13 18:32',
  appointmentDate: '2022. 11. 23 13:00',
};

interface DisplayedStattusList {
  [key: string]: { name: string; icon: string }[];
}

interface StatusListItem {
  status: string;
  displayedStatus: { name: string; icon: string }[];
  activeIdx: number;
  title: string;
  subTitle: string;
  desc: string;
}

// sample: 예시
// reserve: 예약
// cancel: 예약 취소
// noshow: 노쇼 취소
// visit: 방문
// review: 리뷰 작성 시
// unreview: 리뷰 미작성 시
// remind: 리뷰 미작성 15일 후 재알림
const displayedStatusList: DisplayedStattusList = {
  sample: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_default },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_default },
  ],
  reserve: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_active },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_default },
  ],
  cancel: [
    { name: 'Submit', icon: submit_default },
    { name: 'Cancel', icon: reserve_cancel },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_default },
  ],
  noshow: [
    { name: 'Submit', icon: submit_default },
    { name: 'Cancel', icon: reserve_cancel },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_default },
  ],
  visit: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_default },
    { name: 'Visit', icon: visit_active },
    { name: 'Review', icon: review_default },
  ],
  review: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_default },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_active },
  ],
  unreview: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_default },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_active },
  ],
  remind: [
    { name: 'Submit', icon: submit_default },
    { name: 'Reserve', icon: reserve_default },
    { name: 'Visit', icon: visit_default },
    { name: 'Review', icon: review_active },
  ],
};

const statusList: StatusListItem[] = [
  {
    status: 'sample',
    displayedStatus: displayedStatusList['sample'],
    activeIdx: -1,
    title: 'Sample Title Text',
    subTitle: 'Sample Subtitle Text',
    desc: 'Sample description text.  Sample description text. Sample description text. Sample description text text. ',
  },
  {
    status: 'reserve',
    displayedStatus: displayedStatusList['reserve'],
    activeIdx: 1,
    title: 'Appointment completed',
    subTitle: 'Reminder will be sent on visit date',
    desc: 'If you cancel the appointment 2 days before the visit, you will get a full refund. If you cancel the appointment 1 day before the visit, you will get a 50% refund. You cannot cancel the appointment on the day of the visit.',
  },
  {
    status: 'cancel',
    displayedStatus: displayedStatusList['cancel'],
    activeIdx: 1,
    title: 'Appointment canceled',
    subTitle: '',
    desc: 'Based on the actual situation of each credit card company, the refund time will take 1 to 3 weeks.',
  },
  {
    status: 'noshow',
    displayedStatus: displayedStatusList['noshow'],
    activeIdx: 1,
    title: 'Appointment canceled',
    subTitle: '',
    desc: 'Your appointment has been canceled due to a no-show.',
  },
  {
    status: 'visit',
    displayedStatus: displayedStatusList['visit'],
    activeIdx: 2,
    title: 'Today is my appointment day for the hospital visit',
    subTitle: '',
    desc: 'If you are more than 30 minutes late, the appointment will be automatically canceled and no refund will be given.',
  },
  {
    status: 'review',
    displayedStatus: displayedStatusList['review'],
    activeIdx: 3,
    title: 'How does it feel to come to the hospital?',
    subTitle: '',
    desc: 'For the convenience of our customers, please leave your real reviews on this item. After 30 days from the date of visit, the review function will be closed.',
  },
  {
    status: 'unreview',
    displayedStatus: displayedStatusList['unreview'],
    activeIdx: 3,
    title: 'How does it feel to come to the hospital?',
    subTitle: '',
    desc: 'For the convenience of our customers, please leave your real reviews on this item. After 30 days from the date of visit, the review function will be closed.',
  },
  {
    status: 'remind',
    displayedStatus: displayedStatusList['remind'],
    activeIdx: 3,
    title: 'Please leave a review',
    subTitle: '',
    desc: 'For the convenience of customers, please leave your real reviews of the hospital. After the visit date has passed, the review function will be closed.',
  },
];

const RezStatusCard = () => {
  const cancellationCheck = mockRezStatusCard.rezStatus === 'cancel' || mockRezStatusCard.rezStatus === 'noshow';

  return (
    <StatusCard>
      <StatusSection>
        <StatusWrapper>
          {statusList.map(
            statusItem =>
              mockRezStatusCard.rezStatus === statusItem.status && (
                <>
                  {displayedStatusList[statusItem.status].map((displayItem, idx) => (
                    <>
                      <StatusItem isActive={idx === statusItem.activeIdx}>
                        <div>
                          <img src={displayItem.icon} alt="icon" />
                        </div>
                        <span>{displayItem.name}</span>
                      </StatusItem>
                      {idx <= 2 && <StatusDivider />}
                    </>
                  ))}
                </>
              ),
          )}
        </StatusWrapper>
        <Texts>
          {statusList.map(
            statusItem =>
              mockRezStatusCard.rezStatus === statusItem.status && (
                <>
                  <h2>{statusItem.title}</h2>
                  <span>{statusItem.subTitle}</span>
                  <p>{statusItem.desc}</p>
                </>
              ),
          )}
        </Texts>
      </StatusSection>
      <Divider />
      <InfoSection>
        <Infos>
          <h3>{mockRezStatusCard.hospitalName}</h3>
          <span>
            <strong>{mockRezStatusCard.doctorName}</strong> {mockRezStatusCard.doctorPosition}
          </span>
        </Infos>
        <Details>
          <h4>{mockRezStatusCard.treatmentName}</h4>

          {cancellationCheck && mockRezStatusCard.cancelDate ? (
            <>
              <div>
                Appointment date : <span>{mockRezStatusCard.appointmentDate}</span>
              </div>
              <div>
                Cancellation date : <span>{mockRezStatusCard.cancelDate}</span>
              </div>
            </>
          ) : (
            <div>
              Date of visit: <span>{mockRezStatusCard.visitDate}</span>
            </div>
          )}
        </Details>
      </InfoSection>
    </StatusCard>
  );
};

export default RezStatusCard;
