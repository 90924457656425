import React, { MouseEventHandler, ReactNode } from 'react';
import { Wrapper, HeadingWrapper, Heading, HelperText, Button, GlobalHelperText } from './styles';

interface HeadingProps {
  isRequired?: boolean;
  isHelperText?: boolean;
  hasButton?: boolean;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

const ContentHeading = ({ isRequired, isHelperText, hasButton, onClick, children }: HeadingProps) => {
  return (
    <Wrapper>
      <div>
        <HeadingWrapper>
          <Heading>{children}</Heading>
          {isRequired && <HelperText>*필수입력 항목</HelperText>}
        </HeadingWrapper>
      </div>
      {isHelperText && (
        <GlobalHelperText>
          해외 환자용 앱에 노출되는 정보입니다. 정보 수정을 원하시면 운영 관리자에게 문의하세요.
        </GlobalHelperText>
      )}
      {hasButton && (
        <Button isFilled isMiddle onClick={onClick}>
          등록
        </Button>
      )}
    </Wrapper>
  );
};

export default React.memo(ContentHeading);
