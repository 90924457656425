import React, { MouseEventHandler } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { StyledButton } from '../styles';

interface EditButtonProps {
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
}

const EditButton = (props: EditButtonProps) => {
  return (
    <StyledButton type="button" onClick={props.onClick} disabled={props.isDisabled}>
      <EditOutlined style={{ fontSize: '18px' }} />
    </StyledButton>
  );
};

export default React.memo(EditButton);
