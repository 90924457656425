import { ReactNode, useCallback, useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Select from "../../../components/Inputs/Select";
import TableDefault from "../../../components/Tables/TableDefault";
import InnerTdButton from "../../../components/InnerTdButton";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import { StyledLayout, FlexWrapper } from "./styles";
import { useApi } from "../../../contexts/ApiContext";
import { TreatmentItemForPartners, ShiftDirection, TreatmentRepresentative } from "../../../model";
import { ItemDefault } from "../../../components/Inputs/Select";
import { ColumnsType } from "antd/lib/table";
import UpDownButton from "../../../components/UpDownButton";

const popupText = {	
	delete:
		<>
			삭제 하시겠습니까?<br />
			삭제된 정보는 복구가 불가능하며<br />
			앱에 바로 반영됩니다.
		</>,
};

const Treat = () => {
	const api = useApi();

	// 팝업 관리
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	// 팝업 설정
	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk: VoidFunction, handleCancel: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 시술 목록
	const getTreatments = useCallback(async () => {
		const data = (await api.treatment.getTreatments()).data;
		setTreatsData(data.data);
	}, [api.treatment]);

	// 시술 순서 변경
	const swapTreatementOrder = useCallback(async (treatmentId: string, direction: ShiftDirection) => {
		const data = (await api.treatment.swapTreatment(treatmentId, direction)).data;

		if (data.modified) {
			getTreatments();
		}
	}, [api.treatment, getTreatments]);

	// 시술 삭제
	const deleteTreatment = useCallback(async (treatmentId: string) => {
		const data = (await api.treatment.deleteTreatment(treatmentId)).data;

		if(data.deleted) {
			getTreatments();
		}
	}, [api.treatment, getTreatments]);

	// 삭제
	const onDelete = useCallback((item: TreatmentItemForPartners) => {
		const handleOk = () => async () => deleteTreatment(item.treatmentId);
		const handleCancel = () => async () => setIsConfirmVisible(false);

		setPopupData(popupText.delete, true, handleOk, handleCancel);
		setIsConfirmVisible(true);
	}, [deleteTreatment]);

	// 시술 목록
	const [treatsData, setTreatsData] = useState<TreatmentItemForPartners[]>([]);
	const [treats, setTreats] = useState<TreatmentItemForPartners[]>([]);

	// 대표 시술 여부
	const [representativeOptionList] = useState<ItemDefault[]>([
		{ value: TreatmentRepresentative.NORMAL, text: "일반시술"},
		{ value: TreatmentRepresentative.REPRESENTATIVE, text: "대표시술"},
	]);
	const onChangeRepresentative = useCallback(async (representative: string, treatmentId: string) => {		
		let data: any | undefined;
		if(representative == "NORMAL") {
			data = (await api.treatment.changeNormallyTreatment(treatmentId)).data;
		}
		if(representative == "REPRESENTATIVE") {
			data = (await api.treatment.changeRepresentativeTreatment(treatmentId)).data;
		}
		
		if(data.assigned) {
			getTreatments();
		}
	}, [api.treatment, getTreatments]);

	// 시술 목록 칼럼
	const columns: ColumnsType<TreatmentItemForPartners> = [
		{
			title: "순서 변경",
			dataIndex: "change",
			key: "change",
			render: (value: any, record, index: number) => {
				return (
					<>						
						<UpDownButton 
							onClick={() => {
								if (index > 0) {
									swapTreatementOrder(record.treatmentId, ShiftDirection.FORWARD )
								}
							}}
						/>
						<UpDownButton 
							onClick={() => {
								if (index < (treatsData.length - 1)) {
									swapTreatementOrder(record.treatmentId, ShiftDirection.BACKWARD)
								}
							}}
						isDown 
						/>
					</>					
				);
			},
		},
		{
			title: "노출 순서",
			dataIndex: "order",
			key: "order",
			width: 120,
			render: (value: any, record: TreatmentItemForPartners, index: number) => {
				return (index + 1);
			}
		},
		{
			title: "대표 시술 여부",
			dataIndex: "select",
			key: "select",
			render: (value, record) => 
				<Select value={record.representative} optionList={representativeOptionList} style={{ width: "135px", margin: "auto" }} 
					onChange={(value) => {
						onChangeRepresentative(value, record.treatmentId)
					}}/>
		},
		{ title: "시술명", 
			dataIndex: "name", 
			key: "name",
			ellipsis: true },
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "시술 카테고리", dataIndex: "treatmentCategoryName", key: "treatmentCategoryName" },
		{ 
			title: "관리", 
			dataIndex: "treatmentId",
			key: "treatmentId", 
			render: (value, record) => 
				<InnerTdButton 
					isWithEdit 
					isEditLink 
					onClickDelete={() => {onDelete(record)}} 
					link={`/management/treat/${record.treatmentId}`} 
				/> 
		}
	];

	// 시술 목록 호출
	useEffect(() => {
		getTreatments();
	}, [getTreatments]);

	// 시술 목록 데이터 가공
	useEffect(() => {
		const data = treatsData?.map((row) => {
			return { key: row.treatmentId, ...row };
		});

		if (data) {
			setTreats(data);
		}
	}, [treatsData]);

	return (
		<>
			<PageHeading hasLink="시술 추가" link="/management/treat/edit">
				시술 관리
			</PageHeading>

			<StyledLayout>
				<FlexWrapper>
					<span>
						<strong>시술</strong> | 총 <strong>{treatsData.length}</strong>개
					</span>
				</FlexWrapper>
				<TableDefault columns={columns} dataSource={treats} />
			</StyledLayout>
			
			{/* 4-13 팝업 */}
			{isConfirmVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default Treat;
